import { Box, Chip } from "@mui/material";
import { FC, useEffect, useState, MouseEvent} from "react";
import { useQuery } from "@tanstack/react-query";
import { ChangeEvent } from "react";
import { ITestState } from "../../../../../../interfaces/configuration/tests";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useOutletContext,
  useParams,
} from "react-router-dom";
import {
  capitalize,
  createIndex,
  formatDate,
} from "../../../../../../utilities/helper";
import { IErrorResponse,IPagination } from "../../../../../../interfaces";
import Header from "../../../../../../components/header";
import useResource from "../../../../../../hooks/useResource";
import CustomTable from "../../../../../../components/mui/table";
import GetActions from "../../../../../../components/get-actions";
import WarningDialog from "../../../../../../components/mui/warning-dialog";
import useDebounce from "../../../../../../hooks/useDebounce";
import useSnackbar from "../../../../../../hooks/useSnackbar";
import { TestsService } from "../../../../../../services/configuration/tests";
import {
  ITestCategories,
  ICategoryListRow,
} from "../../../../../../interfaces/configuration/tests";
import Filters from "./filter";

interface outletProps {
  refetchTests: () => void;
}
interface props {
  status: string;
}

const TestCategories: FC<props> = ({ status }) => {
  let rows: ICategoryListRow[] = [];
  const [searchParams, setSearchParams] = useSearchParams();
  const { testId } = useParams();
  const { getTestCategoryList, deleteTestCategories } = TestsService();
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { resourceAllocate } = useResource();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<ITestState>({
    deleteWarning: false,
    cloneWarning: false,
    _test: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filters: {
      search: "",
      createdAt: {
        startDate: "",
        endDate: ""
      }
    },
    filterCount: 0
  });

  const tests = useQuery({
    queryKey: ["allTestCategory", state.pagination.page, state.filters],

    queryFn: () =>
      getTestCategoryList({
        _test: testId,
        pagination: true,
        limit: state.pagination.limit,
        page: state.pagination.page,
        search: state.filters.search,
        startDate: state.filters.createdAt?.startDate,
        endDate: state.filters.createdAt?.endDate,
      }),
  });

  const searchRecord = useDebounce(search, 1000);
 
  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        type: searchParams.get("type"),
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    tests.data &&
    tests.data.data &&
    tests.data.data.length &&
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: tests.data.meta.page,
          totalPages: tests.data.meta.totalPages,
          totalRecords: tests.data.meta.totalRecords,
        },
      }));
  }, [tests.data && tests.data.data && tests.data.meta]);


  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";
      const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
      filterCount += date.length ? 1 : 0;
  
      let createdAt: {
        startDate: string;
        endDate: string;
      } | undefined = undefined;
      if (date?.length) {
        createdAt = {
          startDate: date[0]?.startDate,
          endDate: date[0]?.endDate,
        };
      }
  

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        ...prevState.filters,
        search,
        createdAt
      },
      filterCount
    }));
  }, [searchParams]);

  const removeFilters = () => {
    searchParams.delete("date");
    setSearchParams(searchParams);
  };

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const handleDelete = (_test = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _test,
    }));
  };

  const { snackbar } = useSnackbar();
  const onDelete = async () => {
    try {
      const deleted = await deleteTestCategories({ _id: state._test, _test: testId });
      snackbar(deleted.message, "info");
      handleDelete();
      tests.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  const columns = [
    {
      id: "id",
      label: "S No.",
    },
    {
      id: "test_category",
      label: "Category",
    },
    {
      id: "test_questions",
      label: "Questions",
    },
    {
      id: "low_marks",
      label: "Low",
    },
    {
      id: "medium_marks",
      label: "Medium",
    },
    {
      id: "high_marks",
      label: "High",
    },

    {
      id: "created_on",
      label: "Created At",
    },
    {
      id: "action",
      label: "Actions",
    },
  ];

  const createRow = (
    index: number,
    test: ITestCategories,
    pagination: IPagination
  ) => {
    const action = (
      <>
        <div style={{ display: "flex", alignItems: "center" }}>

          <GetActions
            icons={[
              {
                name: "Edit",
                method: () =>
                  navigate({
                    pathname: `manage-category/${test._id}`,
                    search: searchParams.toString(),
                  }),
                disabled: resourceAllocate("test-category.write") && status!=="ARCHIVED" && status!=="PUBLISHED" ? false : true,
              },
              { name: "Delete", method: () => handleDelete(test._id), disabled: resourceAllocate("test-category.remove") && status!=="ARCHIVED" && status!=="PUBLISHED" ? false : true },

            ]}
          />
        </div>
      </>
    );

    return {
      id: createIndex(pagination, index),
      test_category: test?.name ? capitalize(test?.name) : "",
      test_questions: test?.numberOfQuestions ? test?.numberOfQuestions : 0,
      low_marks:
        String(test?.low?.min ?? "") + "-" + String(test?.low?.max ?? ""),
      medium_marks:
        String(test.moderate.min ?? "") +
        "-" +
        String(test?.moderate?.max ?? ""),
      high_marks:
        String(test?.high?.min ?? "") + "-" + String(test?.high?.max ?? ""),
      created_on: formatDate(test?.createdAt ? test?.createdAt : ""),
      action,
    };
  };

  if (tests.data && tests.data.data && tests.data.data.length) {
    rows = ((tests.data && tests.data.data && tests.data.data) || []).map(
      (test, i) => createRow(i, test, state.pagination)
    );
  }

  return (
    <div id="test-manage">
      <Header
        className="my-2"
        searchPlaceholder="Search by category"
        onSearch={onSearch} 
        btnText="Create Test Category"
        onBtnClick={
          resourceAllocate("test-category.write") && status!=="ARCHIVED" && status!=="PUBLISHED"
            ? () => navigate({pathname:"manage-category/new", search: searchParams.toString()})
            : undefined
        }
        onFilter={openFilter}
        >
          <Filters
            anchorEl={state.filterDialog.anchorEl}
            isOpen={state.filterDialog.isOpen}
            OnClose={closeFilter}
          />
          {
            state.filterCount ?
            <Chip
              className="ml-2"
              label={`Filter Applied (${state.filterCount})`}
              color="warning"
              onDelete={removeFilters}
            /> : 
            null
          }
        </Header>
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 330px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Test Category"
        description="This action will delete all associated questions. Are you sure you want to delete this Category?"
      />
      <Outlet context={{ ...outlet, reFetch: tests.refetch }} />
    </div>
  );
};
export default TestCategories;