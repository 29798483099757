import { useEffect, useState, MouseEvent } from "react";
import { useQuery } from "@tanstack/react-query";
import { UsersService, RoleService } from "../../../../services";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import {
  IErrorResponse,
  IJobPosition,
  ITeam,
  IUser,
  IUserField,
  IUserValues,
} from "../../../../interfaces";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import {
  useParams,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { userValidation, userEditValidation } from "../../../../validations";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSelector } from "react-redux";
import CustomDialog from "../../../../components/mui/dialog";
import MobileNumber from "../../../../components/mui/mobile-number";
import useSnackbar from "../../../../hooks/useSnackbar";
import { capitalize } from "../../../../utilities/helper";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CustomLabel from "../../../../components/mui/custom-label";
import SearchSelect from "../../../../components/mui/search-select";
import { BusinessUnitsService } from "../../../../services/configuration/business-unit";

interface outletProps {
  reFetch: () => void;
  refetchUsers: () => void;
}

const ManageUser = () => {
  const { id } = useParams();
  const [searchParam] = useSearchParams();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const isDisable = id === "view" ? true : false;
  const hitQuery = id === "new" || id === "view" ? false : true;
  const navigate = useNavigate();
  const { getPartialRoles } = RoleService();
  const { addUser, getUser, updateUser } = UsersService();
  const [showPassword, setShowPassword] = useState(false);
  const users = useSelector<{ user: { list: IUser[] } }, IUser[]>(
    (state) => state.user.list
  );
  const teams = useSelector<{ team: { list: ITeam[] } }, ITeam[]>(
    (state) => state.team.list
  );
  const roles = useQuery({
    queryKey: ["partial-roles"],
    queryFn: () => getPartialRoles(),
  });
  const user = useQuery({
    queryKey: [hitQuery],
    queryFn: () => getUser({ id }),
    enabled: hitQuery,
  });
  const { getBusinessUnits } = BusinessUnitsService();
  const businessUnits = useQuery({
    queryKey: ["all-user-business-units"],
    queryFn: () => getBusinessUnits({
      pagination: false
    })
  });
  const {
    handleSubmit,
    control,
    getValues,
    trigger,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IUserValues>({
    resolver: joiResolver(id === "new" ? userValidation : userEditValidation),
    defaultValues: {
      email: "",
      gender: "MALE",
      departmentId: "",
      designation: "",
      number: "",
      roleId: "",
      ...(id === "new" && { password: "" }),
      status: "ACTIVE",
      firstName: "",
      lastName: "",
      dob: "",
      joinedDate: "",
      address: "",
      legalEntry: "",
      employeeNumber: "",
      dateOfLeaving: "",
      extensionPhoneNumber: "",
    },
  });

  const designations = useSelector<{ jobPosition: { list: IJobPosition[] } }, IJobPosition[]>(state => state.jobPosition.list);

  const getDesignationOptions = () => 
    designations.filter(title => title && title._department && title._department._id === getValues("departmentId"));


  const [phone, setPhone] = useState({
    country: "INDIA",
    dialCode: "+91",
    iso2: "IN",
  });

  const [extensionNumber, setExtensionNumber] = useState({
    country: "INDIA",
    dialCode: "+91",
    iso2: "IN",
  });

  useEffect(() => {
    if (id === "new") {
      console.log("new");
    } else {
      if (user.data?.data) {
        setValue("firstName", capitalize(user.data.data?.firstName));
        setValue("lastName", capitalize(user.data.data?.lastName));
        setValue("email", user.data.data?.email);
        setValue("gender", user.data.data?.gender);
        setValue("roleId", user.data.data?._role?._id);
        setValue("departmentId", user.data.data.department && user.data.data.department._id);
        setValue("designation", user.data.data.designation && user.data.data.designation._id);
        setPhone(user.data.data?.phone);
        setValue("number", user.data.data?.phone?.number);
        setExtensionNumber(user.data.data?.extensionNumber);
        setValue("extensionPhoneNumber", user.data.data?.extensionNumber?.number);
        setValue("dob", user.data.data?.dob || "");
        setValue("joinedDate", user.data.data?.joinedDate || "");
        setValue("dateOfLeaving", user.data.data?.dateOfLeaving || "");
        setValue("reportingTo", user.data.data && user.data.data.reportingTo && capitalize(user.data.data.reportingTo.name));
        setValue("employeeNumber", user.data.data?.employeeNumber);
        setValue("legalEntry", capitalize(user.data.data?.legalEntry));
        setValue("businessUnit", user.data.data.businessUnit && user.data.data.businessUnit._id);
        setValue("address", user.data.data?.location?.formattedAddress);
        setValue("status", user.data.data?.status);
      }
    }
  }, [id, user.data?.data]);

  // eslint-disable-next-line
  const selectDate = (value: any, name: string) => {
    const date =
      value && dayjs(value).toString() !== "Invalid Date"
        ? dayjs(value)?.toISOString()
        : undefined;
    const keyExist = name === "dob" || name === "joinedDate" || name === "dateOfLeaving";
    if (keyExist) {
      setValue(name, date);
      trigger(name);
    }
  };

  const onSubmit = async (data: IUserValues) => {
    try {
      if (id === "new") {
        const payload = {
          ...data,
          phone: {
            ...phone,
            number: data.number,
          },
          extensionNumber: {
            ...extensionNumber,
            number: data.extensionPhoneNumber,
          },
          _role: data.roleId,
          department: data.departmentId,
          name: `${data?.firstName} ${data?.lastName}`,
          location: {
            formattedAddress: data.address || undefined,
          },
        };
        delete payload.extensionPhoneNumber;
        delete payload.roleId;
        delete payload.number;
        delete payload.departmentId;
        delete payload.address;
        const add = await addUser(payload);
        snackbar(add.message, "info");
        navigate({
          pathname: "/configurations/user",
          search: searchParam.toString(),
        });
        outlet?.reFetch && outlet.reFetch();
        outlet?.refetchUsers && outlet.refetchUsers();
      } else {
        const payload = {
          ...data,
          _id: id,
          phone: {
            ...phone,
            number: data.number,
          },
          extensionNumber: {
            ...extensionNumber,
            number: data.extensionPhoneNumber,
          },
          location: {
            formattedAddress: data.address || undefined,
          },
          _role: data.roleId,
          department: data.departmentId,
          name: `${data?.firstName} ${data?.lastName}`,
        };
        delete payload.extensionPhoneNumber;
        delete payload.number;
        delete payload.roleId;
        delete payload.departmentId;
        delete payload.address;
        const user = await updateUser({ ...payload, _id: id });
        snackbar(user.message, "info");
      }
      navigate({
        pathname: "/configurations/user",
        search: searchParam.toString(),
      });
      outlet?.reFetch && outlet.reFetch();
      outlet?.refetchUsers && outlet.refetchUsers();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log(error);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onNumberChange = (e: SelectChangeEvent<string>, name: string) => {
    const { value } = e.target;
    const data = value.split(":");
    if(name === "number") { 
      setPhone({
        country: data[0],
        iso2: data[1],
        dialCode: data[2],
      });
    }else{
      setExtensionNumber({
        country: data[0],
        iso2: data[1],
        dialCode: data[2],
      });
    }
  };

  const onClose = () => {
    navigate({
      pathname: "/configurations/user",
      search: searchParam.toString(),
    });
  };

  const fields: IUserField[] = [
    {
      label: "First Name",
      name: "firstName",
      type: "input",
      placeholder: "Type first name here",
      required: true,
    },
    {
      label: "Last Name",
      name: "lastName",
      type: "input",
      placeholder: "Type last name here",
      required: true,
    },
    {
      label: "Employee Number",
      name: "employeeNumber",
      type: "input",
      placeholder: "Type employee number here",
    },
    {
      label: "Number",
      name: "number",
      type: "mobile-number",
      required: true,
    },
    {
      label: "Email",
      name: "email",
      type: "input",
      placeholder: "Type your email here",
      required: true,
    },
    {
      label: id === "new" ? "Password" : "Change Password",
      name: "password",
      type: "input",
      placeholder: "Type your password here",
      required: id === "new",
    },
    {
      label: "Gender",
      name: "gender",
      type: "select",
      options: [
        {
          key: "MALE",
          value: "Male"
        },
        {
          key: "FEMALE",
          value: "Female"
        },
      ],
      displayFieldKey: "value",
      storeFieldKey: "key",
      required: true,
    },
    {
      type: "select",
      name: "reportingTo",
      label: "Reporting Manager",
      options: users.map((i) => ({
        key: i._id,
        value: capitalize(i.name)
      })),
      displayFieldKey: "value",
      storeFieldKey: "key",
    },
    {
      label: "DOB",
      name: "dob",
      type: "date",
    },
    {
      label: "Joined Date",
      name: "joinedDate",
      type: "date",
      required: true,
    },
    {
      label: "Date of leaving",
      name: "dateOfLeaving",
      type: "date",
    },
    {
      label: "Role",
      name: "roleId",
      type: "select",
      required: true,
      options: roles.data?.data.map((i) => ({
        key: i._id,
        value: capitalize(i.name)
      })),
      displayFieldKey: "value",
      storeFieldKey: "key",
    },
    {
      label: "Department",
      name: "departmentId",
      type: "select",
      required: true,
      options: teams.map((i) => ({
        key: i._id,
        value: capitalize(i.name)
      })),
      displayFieldKey: "value",
      storeFieldKey: "key",
    },
    {
      label: "Legal Entry",
      name: "legalEntry",
      type: "input",
      placeholder: "Type legal entry here",
    },
    {
      label: "Business Unit",
      name: "businessUnit",
      type: "select",
      options: businessUnits && businessUnits.data && businessUnits.data.data && businessUnits.data.data.map((i) => ({
        key: i._id,
        value: capitalize(i.name)
      })) || [],
      displayFieldKey: "value",
      storeFieldKey: "key",
    },
    {
      label: "Designation",
      name: "designation",
      type: "select",
      options: getDesignationOptions().map((i) => ({
        key: i._id,
        value: capitalize(i.name)
      })),
      displayFieldKey: "value",
      storeFieldKey: "key",
      disabled: !!watch("departmentId"),
      required: true,
    },
    {
      label: "Employee Address",
      name: "address",
      type: "input",
      placeholder: "Type employee address here",
    },
    {
      label: "Extension Number",
      name: "extensionPhoneNumber",
      type: "mobile-number"
    },
  ];

  return (
    <Box>
      <CustomDialog
        title={id !== "new" ? "Edit User" : "Add User"}
        isOpen={id ? true : false}
        onClose={onClose}
        disabled={isDisable}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={4}>
          {fields.map((field) => {
            if (
              field.type === "input" &&
              field.name === "password" 
            ) {
              return (
                <Grid key={field.label} item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => (
                      <TextField
                        label={
                          <CustomLabel
                            label={field.label}
                            required={field?.required}
                          />
                        }
                        className="disable-text"
                        variant={isDisable ? "standard" : "outlined"}
                        disabled={isDisable}
                        placeholder={field.placeholder}
                        size={isDisable ? "medium" : "small"}
                        error={errors[field.name] ? true : false}
                        helperText={errors[field.name]?.message}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        autoComplete="new-password"
                        {...prop.field}
                      />
                    )}
                  />
                </Grid>
              );
            } else if (field.type === "input") {
              return (
                field.name !== "password" && (
                  <Grid key={field.label} item xs={12} md={6}>
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => (
                        <TextField
                          label={
                            <CustomLabel
                              label={field.label}
                              required={field?.required}
                            />
                          }
                          className="disable-text"
                          variant={isDisable ? "standard" : "outlined"}
                          disabled={isDisable}
                          placeholder={field.placeholder}
                          size={isDisable ? "medium" : "small"}
                          error={errors[field.name] ? true : false}
                          helperText={errors[field.name]?.message}
                          autoComplete={
                            field.name === "email" ? "username" : ""
                          }
                          {...prop.field}
                        />
                      )}
                    />
                  </Grid>
                )
              );
            } else if (field.type === "mobile-number") {
              return (
                <Grid key={field.label} item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => (
                      <MobileNumber
                        key={field.label}
                        NumberFieldLabel={
                          <CustomLabel
                            label={field.label}
                            required={field?.required}
                          />
                        }
                        dialCodeValue={field.name === "number" ? `${phone?.country}:${phone?.iso2}:${phone?.dialCode}` : `${extensionNumber?.country}:${extensionNumber?.iso2}:${extensionNumber?.dialCode}`}
                        onChange={(event) => onNumberChange(event, field.name)}
                        error={errors[field.name] ? true : false}
                        helperText={errors[field.name]?.message}
                        other={prop.field}
                      />
                    )}
                  />
                </Grid>
              );
            } else if (field.type === "date") {
              return (
                <Grid key={field.label} item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      label={
                        <CustomLabel
                          label={field.label}
                          required={field?.required}
                        />
                      }
                      onChange={(e) => selectDate(e, field.name)}
                      value={dayjs(getValues(field.name))}
                      slotProps={{
                        textField: {
                          error: errors[field.name] ? true : false,
                          helperText: errors[field.name]?.message,
                        },
                      }}
                      format="LL"
                    />
                  </LocalizationProvider>
                </Grid>
              );
            } else{
              return (<Grid key={field.label} item xs={12} md={6}>
                <SearchSelect
                    name={field.name}
                    label={<CustomLabel label={field.label} required={field?.required} />}
                    error={errors[field.name] ? true : false}
                    helperText={errors[field.name]?.message}
                    options={field.options}
                    displayFieldKey={field.displayFieldKey ? field.displayFieldKey : ""}
                    storeFieldKey={field.storeFieldKey ? field.storeFieldKey : ""}
                    displayUserName={field.displayUserName}
                    capitalize={field.capitalize}
                    trigger={trigger}
                    setValue={setValue}
                    getValues={getValues}
                />
            </Grid>
            );
            }
          })}
        </Grid>
      </CustomDialog>
    </Box>
  );
};

export default ManageUser;