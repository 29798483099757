import { useSelector } from "react-redux";
import { broadcastService } from "../../../../services";
import { joiResolver } from "@hookform/resolvers/joi";
import { Controller, useForm } from "react-hook-form";
import { BroadcastValidation } from "../../../../validations";
import { Box, Grid, MenuItem, TextField, Divider, Chip, FormHelperText, Typography } from "@mui/material";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { IBroadcastField, ITemplate, IBroadcast, IErrorResponse, ISendDynamicTemplate, IWhatsappTemplate, IStatus } from "../../../../interfaces";
import Select from "../../../../components/mui/select";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
import { useEffect, useState } from "react";
import TinyEditor from "../../../../components/text-editor";
import { useQuery } from "@tanstack/react-query";
import useTemplate from "../helpers/useTemplate";
import CustomLabel from "../../../../components/mui/custom-label";
import SearchSelect from "../../../../components/mui/search-select";
import CustomTable from "../../../../components/mui/table";
import GetActions from "../../../../components/get-actions";

interface outletProps {
  reFetch: () => void
}

interface IState {
  sendDynamicTemplate: ISendDynamicTemplate,
  acceptValues: string[]
  whatsappTemplate?: IWhatsappTemplate
}

const initiateSendDynamicTemplate = {
  isSelectedTemplateDynamic: false,
  body: [],
  header: [],
  media: [],
  button: []
};

interface IAttachmentRow {
  id: number,
  file_name: string,
  file_size: string,
  action: JSX.Element
}

let attachments: { filename: string; path: string; sizeInBytes: number; contentType: string; }[] | undefined = [];

const ManageBroadcast = () => {
  let attachmentRow: IAttachmentRow[] = [];
  const { id , broadCastId } = useParams();
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const { isDynamicTemplate } = useTemplate();
  const { snackbar } = useSnackbar();

  const { addbroadcast, whatsappTemplates } = broadcastService();
  const outlet = useOutletContext<outletProps>();
  const templates = useSelector<{ template: { list: ITemplate[] } }, ITemplate[]>(state => state.template.list);
  const statusList = useSelector<{ status: { list: IStatus[] } }, IStatus[]>(state => state.status.list);
  const getWhatsappTemplates = useQuery({
    queryKey: ["whatsappTemplates"],
    queryFn: () => whatsappTemplates({})
  });
  const getWhatsappTemplatesData = getWhatsappTemplates.data?.data || [];
  const { handleSubmit, control, watch, getValues, unregister, trigger, setValue, formState: { errors } } = useForm<IBroadcast>({
    resolver: joiResolver(BroadcastValidation),
    defaultValues: {
      template: "",
      scheduled: "instant",
      subject: "",
      type: "EMAIL",
      broadcastName: "",
      whatsappTemplate: ""
    }
  });

  const [state, setState] = useState<IState>({
    sendDynamicTemplate: initiateSendDynamicTemplate,
    acceptValues: [],
  });

  const fields: Array<IBroadcastDividerField> = [
    {
      label: "Broadcast for",
      name: "type",
      type: "select",
      options: [
        { key: "EMAIL", value: "Email" },
        { key: "WHATSAPP", value: "Whatsapp" },
      ],
      displayFieldKey: "value",
      storeFieldKey: "key",
      divider: "template",
      required: true
    },
    {
      label: "Broadcast Name",
      name: "broadcastName",
      type: "input",
      divider: "template",
      required: true
    },
    {
      label: "Whatsapp Template",
      name: "whatsappTemplate",
      type: "select",
      options: getWhatsappTemplatesData,
      displayFieldKey: "configuration.name",
      storeFieldKey: "_id",
      capitalize: true,
      divider: "template",
      required: true
    },
    {
      label: "Template",
      name: "template",
      type: "select",
      options: templates.filter(template => template.type === "email" && template.tag === "general"),
      displayFieldKey: "title",
      storeFieldKey: "title",
      capitalize: true,
      divider: "template",
      required: true
    },
    {
      label: "Current Status",
      name: "currentStatus",
      type: "select",
      options: statusList,
      displayFieldKey: "name",
      storeFieldKey: "name",
      capitalize: true,
      divider: "template",
      required: true
    },
    {
      label: "Update Status",
      name: "updatedStatus",
      type: "select",
      options: statusList,
      displayFieldKey: "name",
      storeFieldKey: "name",
      capitalize: true,
      divider: "template",
      required: true
    },
    {
      label: "Schedule",
      name: "scheduled",
      type: "select",
      options: [
        { key: "instant", value: "Instant Broadcast" },
        { key: "delayed", value: "Schedule Broadcast" },
      ],
      displayFieldKey: "value",
      storeFieldKey: "key",
      divider: "template",
      disabled: true,
      required: true
    },
    {
      name: "subject",
      label: "Subjects",
      type: "input",
      divider: "template",
      placeholder: "Type Subjects here",
      width: 12,
      required: true
    },
    {
      name: "content",
      label: "Content*",
      type: "text-editor",
      divider: "template",
      width: 12
    }
  ];


  useEffect(() => {
    const subscribe = watch((value, { name }) => {
      if (name === "template") {
        const template = templates.find(template => template.title === value.template);
        attachments = template?.email?.attachments;
        setValue("subject", template?.email?.subject);
        setValue("content", template?.email?.content);
        trigger("content");
      } else if (name === "type") {
        setValue("whatsappTemplate", "");
        setValue("broadcastName", "");
        setValue("template", "");
      } else if (name === "whatsappTemplate") {
        setState(prev => ({ ...prev, dynamicTemplate: initiateSendDynamicTemplate }));
        const whatsappTemplate = getWhatsappTemplatesData.find(template => template._id === value.whatsappTemplate);
        if (whatsappTemplate) {
          unregister("dynamicParameter");
          const dynamicTemplate = isDynamicTemplate(whatsappTemplate);
          if (dynamicTemplate.sendDynamicTemplate.isSelectedTemplateDynamic && dynamicTemplate.sendDynamicTemplate.body?.length) {
            dynamicTemplate.sendDynamicTemplate.body.forEach((variable, index) => {
              setValue(`dynamicParameter.body.${index}.title`, variable.title);
              setValue(`dynamicParameter.body.${index}.type`, variable.type);
              setValue(`dynamicParameter.body.${index}.value`, "fallback");
              setValue(`dynamicParameter.body.${index}.dynamicInput`, "");
            });
          }
          setState(prev => ({ ...prev, ...dynamicTemplate, whatsappTemplate }));

        }
      }
    });
    return () => subscribe.unsubscribe();
  }, [watch, getWhatsappTemplatesData]);


  if (attachments && attachments.length) {
    attachmentRow = attachments.map((attachment, i) => {
        const action = <GetActions
            icons={
                [
                    { name: "Preview", method: () => window.open(`${process.env.REACT_APP_S3_BASE_URL}${attachment.path}`, "_blank") }
                ]
            }
        />;

        return {
            id: i + 1,
            file_name: attachment.filename.length > 50 ?
                attachment.filename.substring(0, 50) + "..."
                : attachment.filename,
            file_size: `${(attachment.sizeInBytes/ 1024/1024).toFixed(2)} MB`,
            action
        };
    });
}


  const selectedJobType = watch("typeOfLead");
  const selectedBroadcastFor = watch("type");


  const onSubmit = async (data: IBroadcast) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload: any = {
      ...data, 
      email: {
        subject: data?.subject,
        content: data?.content,
        attachments: attachments
      },
    };

    if (data.type === "WHATSAPP") {
      payload.template = data.whatsappTemplate;
    } else {
      delete payload.parameters;
      delete payload.template;
    }

    delete payload.typeOfLead;
    delete payload.graduationYear;
    delete payload.jobId;
    delete payload.candidateStatus;
    delete payload.collegeName;
    delete payload.experience;
    delete payload.noticePeriod;
    delete payload.scheduled;
    delete payload.subject;
    delete payload.content;
    delete payload.whatsappTemplate;

    try {
      if (broadCastId === "new") {
        const add = await addbroadcast({ _broadcastGroup: id, ...payload });
        snackbar(add.message, "info");
        navigate({
          pathname: `/broadcast-group/${id}/broadcast`,
          search: searchParam.toString()
        });
        outlet?.reFetch && outlet.reFetch();
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log(error);
    }
  };

  const onClose = () => {
    navigate({
      pathname: `/broadcast-group/${id}/broadcast`,
      search: searchParam.toString()
    });
    outlet?.reFetch && outlet.reFetch();
  };

  const attachmentColumns = [
    {
        id: "id",
        label: "S No."
    },
    {
        id: "file_name",
        label: "File Name"
    },
    {
        id: "file_size",
        label: "File Type"
    },
    {
        id: "action",
        label: "Actions"
    }
];

  interface IBroadcastDividerField extends IBroadcastField {
    divider?: "audience" | "template"
    disabled?: boolean
  }

  let filteredFields = fields.filter((field) => {
    if (selectedJobType === "INTERN") {
      return field.name === "typeOfLead" || field.name === "template" || field.name === "scheduled" || field.name === "jobId" ||
        field.name === "candidateStatus" || field.name === "collegeName" || field.name === "graduationYear" || field.name === "subject" ||
        field.name === "content" || field.name === "updatedStatus" || field.name === "currentStatus"|| field.name === "masterLimit" || field.name === "type" || field.name === "whatsappTemplate" ||
        field.name === "broadcastName" || field.name === "startDate" || field.name === "endDate";
    } else {
      return field.name === "typeOfLead" || field.name === "template" || field.name === "scheduled" || field.name === "jobId" ||
        field.name === "candidateStatus" || field.name === "experience" || field.name === "noticePeriod" || field.name === "subject" ||
        field.name === "content" || field.name === "type" || field.name === "whatsappTemplate" || field.name === "broadcastName" ||
        field.name === "updatedStatus" || field.name === "currentStatus" || field.name === "startDate" || field.name === "endDate";
    }
  });

  filteredFields = filteredFields.filter((field) => {
    if (selectedBroadcastFor === "EMAIL") {
      return !(field.name === "whatsappTemplate");
    } else {
      return !(field.name === "template" || field.name === "subject" || field.name === "content");
    }
  });

  const bodyText = state.whatsappTemplate?.configuration?.components?.find(component => component.type === "BODY");

  return (
    <Box>
      <CustomDialog
        title={"Broadcast Template"}
        confirmText={selectedBroadcastFor === "EMAIL" ? "Email Broadcast" : "Whatsapp Broadcast"}
        isOpen={id ? true : false}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box className="mb-3">

          <Grid container spacing={4}>

            {/* Template configuration  */}
            <Grid item xs={12}>
              <Divider textAlign="left">
                <Chip variant="outlined" label="Template Configuration" />
              </Divider>
            </Grid>
            {
              filteredFields.filter(e => e.divider === "template").map((field) => {
                if (field.type === "input") {
                  return (
                    <Grid key={field.label} item xs={field?.width ? field?.width : 12} md={field?.width ? field?.width : 6}>
                      <Controller
                        control={control}
                        name={field.name}
                        render={(prop) => (
                          <TextField
                            label={<CustomLabel label={field.label} required={field?.required} />}
                            className="disable-text"
                            variant="outlined"
                            size="small"
                            error={errors[field.name] ? true : false}
                            helperText={errors[field.name]?.message}
                            {...prop.field}
                          />
                        )}
                      />
                    </Grid>
                  );
                }

                else if (field.type === "multiline") {
                  return (<Grid key={field.label} item xs={12} md={field.width}>
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => (
                        <TextField
                          label={<CustomLabel label={field.label} required={field?.required} />}
                          className="disable-text"
                          variant="outlined"
                          size="small"
                          multiline
                          error={errors[field.name] ? true : false}
                          helperText={errors[field.name]?.message}
                          {...prop.field}
                        />
                      )}
                    />
                  </Grid>
                  );
                }
                else if (field.type === "select") {
                  return (<Grid key={field.label} item xs={12} md={6}>
                    <SearchSelect
                      name={field.name}
                      label={<CustomLabel label={field.label} required={field?.required} />}
                      error={errors[field.name] ? true : false}
                      helperText={errors[field.name]?.message}
                      options={field.options}
                      disabled={field.disabled}
                      displayFieldKey={field.displayFieldKey ? field.displayFieldKey : ""}
                      storeFieldKey={field.storeFieldKey ? field.storeFieldKey : ""}
                      displayUserName={field.displayUserName}
                      capitalize={field.capitalize}
                      keyUpperCase={field.keyUpperCase}
                      trigger={trigger}
                      setValue={setValue}
                      getValues={getValues}
                    />
                  </Grid>
                  );
                }
                else {
                  return (
                    <Grid key={field.name} item xs={field?.width ? field?.width : 12}>
                      <Box className={errors[field.name] ? "tiny-error" : ""}>
                        <TinyEditor
                          value={getValues("content")}
                          onChange={(e: string) => setValue("content", e)}
                          height="375px"
                        />
                        {
                          errors[field.name] &&
                          <FormHelperText
                            sx={{ margin: "4px 14px 0px 14px" }}
                            error={errors[field.name] ? true : false}
                          >
                            {errors[field.name]?.message}
                          </FormHelperText>
                        }
                      </Box>
                    </Grid>
                  );
                }
              })
            }

            {/* Template body Parameter */}
            <Grid item xs={12}>
              {
                (state.sendDynamicTemplate.isSelectedTemplateDynamic && state.sendDynamicTemplate.body?.length)
                  ?
                  <div className="ml-1">
                    <Typography sx={{ fontWeight: "bold" }} variant="body2" color="black">Body <span className='text-danger'>*</span></Typography>
                    <Typography className='mt-2 mb-2' variant="body2">{bodyText ? bodyText?.text || "" : ""}</Typography>
                  </div>
                  :
                  null
              }

              {
                (state.sendDynamicTemplate.isSelectedTemplateDynamic && state.sendDynamicTemplate.body?.length) ?
                  state.sendDynamicTemplate.body.map((variable, index) =>
                  (<Grid container key={index} spacing={2}>
                    <Grid item md={1}>
                      <Controller
                        control={control}
                        name={`dynamicParameter.body.${index}.title`}
                        render={(prop) => (
                          <TextField
                            label="title"
                            className="disable-text mt-3"
                            variant="outlined"
                            size="small"
                            {...prop.field}
                            disabled
                            value={variable.title}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <div className="mt-3">
                        <Select
                          control={control}
                          name={`dynamicParameter.body.${index}.value`}
                          label="Select Attribute"
                          size="small"
                          variant="outlined"
                          disabled
                          error={(errors["dynamicParameter"] && errors["dynamicParameter"]?.body && errors["dynamicParameter"].body.length && errors["dynamicParameter"].body[index]?.value) ? true : false}
                          helperText={(errors["dynamicParameter"] && errors["dynamicParameter"]?.body && errors["dynamicParameter"].body.length) ? errors["dynamicParameter"].body[index]?.value?.message : ""}
                        >
                          <MenuItem key="fallback" value="fallback">Fallback</MenuItem>
                        </Select>
                      </div>
                    </Grid>
                    <Grid item xs={7}>
                      <Controller
                        control={control}
                        name={`dynamicParameter.body.${index}.dynamicInput`}
                        render={(prop) => (
                          <TextField
                            label={<CustomLabel label="Fallback Value" required />}
                            className="disable-text mt-3"
                            variant="outlined"
                            size="small"
                            placeholder="Type fallback value"
                            error={(errors["dynamicParameter"] && errors["dynamicParameter"].body && errors["dynamicParameter"].body.length && errors["dynamicParameter"].body[index]?.dynamicInput) ? true : false}
                            helperText={(errors["dynamicParameter"] && errors["dynamicParameter"].body && errors["dynamicParameter"].body.length) ? errors["dynamicParameter"].body[index]?.dynamicInput?.message : ""}
                            {...prop.field}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  ))
                  :
                  null
              }
            </Grid>

          </Grid>
        </Box>
        {
        attachments && attachments.length ? <Grid item xs={12}>
            <CustomTable
                columns={attachmentColumns}
                rows={attachmentRow}
            />
          </Grid>
        :
        null
      }
      </CustomDialog>
    </Box>
  );
};

export default ManageBroadcast;