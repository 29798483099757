import { useQuery } from "@tanstack/react-query";
import { FC, MouseEvent } from "react";
import { Box, Button, Chip, CircularProgress, DialogContent } from "@mui/material";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { blogInsightProps, IBlogKeywordsRow, IBlogsInsights, IBlogsInsightState } from "../../../../interfaces/blogs-insights";
import ProjectsService from "../../../../services/content/projects-products";
import { BlogsInsightsService } from "../../../../services/blogs-insights";
import Header from "../../../../components/header";
import CustomTable from "../../../../components/mui/table";
import BlogInsightFilter from "./filters";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../../components/shared/mui-tabs";
import { insightColumns } from "../../variables";
import CustomTypography from "../../../../components/mui/max-length-limit";

const BlogInsights: FC<blogInsightProps> = ({ blogUrl, onClose }) => {
  let rows: IBlogKeywordsRow[] = [];
  const { getContentProjectPartial } = ProjectsService();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getBlogsInsights } = BlogsInsightsService();
  const [state, setState] = useState<IBlogsInsightState>({
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filters: {
      date: {
        startDate: "",
        endDate: ""
      }
    },
    filterCount: 0,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const handleLoadMore = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const project = useQuery({
    queryKey: ["project"],
    queryFn: () => getContentProjectPartial({
      search: "Bot Penguin"
    }),
  });

  const insights = useQuery({
    queryKey: ["insights", currentPage, state.filters],
    queryFn: () => {
      setLoading(true);
      return getBlogsInsights({
        pagination: true,
        limit: state.pagination.limit,
        page: currentPage,
        dimensions: ["query"],
        _product: project.data?.data?._id,
        startDate: state.filters.date?.startDate,
        endDate: state.filters.date?.endDate,
        dimension: "page",
        operator: "equals",
        expression: blogUrl
      });
    },
    onSuccess: () => setLoading(false),
    enabled: !!project?.data?.data,
  });

  const [insightsData, setInsightsData] = useState<IBlogsInsights[] | null>(null);

  useEffect(() => {
    insights.remove();
    setInsightsData(null);
  }, []);

  useEffect(() => {
    if (insights?.data?.success && !insights.data?.data?.rows) {
      setInsightsData(null);
    }
    if (insights.data?.data?.rows) {

      setInsightsData((prevData) => {
        if (!prevData) {
          return insights.data.data.rows as IBlogsInsights[];
        } else {
          if (Array.isArray(insights.data.data.rows)) {
            const newData = insights.data.data.rows as IBlogsInsights[];
            newData.forEach((newItem) => {
              prevData.push(newItem);
            });
            return [...prevData];
          } else {
            return prevData;
          }
        }
      });
    }
  }, [insights?.data?.data]);

  useEffect(() => {
    let filterCount = 0;
    const datee: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
    filterCount += datee.length ? 1 : 0;

    let date: {
      startDate: string;
      endDate: string;
    } = {
      startDate: "",
      endDate: "",
    };
    if (datee?.length) {
      date = {
        startDate: datee[0]?.startDate,
        endDate: datee[0]?.endDate,
      };
    }

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
      },
      filters: {
        ...prevState.filters,
        date
      },
      filterCount
    }));
    setInsightsData(null);
  }, [searchParams]);

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const removeFilters = () => {
    searchParams.delete("date");
    setSearchParams(searchParams);
  };


  const createRow = (index: number, insight: IBlogsInsights) => ({
    id: index + 1,
    keyword: <CustomTypography
        limit={60}
        label={insight.keys[0]}
      />,
    clicks: insight.clicks,
    impressions: insight.impressions
  });

  if (insightsData) {
    rows = insightsData.map((insight, i) =>
      createRow(i, insight)
    );
  }

  return (
    <BootstrapDialog
      maxWidth="lg"
      onClose={onClose}
      open={true}
    >
      <BootstrapDialogTitle onClose={onClose}>
        <Box display="flex" alignItems="center">
          Blog Page Insights
        </Box>
      </BootstrapDialogTitle>

      <DialogContent dividers sx={{ height: "65vh" }}>

        <Header
          className="mb-2"
          onFilter={openFilter}
        >
          <BlogInsightFilter
            anchorEl={state.filterDialog.anchorEl}
            isOpen={state.filterDialog.isOpen}
            OnClose={closeFilter}
          />
          {
            state.filterCount > 0 &&
              <Chip
                className="ml-2"
                label={`Filter Applied (${state.filterCount})`}
                color="warning"
                onDelete={removeFilters}
              />
          }
        </Header>

        <Box marginTop="10px">
          <CustomTable
            columns={insightColumns}
            rows={rows}
            height="calc(100vh - 248px)"
            errorMessage="No data available"
          />
        </Box>

        <div style={{ display: "flex", justifyContent: "center", marginTop: "10px", alignItems: "center" }}>
          {loading ? (
            <CircularProgress />
          ) : (
            insights?.data?.meta?.isLoad && (
              <Button variant="contained" color="primary" onClick={handleLoadMore}>
                Load More
              </Button>
            )
          )}
        </div>
      </DialogContent>

    </BootstrapDialog>
  );
};

export default BlogInsights;
