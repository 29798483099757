import { useEffect, useState } from "react";
import { Box, Chip } from "@mui/material";
import { ChangeEvent, MouseEvent } from "react";
import { useQuery } from "@tanstack/react-query";
import { IPagination } from "../../../interfaces";
import { JobTitleService } from "../../../services";
import { Outlet, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { capitalize, createIndex, formatDateTime } from "../../../utilities/helper";
import { IJobTitleRow, IJobTitleState, IJobTitle } from "../../../interfaces";
import Header from "../../../components/header";
import useSnackbar from "../../../hooks/useSnackbar";
import useDebounce from "../../../hooks/useDebounce";
import useResource from "../../../hooks/useResource";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import DepartmentFIlter from "../common-filter";
interface outletProps {
  refetchJobTitles: () => void;
}

const JobTitle = () => {
  let rows: IJobTitleRow[] = [];
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<outletProps>();
  const { resourceAllocate } = useResource();
  const [search, setSearch] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { getJobTitles, deleteJobTitles } = JobTitleService();
  const [state, setState] = useState<IJobTitleState>({
    deleteWarning: false,
    _jobTitle: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    filters: {
      search: "",
      _department: []
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filterCount: 0
  });

  const jobTitles = useQuery({
    queryKey: ["allJobTitles", state.pagination.page, state.filters],
    queryFn: () => getJobTitles({
      pagination: true, limit: state.pagination.limit, page: state.pagination.page, ...state.filters
    })
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams(prev => ({
        ...prev,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (jobTitles.data?.data.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: jobTitles.data.meta.page,
          totalPages: jobTitles.data.meta.totalPages,
          totalRecords: jobTitles.data.meta.totalRecords
        }
      }));
    }
  }, [jobTitles.data?.meta]);


  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";
    const _department: { key: string; value: string }[] = searchParams.get("_department")
      ? JSON.parse(String(searchParams.get("_department")))
      : [];
    filterCount += _department.length ? 1 : 0;
    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        ...prevState.filters,
        search,
        _department: _department.map((_department) => _department.key),
      },
      filterCount,
    }));
  }, [searchParams]);

  const removeFilters = () => {
    searchParams.delete("_department");
    setSearchParams(searchParams);
  };

  const openFilter = (e: MouseEvent<HTMLButtonElement>) =>
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        anchorEl: e.currentTarget,
        isOpen: !state.filterDialog.isOpen,
      },
    }));

  const closeFilter = () => {
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false,
      },
    }));
  };

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const handleDelete = (_jobTitle = "") => {
    setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _jobTitle
    }
    ));
  };

  const onDelete = async () => {
    try {
      const deleted = await deleteJobTitles({ _id: state._jobTitle });
      snackbar(deleted.message, "info");
      handleDelete();
      jobTitles.refetch();
      outlet?.refetchJobTitles && outlet.refetchJobTitles();
    } catch (error) {
      console.log(error);
    }

  };

  const columns = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "jobtTitle_title",
      label: "Title"
    },
    {
      id: "jobtTitle_department",
      label: "Department"
    },
    {
      id: "jobtTitle_createdBy",
      label: "Created By"
    },
    {
      id: "jobtTitle_createdAt",
      label: "Created At"
    },
    {
      id: "action",
      label: "Actions"
    },
  ];

  const createRow = (index: number, JobTitle: IJobTitle, pagination: IPagination) => {
    const action = <GetActions
      icons={[
        { name: "Edit", method: () => navigate({ pathname: "" + JobTitle._id, search: searchParams.toString() }), disabled: resourceAllocate("job-title.write") ? false : true },
        { name: "Delete", method: () => handleDelete(JobTitle._id), disabled: resourceAllocate("job-title.remove") ? false : true },
      ]}
    />;

    return {
      id: createIndex(pagination, index),
      jobtTitle_title: capitalize(JobTitle.name),
      jobtTitle_department: JobTitle._department && JobTitle?._department.name ? capitalize(JobTitle._department.name) : "",
      jobtTitle_createdBy: JobTitle?.createdBy?.name && capitalize(JobTitle?.createdBy?.name),
      jobtTitle_createdAt: formatDateTime(JobTitle?.createdAt),
      action,
    };
  };

  if (jobTitles.data?.data.length) {
    rows = jobTitles.data?.data.map((JobTitle, i) => createRow(i, JobTitle, state.pagination));
  }

  return (
    <>
      {/* Add Data  */}
      <Header
        className='my-2'
        searchPlaceholder="Search by title"
        onSearch={onSearch}
        btnText="ADD JOB TITLE"
        onBtnClick={resourceAllocate("job-title.write") ? () => navigate("new") : undefined}
        onFilter={openFilter}
      >
        <DepartmentFIlter 
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
           OnClose={closeFilter}
        />
        {state.filterCount > 0 && (
          <Chip
            className="ml-2"
            label={`Filter Applied (${state.filterCount})`}
            color="warning"
            onDelete={removeFilters}
          />
        )}
      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={() => onDelete()}
        title="Delete Job Title"
        description="Are you sure you want to delete this job-title?"
      />
      <Outlet context={{ ...outlet, reFetch: jobTitles.refetch }} />
    </>
  );
};
export default JobTitle;

