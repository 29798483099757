import Joi from "joi";
import { required, only_string } from "../../shared";

export const editSkillItemValidation = Joi.object({
    name: Joi.string()
        .required()
        .label("Name")
        .max(80)
        .messages({
            "string.empty": required,
            "string.pattern.base": only_string,
            "string.max": "Maximum 80 characters are allowed"
        }),
    _skillCategory: Joi.string()
        .required()
        .label("Skill Category")
        .messages({
            "string.empty": required,
        })
});
