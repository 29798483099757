import Joi from "joi";
import { required, } from "../../shared";

export const SkillItemValidation = Joi.object({

    skillItems: Joi.array()
        .min(1)
        .required()
        .items(Joi.string())
        .label("Skill Item")
        .messages({
            "string.max": "Skill Item cannot exceed 25 characters.",
            "array.empty": required,
        }),
    skillItemsText: Joi.string()
        .allow(""),
    _skillCategory: Joi.string()
        .required()
        .label("Skill Category")
        .messages({
            "string.empty": required,
        })
});

export const EmployeeValidation = Joi.object({

    employees: Joi.array()
        .required()
        .label("Employee Name")
        .messages({
            "string.empty": required,
        }),
});

