import { ChangeEvent } from "react";
import { useQuery } from "@tanstack/react-query";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { IPagination } from "../../../interfaces/";
import { Box, Typography, Chip, Tooltip } from "@mui/material";
import { cpdDashboardService } from "../../../services";
import { useEffect, useState, MouseEvent } from "react";
import { capitalize, createIndex, formatDate } from "../../../utilities/helper";
import { ICpdDashboardRow, ICpdDashboardState, ICpdDashboard } from "../../../interfaces";
import Header from "../../../components/header";
import useDebounce from "../../../hooks/useDebounce";
import CustomTable from "../../../components/mui/table";
import DashboardFilters from "../Components/dashboard-filter";

const Dashboards = () => {
  let rows: ICpdDashboardRow[] = [];
  const { getCpdDashboards } = cpdDashboardService();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState<ICpdDashboardState>({
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    filters: {
      search: "",
      category: [],
      college: [],
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filterCount: 0,
  });

  const getCpdDashboard = useQuery({
    queryKey: ["allCpdDashboards", state.pagination.page, state.filters],
    queryFn: () => getCpdDashboards({
      pagination: true, limit: state.pagination.limit, page: state.pagination.page, ...state.filters
    })
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams(prev => ({
        ...prev,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (getCpdDashboard.data?.data.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: getCpdDashboard.data.meta.page,
          totalPages: getCpdDashboard.data.meta.totalPages,
          totalRecords: getCpdDashboard.data.meta.totalRecords
        }
      }));
    }
  }, [getCpdDashboard.data?.meta]);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
    const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
    const college: { key: string, value: string }[] = searchParams.get("college") ? JSON.parse(String(searchParams.get("college"))) : [];
    filterCount += category.length ? 1 : 0;
    filterCount += college.length ? 1 : 0;
    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search,
        category: category.map(category => category.key),
        college: college.map(college => college.key),
      },
      filterCount
    }));
  }, [searchParams]);

  const removeFilters = () => {
    searchParams.delete("category");
    searchParams.delete("college");
    setSearchParams(searchParams);
  };

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const columns = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "cpd_cpdId",
      label: "CPD ID"
    },
    {
      id: "cpd_collegeName",
      label: "College Name"
    },
    {
      id: "cpd_duration",
      label: "CPD Duration"
    },
    {
      id: "cpd_category",
      label: "Category"
    },
    {
      id: "cpd_totalApplied",
      label: "Total Applied"
    },
    {
      id: "cpd_testCleared",
      label: "Test Cleared"
    },
    {
      id: "cpd_hrRoundCleared",
      label: "HR Round Cleared"
    },
    {
      id: "cpd_selectedStudent",
      label: "Selected Students"
    },
    {
      id: "cpd_onHoldStudent",
      label: "Onhold Students"
    },
  ];

  const createRow = (index: number, dashboard: ICpdDashboard, pagination: IPagination) => {
    const name = <Typography
      onClick={() => navigate({ pathname: "" + dashboard.cpd._id, search: searchParams.toString() })}
      variant="body2"
      color={["created", "initiated"].includes(dashboard.cpd.status) ? "primary" : "error"}
      className="link-none-underline"
    >
      {(dashboard?.cpd?.cpdId)}
    </Typography>;

    const cpd = [{ key: dashboard.cpd.cpdId, value: dashboard.cpd.cpdId }];
    const testCleared = [
      { key: "LA SELECTED", value: "LA Selected" },
      { key: "HR SELECTED", value: "HR Selected" },
      { key: "OFFER SENT", value: "Offer Sent" },
      { key: "OFFER DECLINED", value: "Offer declined" },
      { key: "JOINED", value: "Joined" },
      { key: "NOT JOINED", value: "Not Joined" },
      { key: "TA SCHEDULED", value: "TA Scheduled" },
      { key: "TA SELECTED", value: "TA Selected" },
      { key: "HR SCHEDULED", value: "HR Scheduled" },
    ];
    const hrRoundCleared = [
      { key: "HR SELECTED", value: "HR Selected" },
      { key: "OFFER SENT", value: "Offer Sent" },
      { key: "OFFER DECLINED", value: "Offer declined" },
      { key: "JOINED", value: "Joined" },
      { key: "NOT JOINED", value: "Not Joined" }
    ];
    const selectedCandidates = [
      { key: "OFFER SENT", value: "Offer Sent" },
      { key: "OFFER DECLINED", value: "Offer declined" },
      { key: "JOINED", value: "Joined" },
      { key: "NOT JOINED", value: "Not Joined" }
    ];
    const onHoldCandidates = [
      { key: "HR ONHOLD", value: "HR Onhold" },
    ];

    const cpdCollegeName = (
      <Tooltip title={capitalize(dashboard?.college.name)}>
        <span>
          {dashboard?.college?.shortName.toUpperCase()}
        </span>
      </Tooltip>
    );
    return {
      id: createIndex(pagination, index),
      cpd_cpdId: name,
      cpd_collegeName: cpdCollegeName,
      cpd_duration: formatDate(dashboard?.cpd?.startDate),
      cpd_category: capitalize(dashboard?.college?.category),
      cpd_totalApplied: <Typography
        color="primary"
        className="link-none-underline"
        onClick={() => navigate({ pathname: "/candidates/intern", search: `cpd=${JSON.stringify(cpd)}` })}
      >
        {dashboard?.cpd?.count?.totalApplied}
      </Typography>,
      cpd_testCleared: <Typography
        color="primary"
        className="link-none-underline"
        onClick={() => navigate({ pathname: "/candidates/intern", search: `cpd=${JSON.stringify(cpd)}&status=${JSON.stringify(testCleared)}` })}
      >
        {dashboard?.cpd?.count?.testCleared}
      </Typography>,
      cpd_hrRoundCleared: <Typography
        color="primary"
        className="link-none-underline"
        onClick={() => navigate({ pathname: "/candidates/intern", search: `cpd=${JSON.stringify(cpd)}&status=${JSON.stringify(hrRoundCleared)}` })}
      >
        {dashboard?.cpd?.count?.hrRoundCleared}
      </Typography>,
      cpd_selectedStudent: <Typography
        color="primary"
        className="link-none-underline"
        onClick={() => navigate({ pathname: "/candidates/intern", search: `cpd=${JSON.stringify(cpd)}&status=${JSON.stringify(selectedCandidates)}` })}
      >
        {dashboard?.cpd?.count?.selectedCandidates}
      </Typography>,
      cpd_onHoldStudent: <Typography
        color="primary"
        className="link-none-underline"
        onClick={() => navigate({ pathname: "/candidates/intern", search: `cpd=${JSON.stringify(cpd)}&status=${JSON.stringify(onHoldCandidates)}` })}
      >
        {dashboard?.cpd?.count?.onHoldCandidates}
      </Typography>,
    };
  };
  if (getCpdDashboard.data?.data.length) {
    rows = getCpdDashboard.data?.data.map((dashboard, i) => createRow(i, dashboard, state.pagination));
  }

  return (
    <>
      {/* Add Data  */}
      <Header
        className='my-2'
        searchPlaceholder="Search by college name"
        onSearch={onSearch}
        onFilter={openFilter}
      >
        <DashboardFilters
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          onClose={closeFilter}
        />
        {
          state.filterCount > 0 &&
          <Chip
            className="ml-2"
            label={`Filter Applied (${state.filterCount})`}
            color="warning"
            onDelete={removeFilters}
          />
        }
      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      <Outlet />
    </>
  );
};
export default Dashboards;