import React, { ChangeEvent, FC, SyntheticEvent, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Tabs, Tab, Typography, FormControlLabel, Checkbox, DialogActions, Button, Divider, Pagination } from "@mui/material";
import { IEditSkillMatrix, ISkillMatrixItem } from "../../../../interfaces/skill-matrix/edit-skill-matrix";
import GetActions from "../../../../components/get-actions";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../../components/shared/mui-tabs";
import { SkillMatrixService } from "../../../../services";
import { useQuery } from "@tanstack/react-query";
import { SkillItemCategoryService } from "../../../../services/skill-matrix/skill-items-categories";
import useSnackbar from "../../../../hooks/useSnackbar";
import { useNavigate } from "react-router-dom";
import useResource from "../../../../hooks/useResource";
import { capitalize } from "../../../../utilities/helper";
import ErrorMessage from "../../../../components/shared/error-message";
import { ISkillMatrixUserState } from "../../../../interfaces";
import "./style.scss";
interface IProps {
  skillData: ISkillMatrixItem[];
  employees: IEditSkillMatrix[];
  pagination: {
    page: number;
    totalPages: number;
  };
  setState: React.Dispatch<React.SetStateAction<ISkillMatrixUserState>>
  onPageChange: (e: ChangeEvent<unknown>, page: number) => void;
  reFetchUserSkills: () => void;
  reFetchSkillItemData: () => void;
}

const SkillsTable: FC<IProps> = ({ skillData, employees, pagination, onPageChange, reFetchUserSkills, reFetchSkillItemData, setState }) => {
  const navigate = useNavigate();
  const { resourceAllocate } = useResource();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
  const { getSkillMatrixCategoryItems, deleteSkillItem } = SkillMatrixService();
  const [selectedTab, setSelectedTab] = useState(0);

  const { snackbar } = useSnackbar();

  const { deleteSkillItemCategory } = SkillItemCategoryService();

  const handleTabChange = (_event: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, skillId: string) => {
    if (event.target.checked) {
      setSelectedSkills((prevSelectedSkills) => [...prevSelectedSkills, skillId]);
    } else {
      setSelectedSkills((prevSelectedSkills) =>
        prevSelectedSkills.filter((id) => id !== skillId)
      );
    }
  };
  const handleDeleteCategory = async () => {
    try {
      const category = await deleteSkillItemCategory({ _id: selectedCategory });
      snackbar(category.message, "info");
    } catch (error) {
      console.log("Error in category delete", error);
    }
  };

  const handleDeleteSkillItems = async () => {
    try {
      const category = await deleteSkillItem({ _ids: selectedSkills });
      snackbar(category.message, "info");
    } catch (error) {
      console.log("Error in skill items delete", error);
    }
  };

  const handleDelete = () => {
    if (selectedTab === 0) {
      handleDeleteCategory();
    } else {
      handleDeleteSkillItems();
    }
    reFetchUserSkills();
    reFetchSkillItemData();
    setSelectedSkills([]);
    setSelectedCategory("");
    setOpenDeleteDialog(false);
  };

  const skillItems = useQuery({
    queryKey: ["getSkillMatrixCategoryItems", selectedCategory],
    queryFn: () => getSkillMatrixCategoryItems({ _skillCategory: selectedCategory }),
    enabled: !!selectedCategory && selectedTab === 1
  });
  return (
    <>
      <TableContainer className= "table-container">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="category-cell header">Category</TableCell>
              <TableCell className="category-cell header">Skill Item</TableCell>
              {employees && employees.map((employee, index) => (
                <TableCell
                  key={index}
                  className={resourceAllocate("skill-user-mapping.write") ? "style-table-cell header category-cell" : "header category-cell"}
                  onClick={
                    resourceAllocate("skill-user-mapping.write") ?
                    () => { setState((prevState) => ({ ...prevState, editEmployee: !prevState.editEmployee, _employeeId: employee._id })); } :
                    undefined
                  }
                >
                  {capitalize(employee._user)}
                </TableCell>
              ))}
              <TableCell className="action-cell header">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {skillData && skillData.map((categoryData) => (
              <React.Fragment key={categoryData.skillCategory._id}>
                {categoryData.skillItems.length > 0 ? (
                  categoryData.skillItems.map((skillItem, index) => (
                    <TableRow key={skillItem._id}>
                      {index === 0 && (
                        <TableCell 
                          rowSpan={categoryData.skillItems.length}
                          onClick={
                            resourceAllocate("skill-matrix-category.write")
                              ? () => { navigate({ pathname: "category/" + categoryData.skillCategory._id }); } : undefined
                          }
                          className={resourceAllocate("skill-matrix-category.write") ? "style-table-cell category-cell" : "category-cell"}
                        >
                          {categoryData.skillCategory.name}
                        </TableCell>
                      )}
                      <TableCell 
                        className={resourceAllocate("skill-matrix-item.write") ? "style-table-cell category-cell" : "category-cell"}
                        onClick={
                          resourceAllocate("skill-matrix-item.write")
                            ? () => { setState((prevState) => ({ ...prevState,editSkill: !prevState.editSkill, _skillId: skillItem._id })); } : undefined
                        }
                      >
                        {skillItem.name}
                      </TableCell>
                      {employees && employees.map((employee) => (
                        <TableCell key={employee._id}>
                          {employee.skillItems.find((item) => item._itemId._id === skillItem._id)?.nextScore || 0}
                        </TableCell>
                      ))}
                      {index === 0 && (
                        <TableCell className="action-cell" rowSpan={categoryData.skillItems.length}>
                          <GetActions
                            icons={[
                              { name: "Delete", method: () => { setOpenDeleteDialog(true); setSelectedCategory(categoryData.skillCategory._id); } },
                            ]}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell 
                      onClick={
                        resourceAllocate("skill-matrix-category.write")
                          ? () => { navigate({ pathname: "category/" + categoryData.skillCategory._id }); } : undefined
                      }
                      className={resourceAllocate("skill-matrix-category.write") ? "style-table-cell category-cell" : "category-cell"}
                    >{categoryData.skillCategory.name}</TableCell>
                    <TableCell className="category-cell" colSpan={employees ? employees.length + 1 : 2}>{""}</TableCell>
                    <TableCell className="action-cell">
                      <GetActions
                        icons={[
                          { name: "Delete", method: () => { setOpenDeleteDialog(true); setSelectedCategory(categoryData.skillCategory._id); } },
                        ]}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!skillItems && !employees && <ErrorMessage errorMessage={"Add some data to see"} />}
      <BootstrapDialog
        maxWidth="sm"
        onClose={() => { setOpenDeleteDialog(false); setSelectedCategory(""); }}
        aria-labelledby="customized-dialog-title"
        open={openDeleteDialog}
        scroll="paper"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => { setOpenDeleteDialog(false); setSelectedCategory(""); }}>
          {"What do you want to delete?"}
        </BootstrapDialogTitle>

        <Box className="dialog-body">
          <Tabs value={selectedTab} onChange={handleTabChange} aria-label="category and skill items tabs">
            <Tab label="Category" />
            <Tab label="Skill Items" />
          </Tabs>
          <Divider />
          {selectedTab === 0 && (
            <Box p={3}>
              <Typography>Are you sure you want to delete this entire category?</Typography>
            </Box>
          )}
          {selectedTab === 1 && (
            <Box p={3} className="skill-items-box">
              {skillItems?.data?.data?.length ? (
                skillItems.data.data.map((item) => (
                  <Box key={item._id}>
                    <FormControlLabel
                      key={item._id}
                      control={
                        <Checkbox
                          onChange={(event) => handleCheckboxChange(event, item._id)}
                          name={item.name}
                        />
                      }
                      label={item.name}
                    />
                  </Box>
                ))
              ) : (
                <Typography>No skill items available</Typography>
              )}
            </Box>
          )}
        </Box>

        <Divider />
        <DialogActions className="dialog-footer">
          <Button
            onClick={() => setOpenDeleteDialog(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            color="primary"
            disabled={selectedTab === 1 ? selectedSkills.length === 0 : false}
          >
            Delete
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Box className="pagination">
      <Pagination
          count={pagination.totalPages}
          page={pagination.page}
          onChange={onPageChange}
          color="primary"
      />
      </Box>
    </>
  );
};

export default SkillsTable;
