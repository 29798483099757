import "../style.scss";
import { Box, Grid, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { BarGraph } from "../../../components/graphs";
import { IRecruiterPerformanceRow, IRecruiterStatusRow } from "../../../interfaces";
import { capitalize } from "../../../utilities/helper";
import ReportService from "../../../services/report";
import AnalyticProgressCard from "../components/progress-card";
import Header from "../components/header";
import CustomTable from "../../../components/mui/table";

const performanceColumns = [
  {
    id: "recruiterName",
    label: "Recruiter Name",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    id: "candidateSource",
    label: "Candidate Source",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    id: "applied",
    label: "Applied",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    id: "interviewScheduled",
    label: "Interview Scheduled",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    id: "hired",
    label: "Hired",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    id: "rejected",
    label: "Rejected",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    id: "rateOfHiring",
    label: "Rate Of Hiring",
    minWidth: 100,
    maxWidth: 100,
  },
];

/**
 * It map table column keys with table row data
 * @params are the team performance
 * All the map keys will pass in table rows
 **/
const createPerformanceData = (
  recruiterName: string,
  candidateSource: number,
  applied: number,
  interviewScheduled: number,
  hired: number,
  rejected: number,
  rateOfHiring: number
) => {
  if (isNaN(rateOfHiring)) {
    rateOfHiring = 0;
  }
  return {
    recruiterName,
    candidateSource,
    applied,
    interviewScheduled,
    hired,
    rejected,
    rateOfHiring: rateOfHiring + "%",
  };
};

const statusColumns = [
  {
    id: "recruiterName",
    label: "Recruiter Name",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    id: "LA_Sent",
    label: "LA sent",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    id: "TA_Selected",
    label: "TA Selected",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    id: "HR_Selected",
    label: "HR Selected",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    id: "Offer_sent",
    label: "Offer Sent",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    id: "joined",
    label: "Joined",
    minWidth: 100,
    maxWidth: 100,
  },
];

/**
 * It map table column keys with table row data
 * @params are the team performance
 * All the map keys will pass in table rows
 **/
const createStatusData = (
  recruiterName: string,
  LA_Sent: string,
  TA_Selected: string,
  HR_Selected: string,
  Offer_sent: string,
  joined: string,
) => ({
  recruiterName,
  LA_Sent,
  TA_Selected,
  HR_Selected,
  Offer_sent,
  joined
});

const checkCount = (number: number | undefined) => {
  if (!number) return "N/A";
  const val = String(number);
  return val?.length ? val : "N/A";
};

const TeamPerformance = () => {
  let recruiterPerformanceRow: IRecruiterPerformanceRow[] = [];
  let recruiterStatusRow: IRecruiterStatusRow[] = [];
  const { teamPerformanceReport } = ReportService();
  const [searchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    department: searchParams.get("department") || "all",
    job: searchParams.get("job") || "all",
    typeOfLead: searchParams.get("typeOfLead") || "all",
    time: searchParams.get("time") || "all",
  });
  const getTeamPerformance = useQuery({
    queryKey: ["report-overview", filters],
    queryFn: () => teamPerformanceReport({ ...filters })
  });

  useEffect(() => {
    const job = searchParams.get("job") ? String(searchParams.get("job")) : "all";
    const department = searchParams.get("department") ? String(searchParams.get("department")) : "all";
    const typeOfLead = searchParams.get("typeOfLead") ? String(searchParams.get("typeOfLead")).toLowerCase() : "all";
    const time = searchParams.get("time") ? String(searchParams.get("time")) : "all";

    setFilters(prev => ({
      ...prev,
      job,
      department,
      typeOfLead,
      time
    }));
  }, [searchParams]);

  const countData = [
    {
      title: "Jobs posted",
      subtitle: "No. of jobs posted",
      count: getTeamPerformance.data?.data?.totalOpenJobs || 0,
      label: "jobs",
    },
    {
      title: "Jobs closed",
      subtitle: "No. of jobs closed",
      count: getTeamPerformance.data?.data?.totalCloseJobs || 0,
      label: "jobs",
    },
    {
      title: "Candidates hired",
      subtitle: "No. of candidates hired",
      count: getTeamPerformance.data?.data?.hiredcandidated || 0,
      label: "candidates",
    },
    {
      title: "Candidates rejected",
      subtitle: "No. of candidates rejected",
      count: getTeamPerformance.data?.data?.rejectedcandidated || 0,
      label: "candidates",
    },
    {
      title: "Avg. time to hire",
      subtitle: "Avg. time to hire in days",
      count: getTeamPerformance.data?.data?.hiredAverage || 0,
      label: "Days",
    },
    {
      title: "Avg. time to reject",
      subtitle: "Avg. time to reject in days",
      count: getTeamPerformance.data?.data?.rejectAverage || 0,
      label: "days",
    },
  ];

  const showData = (label: string) => {
    const data = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: ["rgba(17, 140, 252, 0.3)"],
        },
      ],
    };

    const interviewScheduledLabels: string[] = [];
    const interviewScheduledCount: number[] = [];
    getTeamPerformance.data?.data?.interviewScheduledByTeam?.map(candidate => {
      interviewScheduledLabels.push(candidate?._id) || "";
      interviewScheduledCount.push(candidate?.count || 0);
    });

    const hiredLabels: string[] = [];
    const hiredCount: number[] = [];
    getTeamPerformance.data?.data?.hiredcandidatedByTeam?.map(candidate => {
      hiredLabels.push(candidate?._id) || "";
      hiredCount.push(candidate?.count || 0);
    });

    if (label === "interviewScheduledByTeam") {
      return {
        ...data,
        labels: interviewScheduledLabels,
        datasets: [
          {
            label: "Candidates",
            data: interviewScheduledCount,
            backgroundColor: ["rgba(17, 140, 252, 0.3)"],
          },
        ],
      };
    } else if (label === "hiredcandidatedByTeam") {
      return {
        ...data,
        labels: hiredLabels,
        datasets: [
          {
            label: "Candidates",
            data: hiredCount,
            backgroundColor: ["rgba(17, 140, 252, 0.3)"],
          },
        ],
      };
    } else {
      return data;
    }
  };

  if (getTeamPerformance.data?.data?.teamPerformance) {
    recruiterPerformanceRow = getTeamPerformance.data?.data?.teamPerformance?.map(
      (candidate) => createPerformanceData(
        capitalize(candidate.name),
        candidate.source,
        candidate.applied,
        candidate.scheduled,
        candidate.hired,
        candidate.rejected,
        Math.round(
          (candidate.hired / (candidate.source + candidate.applied)) * 100
        )
      )
    );
  }

  if (getTeamPerformance.data?.data?.candidateStatusAverage) {
    const candidateStatusData = [] as { [key: string]: number }[];

    getTeamPerformance.data.data.candidateStatusAverage?.map(
      (recruiter) => {
        const payload = {
          name: recruiter._id
        } as any;  // eslint-disable-line

        recruiter.states?.forEach(status => {
          payload[status.status] = status.avgDays;
        });

        candidateStatusData.push(payload);
      }
    );

    recruiterStatusRow = candidateStatusData.map((recruiter: any) => createStatusData(   // eslint-disable-line
      recruiter.name,
      checkCount(recruiter["LA SENT"]),
      checkCount(recruiter["TA SELECTED"]),
      checkCount(recruiter["HR SELECTED"]),
      checkCount(recruiter["OFFER SENT"]),
      checkCount(recruiter["JOINED"]),
    )
    );
  }

  return (
    <>
      {/* Show Data  */}
      <Box marginTop="10px" height="calc(100vh - 145px)">
        <Header />

        <Box marginTop="16px" height="calc(100% - 70px)" overflow="auto">
          <Grid container spacing={2}>
            {/* show count  */}
            {countData.map((info, index) => <Grid key={index} item lg={2} md={4} sm={6}>
              <AnalyticProgressCard
                title={info.title}
                subtitle={info.subtitle}
                count={info.count}
                label={info.label}
              />
            </Grid>
            )}

            <Grid item sm={12}>
              <Box className="report-container">
                <Typography className="mb-1" variant="h6">
                  Recruiter Performance
                </Typography>
                <CustomTable
                  columns={performanceColumns}
                  rows={recruiterPerformanceRow}
                  width={"calc(100% - 2px)"}
                />
              </Box>
            </Grid>

            <Grid item sm={12}>
              <Box className="report-container">
                <Typography className="mb-1" variant="h6">
                  Recruiter Performance
                </Typography>
                <CustomTable
                  columns={statusColumns}
                  rows={recruiterStatusRow}
                  width={"calc(100% - 2px)"}
                  height="100%"
                />
              </Box>
            </Grid>

            <Grid item md={6} sm={12}>
              <BarGraph
                label="Candidates sourced over time"
                data={showData("interviewScheduledByTeam")}
              />
            </Grid>

            <Grid item md={6} sm={12}>
              <BarGraph
                label="Candidates sourced over time"
                data={showData("hiredcandidatedByTeam")}
              />
            </Grid>
          </Grid>

        </Box>
      </Box>
    </>
  );
};
export default TeamPerformance;