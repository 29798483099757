import Joi from "joi";

export const instructionValidation = Joi.object({
  name: Joi.string().required().max(25).messages({
    "string.empty": "Name is required",
    "string.max": "Maximum 25 characters are allowed"
  }),
  instructions: Joi.string().required().messages({
    "string.empty": "Instructions are required",
  })
});