import React from "react";
import { Tooltip, Typography } from "@mui/material";
interface CommonComponentProps {
    limit: number;
    label: string;
    onClick?: () => void;
    color?: "primary" | "error";
}

const CustomTypography: React.FC<CommonComponentProps> = ({
    limit,
    label,
    onClick,
    color
}) => {
    const truncatedName = label?.length > limit ? `${label.slice(0, limit)}...` : label;

    return (
        <div>
            <Tooltip
                title={truncatedName !== label ? (label) : ""}
                placement="bottom-start"
            >
                <span>
                    <Typography
                        onClick={onClick}
                        variant="body2"
                        color={color}
                        className="link-none-underline"
                    >
                        {truncatedName}
                    </Typography>
                </span>
            </Tooltip>
        </div>
    );
};

export default CustomTypography;
