import Joi from "joi";
import { required, } from "../../../shared";

export const EditSkillValidation = Joi.object({

    name: Joi.string()
        .regex(/^[a-zA-Z\s]+$/)
        .trim()
        .required()
        .max(80)
        .min(3)
        .label("Skill Matrix Name")
        .messages({
            "string.empty": required,
            "string.pattern.base": "Please enter a valid skill name",
            "string.max": "Maximum 80 characters are allowed",
            "string.min": "Minimum 3 characters are allowed"
        })
       
});
