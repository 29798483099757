import { Grid, TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import InlineDialog from "../../../../components/mui/inlineDialogue";
import { useNavigate } from "react-router-dom";
import { joiResolver } from "@hookform/resolvers/joi";
import { productValidations } from "../../../../validations/content/product";
import { IProjectsProductsField } from "../../../../interfaces/content/projects-products";
import { IAddProject } from "../../../../interfaces/content/projects-products";
import { IErrorResponse } from "../../../../interfaces";
import useSnackbar from "../../../../hooks/useSnackbar";
import ProjectsService from "../../../../services/content/projects-products";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useOutletContext } from "react-router-dom";

interface IOutletProps {
    reFetch: () => void,
    refetchProjects: () => void;
}
const AddProjects = () => {


    const navigate = useNavigate();
    const { snackbar } = useSnackbar();
    const { id } = useParams();
    const outlet = useOutletContext<IOutletProps>();
    const hitQuery = (id === "new") ? false : true;
    const { addContentProjects, updateContentProjects, getContentProject } = ProjectsService();
    const { handleSubmit, setValue, control, formState: { errors } } = useForm({
        resolver: joiResolver(productValidations),
        defaultValues: {
            name: "",
            domain: ""
        }
    });

    // get single project  based on id 
    const project = useQuery({
        queryKey: ["projects", id],
        queryFn: () =>
            getContentProject({ _id: id }),

        enabled: hitQuery
    });

    // we are filling the field when we are updating the name
    useEffect(() => {
        if (id !== "new") {
            if (project?.data?.data) {
                setValue("name", project?.data?.data?.name);
                setValue("domain", project.data.data.domain);
            }
        }
    }, [id, project?.data]);

    const fields: IProjectsProductsField[] = [
        {
            name: "name",
            type: "input",
            label: "Project/Product",
            placeholder: "Enter Project/Product",
            width: 6
        },
        {
            name: "domain",
            type: "input",
            label: "Project/Product Domain",
            placeholder: "Enter Project/Product Domain",
            width: 6
        }
    ];


    const onSubmit = async (data: IAddProject) => {

        try {
            if (id === "new") {

                const response = await addContentProjects(data);
                snackbar(response.message, "info");
                navigate("/content/products");
                outlet.reFetch && outlet.reFetch();
                outlet.refetchProjects && outlet.refetchProjects();
            } else {
                const payload = {
                    _id: id,
                    ...data
                };
                const response = await updateContentProjects(payload);
                outlet.reFetch && outlet.reFetch();
                outlet.refetchProjects && outlet.refetchProjects();
                snackbar(response.message, "info");
                navigate("/content/products");
            }
            outlet.refetchProjects && outlet.refetchProjects();
        } catch (error) {
            const err = error as IErrorResponse;
            console.log("Error in Add Projects", err);
            snackbar(err?.data?.message, "error");
        }
    };



    return (

        <InlineDialog
            onClose={() => navigate("/content/products")}
            onSubmit={handleSubmit(onSubmit)}
        >
            <Grid container spacing={2}>
                {

                    fields.map(field => {
                        if (field.type === "input") {
                            return (
                                <Grid key={field.name} item xs={field.width}>
                                    <Controller
                                        name={field.name}
                                        control={control}
                                        render={(prop) => <TextField
                                            placeholder={field.placeholder}
                                            label={field.label}
                                            inputProps={{

                                                maxLength: 30
                                            }}
                                            error={errors[field.name] ? true : false}
                                            helperText={errors[field.name]?.message}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                            );
                        }
                    })
                }
            </Grid>
        </InlineDialog>
    );
};

export default AddProjects;