import Joi from "joi";
import { required} from "../../shared";

export const jobtitleValidation = Joi.object({
    name: Joi.string()
        .required()
        .label("Name")
        .messages({
            "string.empty": required,
        }),
    _department: Joi.string()
        .required()
        .label("Department")
        .messages({
            "string.empty": required
        }),
});
