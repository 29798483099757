import { ChangeEvent, useEffect, useState } from "react";
import Header from "../../../components/header";
import CustomTable from "../../../components/mui/table";
import { useSearchParams } from "react-router-dom";
import useDebounce from "../../../hooks/useDebounce";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { ITypeOfContentStatus, ITypesOfContent, ITypesOfContentRows } from "../../../interfaces/content/types-of-content";
import TypesOfContentService from "../../../services/content/type-of-content";
import { useQuery } from "@tanstack/react-query";
import GetActions from "../../../components/get-actions";
import { IColumn, IErrorResponse } from "../../../interfaces";
import useSnackbar from "../../../hooks/useSnackbar";
import WarningDialog from "../../../components/mui/warning-dialog";
import { Box, Checkbox, Typography } from "@mui/material";
import { handleSelect, handleSelectAll } from "../common/helper";
import useResource from "../../../hooks/useResource";
const TypesOfContent = () => {

    const [search, setSearch] = useState("");
    const { snackbar } = useSnackbar();
    const { resourceAllocate } = useResource();
    const { getContentTypesOfContents, deleteContentTypesOfContent } = TypesOfContentService();
    const [searchParams, setSearchParams] = useSearchParams();
    const [openState, setOpenState] = useState(false);
    const navigate = useNavigate();
    const [state, setState] = useState<ITypeOfContentStatus>({
        multiDeleteWarning: false,
        selectAll: [],
        pagination: {
            page: 1,
            limit: 20,
            totalPages: 1,
            totalRecords: 0
        },
        searching: {
            search: ""
        },
        deleteWarning: false,
        _deleteId: ""
    });

    const typesOfContentDataList = useQuery({
        queryKey: ["typesOfContentDataList", state.pagination.page, state.searching],
        queryFn: () => getContentTypesOfContents({
            pagination: true,
            page: state.pagination.page,
            limit: state.pagination.limit,
            ...state.searching
        })
    });


    useEffect(() => {
        if (typesOfContentDataList?.data?.data?.length) {
            setState(prevState => ({
                ...prevState,
                pagination: {
                    ...prevState.pagination,
                    page: typesOfContentDataList?.data?.meta?.page,
                    totalPages: typesOfContentDataList?.data?.meta?.totalPages,
                    totalRecords: typesOfContentDataList?.data?.meta?.totalRecords
                }
            }));
        }
    }, [typesOfContentDataList?.data?.meta]);
    const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);
    const searchRecord = useDebounce(search, 1000);
    useEffect(() => {
        if (searchRecord.length) {
            setSearchParams(prev => ({
                ...prev,
                page: 1,
                search: searchRecord
            }));
        } else {
            searchParams.delete("search");
            setSearchParams(searchParams);
        }

    }, [searchRecord]);
    useEffect(() => {
        const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
        const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
        setState(prev => ({
            ...prev,
            pagination: {
                ...prev.pagination,
                page: page
            },
            searching: {
                ...prev.searching,
                search: search
            }
        }));

    }, [searchParams]);


    const isChecked = (typesOfContentDataList.data?.data?.length && state.selectAll?.length === typesOfContentDataList.data?.data?.length) ? true : false;
    const isIndeterminateChecked = (state.selectAll.length > 0 && state.selectAll.length < Number(typesOfContentDataList.data?.data.length)) ? true : false;
    const columns: IColumn[] = [
        {
            id: "all",
            label: <Checkbox onChange={e => handleSelectAll(e, typesOfContentDataList?.data?.data ? typesOfContentDataList?.data?.data : [], setState)} checked={isChecked} indeterminate={isIndeterminateChecked} />,
            maxWidth: 0
        },
        {
            id: "name",
            label: "Name",
            minWidth: 200
        },
        {
            id: "action",
            label: "Actions",
            align: "right",
        }
    ];

    const handleDelete = (_deleteId = "") => {
        setState(prevState => ({
            ...prevState,
            deleteWarning: !prevState.deleteWarning,
            _deleteId: _deleteId
        }));
    };
    const onDelete = async () => {
        try {
            const response = await deleteContentTypesOfContent({ ids: [state._deleteId] });
            setState(prevState => ({
                ...prevState,
                selectAll : prevState.selectAll.filter((del) => del !== state._deleteId)
            }));
            snackbar(response?.message, "info");
            handleDelete();
            typesOfContentDataList.refetch();
            navigate(
                {pathname : "/content/type-of-content",
                search: searchParams.toString()}
            );
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err?.data?.message, "error");
            console.log("Error in delete of types of content", err);
        }
    };
    const handleMultiDelete = () => setState(prevState => ({
        ...prevState,
        multiDeleteWarning: !prevState.multiDeleteWarning
    }));

    const onMultiDelete = async () => {
        try {
            console.log(state.selectAll);
            const goal = await deleteContentTypesOfContent({ ids: state.selectAll });
            snackbar(goal.message, "info");
            typesOfContentDataList.refetch();
            setState(prevState => ({
                ...prevState,
                multiDeleteWarning: false,
                selectAll: []
            }));
            navigate(
                {pathname : "/content/type-of-content",
                search: searchParams.toString()}
            );
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            handleMultiDelete();
            console.log({ "Error in delete project": error });
        }
    };
    const createRows = (data: ITypesOfContent, selectedAll: string[]) => {

        const action = <GetActions
            icons={[
                {
                    name: "Edit", method: () => {
                        setOpenState(true);
                        navigate({
                            pathname: "" + data._id,
                            search: searchParams.toString()
                        });
                    },
                    disabled: resourceAllocate("cms-content-types.write") ? false : true
                },
                { name: "Delete", method: () => handleDelete(data._id), disabled: resourceAllocate("cms-content-types.remove") ? false : true },
            ]}
        />;

        return {
            all: <Checkbox onChange={e => handleSelect(e, data._id, state, setState)} checked={selectedAll.includes(data._id)} />,
            name: data?.name,
            action
        };
    };


    let rows: ITypesOfContentRows[] = [];

    if (typesOfContentDataList?.data?.data) {
        rows = typesOfContentDataList?.data?.data?.map((data: ITypesOfContent) => createRows(data, state.selectAll));
    }


    const openManageComponent = () => {
        setOpenState(true);
        navigate("/content/type-of-content/new");
    };




    const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
        searchParams.set("page", page.toString());
        setSearchParams(searchParams);
    };

    return (
        <div>
            {openState && <Outlet context={{ ...Outlet, reFetch: typesOfContentDataList.refetch }} />}
            <Header
                className="my-2"
                btnText="Add Types of Content"
                onBtnClick={resourceAllocate("cms-content-types.write") ? () => openManageComponent() : undefined}
                onDelete={resourceAllocate("cms-content-types.remove") ? handleMultiDelete : undefined}
                isDeleteDisable={state.selectAll.length ? false : true}
                onSearch={onSearch}
                searchPlaceholder="Search by name"
            />
            <Box display="flex" className="mb-2" justifyContent="flex-end" alignItems="center">
                <Box display="flex" alignItems="center">
                    <Typography variant="body1">Total Types of Content:</Typography>
                    <Typography className="ml-3" variant="body1" >{typesOfContentDataList?.data?.meta?.totalRecords}</Typography>
                </Box>
            </Box>

            <CustomTable
                rows={rows}
                columns={columns}
                height={"calc(100vh - 285px)"}
                pagination={state.pagination}
                onPageChange={onPageChange}
            />


            <WarningDialog
                isOpen={state.deleteWarning}
                onClose={() => handleDelete()}
                onConfirm={onDelete}
                title="Delete Type of Content"
                description="Are you sure you want to delete this type of content?"

            />
            {/* Multiple Delete  */}
            <WarningDialog
                isOpen={state.multiDeleteWarning}
                onClose={() => handleMultiDelete()}
                onConfirm={onMultiDelete}
                title="Delete All Type of Content"
                description={`Are you sure you want to delete ${state.selectAll.length} selected types of content?`}
            />
        </div>
    );
};

export default TypesOfContent;