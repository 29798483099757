import { Autocomplete, Box, Chip, Grid, MenuItem, TextField, AutocompleteChangeReason } from "@mui/material";
import { useSelector } from "react-redux";
import { IUser } from "../../../../interfaces";
import { IEmployee } from "../../../../interfaces/skill-matrix/add-skill-matrix";
import { capitalize } from "../../../../utilities/helper";
import { FC, SyntheticEvent } from "react";
import { Control, UseFormReset, FieldErrors, Controller, UseFormSetValue, UseFormTrigger } from "react-hook-form";
import { IEmployeeActionDetail } from "../../../../interfaces/skill-matrix/action";

interface IProps {
    currentTab: number;
    reset: UseFormReset<IEmployeeActionDetail>;
    control: Control<IEmployeeActionDetail>
    errors: FieldErrors<IEmployeeActionDetail>
    selectedEmployee: string[];
    setSelectedEmployee: (value: string[]) => void;
    setEnteredValue: (value: string[]) => void;
    setValue: UseFormSetValue<IEmployeeActionDetail>;
    trigger: UseFormTrigger<IEmployeeActionDetail>;
    selectedUser: string[];
    setSelectedUsers: (value: string[]) => void;
}

const Employee: FC<IProps> = ({ control, errors, setSelectedEmployee, setValue, trigger, selectedUser, setSelectedUsers }) => {
    const users = useSelector<{ user: { list: IUser[] } }, IUser[]>(state => state.user.list);

    const onChangeUsersAutoComplete = (
        event: SyntheticEvent<Element, Event>,
        value: IUser[],
        reason: AutocompleteChangeReason
    ) => {
        let updatedSelectedUser: string[] = [...selectedUser];
        let newSelectedUser: string[];
        let removedUsers: string[];

        switch (reason) {
            case "selectOption":
                newSelectedUser = value.map((User) => User._id);
                newSelectedUser = newSelectedUser.filter((userId) => !updatedSelectedUser.includes(userId));
                updatedSelectedUser = [...updatedSelectedUser, ...newSelectedUser];
                break;
            case "removeOption":
                removedUsers = value.map((User) => User._id);
                updatedSelectedUser = updatedSelectedUser.filter((userId) => !removedUsers.includes(userId));
                break;
            default :
                break;
        }
        setSelectedUsers(updatedSelectedUser);
        setSelectedEmployee(updatedSelectedUser);
        setValue("employees", updatedSelectedUser);
        trigger("employees");
    };

    const handleDeleteUserChip = (users: string) => {
        const updatedUsers = selectedUser.filter((user) => user !== users);
        setSelectedUsers(updatedUsers);
        setSelectedEmployee(updatedUsers);
        setValue("employees", updatedUsers);
    };

    const fields: IEmployee[] = [
        {
            type: "select",
            name: "employees",
            label: "Employee Name*",
            children: [
                <MenuItem key={"none"} value="none" disabled>Select</MenuItem>,
            ],
        },
    ];


    return (
        <Box paddingTop="10px">
            <Box height="30vh" overflow="auto" paddingTop="10px">

                {/* Add Data  */}
                <Box marginBottom="20px">
                    <Grid container spacing={4}>
                        {
                            fields.map(field => {
                                if (field.type === "select") {
                                    return (
                                        <Grid key={field.label} item xs={16}>
                                            <Controller
                                                control={control}
                                                name={field.name}
                                                render={(prop) => (
                                                    <>
                                                        <Autocomplete
                                                            className="disable-text"
                                                            options={users
                                                                .map(
                                                                    (user) => user
                                                                )}
                                                            getOptionLabel={(option) => capitalize(option.name)}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    label={field.label}
                                                                    placeholder={field.placeholder}
                                                                    error={errors[field.name] ? true : false}
                                                                    helperText={errors[field.name]?.message}
                                                                />
                                                            )}
                                                            {...prop.field}
                                                            value={[]}
                                                            onChange={onChangeUsersAutoComplete}
                                                            multiple
                                                        />
                                                        {selectedUser.map((userId) => (
                                                            <Chip
                                                                key={userId}
                                                                label={users.find((user) => user._id === userId)?.name}
                                                                color="primary"
                                                                variant="outlined"
                                                                onDelete={() => handleDeleteUserChip(userId)}
                                                                sx={{ margin: "5px" }}
                                                            />
                                                        ))}
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                    );
                                }
                            })
                        }
                    </Grid>
                </Box>
            </Box>
        </Box>
    );

};

export default Employee;