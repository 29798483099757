import { useEffect, useState } from "react";
import { TrashService } from "../../../services";
import { useNavigate } from "react-router-dom";
import { IPagination } from "../../../interfaces";
import { ChangeEvent, MouseEvent } from "react";
import { useQuery } from "@tanstack/react-query";
import { Box, Grid, Button, Chip, IconButton } from "@mui/material";
import { useSearchParams, Outlet } from "react-router-dom";
import { ITrashRow, ITrashState, ICandidate } from "../../../interfaces";
import { capitalize, createIndex, formatMobileNumber } from "../../../utilities/helper";
import TrashFilter from "./filter";
import Header from "../../../components/header";
import useSnackbar from "../../../hooks/useSnackbar";
import useDebounce from "../../../hooks/useDebounce";
import CustomTable from "../../../components/mui/table";
import WarningDialog from "../../../components/mui/warning-dialog";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import CustomTypography from "../../../components/mui/max-length-limit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const Trash = () => {
  let rows: ITrashRow[] = [];
  const navigate = useNavigate();
  const { getTrashs, recoverTrash } = TrashService();
  const { snackbar } = useSnackbar();
  const [search, setSearch] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState<ITrashState>({
    recoverWarning: false,
    _trash: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filters: {
      assignTo: "all",
      search: "",
      jobId: [],
    },
    filterCount: 0,
  });

  const getTrash = useQuery({
    queryKey: ["allTrashs", state.pagination.page, state.filters],
    queryFn: () => getTrashs({
      page: state.pagination.page, limit: state.pagination.limit, sort: false,
    },
      { status: "DELETED", ...state.filters }
    )
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      const prevParams: { [index: string]: string } = {};
      searchParams.forEach((value, key) => {
        prevParams[key] = value;
      });
      setSearchParams(prev => ({
        ...prev,
        ...prevParams,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (getTrash.data?.data.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: getTrash.data.meta.page,
          totalPages: getTrash.data.meta.totalPages,
          totalRecords: getTrash.data.meta.totalRecords
        }
      }));
    }
  }, [getTrash.data?.meta]);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
    const jobId: { key: string, value: string }[] = searchParams.get("jobId") ? JSON.parse(String(searchParams.get("jobId"))) : [];
    const ctc: { isApply: boolean, value: number[] } = searchParams.get("ctc") ? JSON.parse(String(searchParams.get("ctc"))) : { isApply: false };
    const ectc: { isApply: boolean, value: number[] } = searchParams.get("ectc") ? JSON.parse(String(searchParams.get("ectc"))) : { isApply: false };
    const noticePeriod: { isApply: boolean, value: number[] } = searchParams.get("notice-period") ? JSON.parse(String(searchParams.get("notice-period"))) : { isApply: false };
    const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
    filterCount += jobId.length ? 1 : 0;
    filterCount += ctc.isApply ? 1 : 0;
    filterCount += ectc.isApply ? 1 : 0;
    filterCount += noticePeriod.isApply ? 1 : 0;
    filterCount += date.length ? 1 : 0;

    let createdAt: {
      startDate: string;
      endDate: string;
    } | undefined = undefined;
    if (date.length) {
      createdAt = {
        startDate: date[0]?.startDate,
        endDate: date[0]?.endDate,
      };
    }
    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search,
        jobId: jobId.map(jobId => jobId.key),
        ctc: ctc?.isApply ? { startAmount: ctc.value[0], endAmount: ctc.value[1] } : undefined,
        ectc: ectc?.isApply ? { startAmount: ectc.value[0], endAmount: ectc.value[1] } : undefined,
        noticePeriod: noticePeriod?.isApply ? { startAmount: noticePeriod.value[0], endAmount: noticePeriod.value[1] } : undefined,
        createdAt,
      },
      filterCount
    }));
  }, [searchParams]);

  const removeFilters = () => {
    searchParams.delete("jobId");
    searchParams.delete("ctc");
    searchParams.delete("ectc");
    searchParams.delete("notice-period");
    searchParams.delete("date");
    setSearchParams(searchParams);
  };

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const handleRecover = (_trash = "") => {
    setState(prevState => ({
      ...prevState,
      recoverWarning: !prevState.recoverWarning,
      _trash
    }
    ));
  };

  const onRecover = async () => {
    try {
      const recovered = await recoverTrash({ _id: state._trash });
      snackbar(recovered.message, "info");
      navigate({ pathname: "/trash/candidate-trash", search: searchParams.toString() });
      handleRecover();
      getTrash.refetch();

    } catch (error) {
      console.log(error);
    }
  };

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const columns = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "trash_name",
      label: "Name"
    },
    {
      id: "trash_email",
      label: "Email"
    },
    {
      id: "trash_phone",
      label: "Phone"
    },
    {
      id: "trash_jobType",
      label: "Job Type"
    },
    {
      id: "trash_jobTitle",
      label: "Job Title"
    },
    {
      id: "trash_experience",
      label: "Experience"
    },
    {
      id: "trash_ctc",
      label: "CTC"
    },
    {
      id: "trash_ectc",
      label: "ECTC"
    },
    {
      id: "trash_noticePeriod",
      label: "Notice Period"
    },
    {
      id: "trash_deletedBy",
      label: "Deleted By"
    },  
    {
      id: "action",
      label: "Action"
    }
   
  ];

  const onCopy = (label: string, text: string) => {
    navigator.clipboard.writeText(text);
    snackbar(`${capitalize(label)} ID copied`, "info");
  };

  const createRow = (index: number, candidate: ICandidate, pagination: IPagination) => {
    const action = (
      <Grid item md={3}>
        <Button fullWidth type="submit" onClick={() => handleRecover(candidate._id)} startIcon={<RestoreFromTrashIcon />}>
          RECOVER
        </Button>
      </Grid>
    );
    const email = (
      <Box display="flex" alignItems="center">
      <IconButton className="ml-2" onClick={() => onCopy("email", candidate?.email)} size="small">
        <ContentCopyIcon fontSize="small" />
      </IconButton >
      <CustomTypography
        limit={20}
        label={candidate?.email}
      />
      </Box>
    );

    return {
      id: createIndex(pagination, index),
      trash_name: (
        <CustomTypography
          limit={30}
          label={capitalize(candidate.name)}
        />
      ),
      trash_email: email,
      trash_phone: formatMobileNumber(candidate?.mobileNumber),
      trash_jobType: capitalize(candidate?.typeOfLead || ""),
      trash_jobTitle: capitalize(candidate?.jobId?.title || ""),
      trash_experience: (candidate?.experience || 0),
      trash_ctc: (candidate?.ctc || 0),
      trash_ectc: (candidate?.ectc || 0),
      trash_noticePeriod: (candidate?.noticePeriod || 0),
      trash_deletedBy : (candidate?.deletedBy?.name || "" ), 
      action,
    };
  };
  if (getTrash.data?.data.length) {
    rows = getTrash.data?.data.map((candidate, i) => createRow(i, candidate, state.pagination));
  }

  return (
    <>
      {/* Add Data  */}
      <Header
        searchPlaceholder="Search by name, email and phone"
        onSearch={onSearch}
        onFilter={openFilter}
      >
        <TrashFilter
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          OnClose={closeFilter}
        />
        {
          state.filterCount > 0 &&
          <Chip
            className="ml-2"
            label={`Filter Applied (${state.filterCount})`}
            color="warning"
            onDelete={removeFilters}
          />
        }
      </Header>
      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Recover Data  */}
      <WarningDialog
        isOpen={state.recoverWarning}
        onClose={() => handleRecover()}
        onConfirm={() => onRecover()}
        title="Recover Candidate"
        description="Are you sure you want to recover this candidate?"
      />
      <Outlet context={{ reFetch: getTrash.refetch }} />
    </>
  );
};
export default Trash;