import {
    Menu,
    Box,
    Chip,
    Typography,
    IconButton,
    Grid,
    List,
    Divider,
    ListItemButton,
    ListItemText,
    Checkbox,
    Button,
  } from "@mui/material";
  import { FC, useEffect, useState } from "react";
  import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
  import CloseIcon from "@mui/icons-material/Close";
  import { MenuItem } from "../../../../components/shared/filter";
  import { useSearchParams } from "react-router-dom";
  import { useNavigate } from "react-router-dom";
  import { ISubCategoryFilter } from "../../../../interfaces/content/sub-category";
  import { useQuery } from "@tanstack/react-query";
  import CategoryService from "../../../../services/content/category";

  interface props {
    anchorEl: null | HTMLElement;
    isOpen: boolean;
    onClose: () => void;
  }
  
  const SubCategorysFilter: FC<props> = ({ anchorEl, isOpen, onClose }) => {
    const [searchParams] = useSearchParams();
    const { getContentCategorys} = CategoryService();
    const navigate = useNavigate();
    const [state, setState] = useState<ISubCategoryFilter>({
      selectedMenu: 0,
      category: [],
    });
     


    const categorysData = useQuery({
      queryKey: ["category"],
      queryFn: () =>
        getContentCategorys({isActive: true}),
    });
    let category: { key: string; name: string }[] = [];
    useEffect(() => {
      const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
      
      setState(prevState => ({
          ...prevState,
          category
      }));
  }, [searchParams]);

    if (categorysData.data?.data?.length) {
      category = categorysData.data.data.map((item) => ({
        key: item._id,
        name: item.name,
      }));
    }
  
    const resetFilter = () => {
      setState({
        selectedMenu: 0,
        category: [],
      });
    };
  
    const onApply = () => {
      searchParams.set("category", JSON.stringify(state.category));
      searchParams.set("page", "1");
      navigate({
        pathname: "/content/sub-category",
        search: searchParams.toString(),
      });
      onClose();
    };
    const OnClose = () => {
      const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
      setState(prevState => ({
          ...prevState,
          category,
      }));
      onClose();
  };
  
    const deleteChip = (name: "category", key: string) => {
      let payload: Array<{
        key: string;
        value: string;
      }> = [];
  
      payload = state[name].filter((ele) => ele.key !== key);
      setState((prevState) => ({
        ...prevState,
        [name]: payload,
      }));
    };
  
    const handleLeftListItem = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      index: number
    ) => {
      setState((prevState) => ({
        ...prevState,
        selectedMenu: index,
      }));
    };
  
    const handleRightListItem = (
      name: "category",
      key: string,
      value: string
    ) => {
      let payload: Array<{
        key: string;
        value: string;
      }> = [];
  
      const isExist = state[name].find((ele) => ele.key === key) ? true : false;
      if (isExist) {
        payload = state[name].filter((ele) => ele.key !== key);
      } else {
        payload = state[name];
        payload.push({
          key,
          value,
        });
      }
  
      setState((prevState) => ({
        ...prevState,
        [name]: payload,
      }));
    };
  
    return (
      <>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={isOpen}
          onClose={onClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <Box id="filters-container">
            <Box
              className="center mb-3"
              justifyContent="space-between"
              alignItems="start"
            >
              <div className="active-filter mb-1">
                {state.category.length ? (
                  <>
                    {state.category.map((ele) => (
                      <Chip
                        key={ele.key}
                        className="m-1"
                        color="primary"
                        onDelete={() => deleteChip("category", ele.key)}
                        label={ele.value}
                        variant="outlined"
                      />
                    ))}
                  </>
                ) : (
                  <Box
                    className="mt-2"
                    display="flex"
                    alignItems="center"
                  >
                    <FilterAltOffIcon />
                    <Typography className="ml-2">
                      No filter are applied
                    </Typography>
                  </Box>
                )}
              </div>
              <IconButton
                onClick={OnClose}
                style={{ marginRight: "-10px" }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
  
            <Grid
              className="filter-box"
              container
            >
              <Grid
                id="left"
                item
                xs={5}
              >
                <List component="nav">
                  <MenuItem
                    index={0}
                    label="Category"
                    selectedMenu={state.selectedMenu === 0}
                    onChange={handleLeftListItem}
                    count={state.category}
                  />
                </List>
              </Grid>
              <Divider orientation="vertical" />
  
              <Grid
                id="right"
                item
                xs={6}
              >
                <List component="nav">
                  {state.selectedMenu === 0 &&
                    category.map((category) => (
                      <ListItemButton
                        key={category.key}
                        selected={
                          state.category.find((ele) => ele.key === category.key)
                            ? true
                            : false
                        }
                        onClick={() =>
                          handleRightListItem(
                            "category",
                            category.key,
                            category.name
                          )
                        }
                      >
                        <ListItemText primary={category.name} />
                        <Checkbox
                          edge="end"
                          checked={
                            state.category.find((ele) => ele.key === category.key)
                              ? true
                              : false
                          }
                        />
                      </ListItemButton>
                    ))}
                </List>
              </Grid>
            </Grid>
  
            <Box
              className="actions-btn"
              marginTop="8px"
              textAlign="end"
            >
              <Button
                variant="outlined"
                color="error"
                onClick={() => resetFilter()}
              >
                Clear All
              </Button>
              <Button
                className="ml-2"
                onClick={onApply}
              >
                Apply
              </Button>
            </Box>
          </Box>
        </Menu>
      </>
    );
  };
  
  export default SubCategorysFilter;
  