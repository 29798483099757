import Joi from "joi";
import { required } from "../../shared";

export const HighLightValidation = Joi.object({
  youtubeUrl: Joi.string()
    .uri()
    .label("YouTube URL")
    .required()
    .messages({
      "string.empty": required,
  }),
  title: Joi.string()
    .label("Title")
    .required()
    .min(3)
    .max(30)
    .messages({
      "string.empty": required,
      "string.min": "Title must be at least 3 characters long",
      "string.max": "Title must be at most 30 characters long",
  }),
  description: Joi.string()
    .label("Description")
    .allow(""),
  collegeId: Joi.string()
    .label("College Name")
    .required()
    .messages({
      "string.empty": required
  }),
});