import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useNavigate, useSearchParams, Outlet } from "react-router-dom";
import { Box, Chip, Switch, Typography } from "@mui/material";
import Header from "../../../components/header";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import useDebounce from "../../../hooks/useDebounce";
import useSnackbar from "../../../hooks/useSnackbar";
import {
  ISubCategory,
  ISubCategoryRow,
  ISubCategoryStatus,
} from "../../../interfaces/content/sub-category";
import { useQuery } from "@tanstack/react-query";
import SubCategoryService from "../../../services/content/sub-category";
import { IColumn, IErrorResponse } from "../../../interfaces";
import { capitalize, formatDateTime } from "../../../utilities/helper";
import useResource from "../../../hooks/useResource";
import WarningDialog from "../../../components/mui/warning-dialog";
import SubCategorysFilter from "./sub-category-filter";


const SubCategoryList = () => {
  const navigate = useNavigate();
  const { getContentSubCategorys, deleteContentSubCategory, updateContentSubCategory } = SubCategoryService();
  const [search, setSearch] = useState<string>("");
  const searchRecord = useDebounce(search, 1000);
  const [searchParams, setSearchParams] = useSearchParams();
  const { resourceAllocate } = useResource();
  const { snackbar } = useSnackbar();
  const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
  const [state, setState] = useState<ISubCategoryStatus>({
    pagination: {
      page: page,
      limit: 20,
      totalPages: 1,
      totalRecords: 0,
    },
    selectAll: [],
    multiDeleteWarning: false,
    filters: {
      search: "",
      category: [],
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false,
    },
    filterCount: 0,
    deleteWarning: false,
    _subCategory: "",
  });

  const subCategorys = useQuery({
    queryKey: ["sub-category", state.pagination.page, state.filters],
    queryFn: () =>
      getContentSubCategorys({
        pagination: true,
        page: state.pagination.page,
        limit: state.pagination.limit,
        ...state.filters,
      }),
  });
  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  useEffect(() => {
    if (searchRecord?.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (subCategorys.data?.data.length) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: subCategorys.data.meta.page,
          totalPages: subCategorys.data.meta.totalPages,
          totalRecords: subCategorys.data.meta.totalRecords,
        },
      }));
    }
  }, [subCategorys.data?.meta]);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";
    const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];

    filterCount += category.length ? 1 : 0;
    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        ...prevState.filters,
        search,
        categoryId: category.map(item => item.key)
      },
      filterCount
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
    navigate(`?${searchParams.toString()}`);
  };

  const handleDelete = (_subCategory = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _subCategory,
    }));
  };

  const onStatusUpdate = async (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    id: string
  ) => {
    const isActive = checked ? "true" : "false";
    try {
      const response = await updateContentSubCategory({ _id: id, isActive });
      snackbar(response.message, "info");
      subCategorys.refetch();

    } catch (error) {
      console.log("Error in sub-category status update", error);
    }
  };
  const createRows = (
    index: number,
    sub_category: ISubCategory
  ) => {
    const action = (
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () => {
              setAddState(true),
                navigate({
                  pathname: "" + sub_category._id,
                  search: searchParams.toString(),
                });
            },
            disabled: resourceAllocate("cms-sub-category.write") ? false : true,
          }
        ]}
      />
    );

    const isActive = <Switch
      disabled={resourceAllocate("cms-sub-category.write") ? false : true}
      checked={sub_category.isActive}
      onChange={(event, checked) => { onStatusUpdate(event, checked, sub_category._id); }}
    />;

    return {
      category_title: capitalize(sub_category?.name),
      no_of_blogs: "0",
      category_name: sub_category?.categoryId?.name,
      date: formatDateTime(sub_category?.updatedAt),
      created_by: capitalize(sub_category?.createdBy?.name),
      isActive,
      action,
    };
  };

  const columns: IColumn[] = [
    {
      id: "category_title",
      label: "Name",
    },
    {
      id: "no_of_blogs",
      label: "No. of Blogs",
    },
    {
      id: "category_name",
      label: "Category Name",
    },
    {
      id: "date",
      label: "Creation Date",
    },
    {
      id: "created_by",
      label: "Created by",
    },
    {
      id: "isActive",
      label: "Status"
    },
    {
      id: "action",
      label: "Action",
      align: "right"
    },
  ];
  let rows: ISubCategoryRow[] = [];
  if (subCategorys?.data?.data?.length) {
    rows = subCategorys?.data?.data?.map(
      (subCategory: ISubCategory, i: number) =>
        createRows(i, subCategory)
    );

  }

  const onDelete = async () => {
    try {
      const response = await deleteContentSubCategory({ ids: [state._subCategory] });
      snackbar(response.message, "info");
      handleDelete();
      subCategorys.refetch();

    } catch (error) {
      const err = error as IErrorResponse;
      console.log("Error in delete sub-category", err);

    }
  };

  const openManageImport = () => {
    setAddState(true);
    navigate("/content/sub-category/import");
  };

  const handleMultiDelete = () => setState(prevState => ({
    ...prevState,
    multiDeleteWarning: !prevState.multiDeleteWarning
  }));

  const onMultiDelete = async () => {
    try {
      const goal = await deleteContentSubCategory({ ids: state.selectAll });
      snackbar(goal.message, "info");
      subCategorys.refetch();
      setState(prevState => ({
        ...prevState,
        multiDeleteWarning: false,
        selectAll: []
      }));
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      handleMultiDelete();
      console.log({ "Error in delete project": error });
    }
  };
  const [addState, setAddState] = useState(false);
  const openAddBox = () => {
    setAddState(true);
    navigate("/content/sub-category/new");
  };

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const removeFilters = () => {
    searchParams.delete("category");
    setSearchParams(searchParams);
  };


  return (
    <div>
      {addState && <Outlet context={{ ...Outlet, reFetch: subCategorys.refetch }} />}
      <Header
        className="my-2"
        searchPlaceholder="Search by name"
        onSearch={onSearch}
        btnText="Add Sub-Category"
        onImport={resourceAllocate("cms-sub-category.write") ? () => openManageImport() : undefined}
        onBtnClick={resourceAllocate("cms-sub-category.write") ? () => openAddBox() : undefined}
        onFilter={openFilter}
      >
        <SubCategorysFilter
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          onClose={closeFilter}
        />
        {
          state.filterCount > 0 &&
          <Chip
            className="ml-2"
            label={`Filter Applied (${state.filterCount})`}
            color="warning"
            onDelete={removeFilters}
          />
        }
      </Header>
      <Box display="flex" className="mb-2" justifyContent="flex-end" alignItems="center">
          <Box display="flex" alignItems="center">
            <Typography variant="body1">Total Subcategories:</Typography>
            <Typography className="ml-3" variant="body1" >{subCategorys?.data?.meta?.totalRecords}</Typography>
          </Box>
        </Box>
      <Box id="custom-table" marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 285px)"
          errorMessage="Add user to see the data here"
          pagination={{
            page: state.pagination.page,
            totalPages: state.pagination.totalPages,
          }}
          onPageChange={onPageChange}
        />
      </Box>
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Subcategory"
        description="Are you sure you want to delete this subcategory?"
      />
      {/* Multiple Delete  */}
      <WarningDialog
        isOpen={state.multiDeleteWarning}
        onClose={() => handleMultiDelete()}
        onConfirm={onMultiDelete}
        title="Delete All Subcategories"
        description={`Are you sure you want to delete ${state.selectAll.length} selected ${state.selectAll.length > 1 ? "subcategories" : "subcategory"}?`}
      />
    </div>
  );
};
export default SubCategoryList;
