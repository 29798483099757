import InlineDialog from "../../../../components/mui/inlineDialogue";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  IAddTypesOfContent,
  ITypesOfContentField,
} from "../../../../interfaces/content/types-of-content";
import { Grid, TextField } from "@mui/material";
import { joiResolver } from "@hookform/resolvers/joi";
import { typesOfContentValidations } from "../../../../validations/content/types-of-content";
import { useOutletContext } from "react-router-dom";
import TypesOfContentService from "../../../../services/content/type-of-content";
import useSnackbar from "../../../../hooks/useSnackbar";
import { IErrorResponse } from "../../../../interfaces";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

interface IOutletProps {
  reFetch: () => void;
}

const AddTypesOfContent = () => {
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<IOutletProps>();
  const { id } = useParams();
  const { addContentTypesOfContent, updateContentTypesOfContent ,getContentTypesOfContent} =
    TypesOfContentService();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(typesOfContentValidations),
    defaultValues: {
      name: "",
    },
  });
  const hitQuery = !(id === "new");
  const  typesOfContent = useQuery({
    queryKey:["typesOfContent"],
    queryFn:() => getContentTypesOfContent({_id:id}),
    enabled: hitQuery
  });

  useEffect(() => {
     if(id !== "new"){
        setValue("name",typesOfContent?.data?.data?.name || "");
     }
  },[typesOfContent?.data?.data]);

  const fields: ITypesOfContentField[] = [
    {
      name: "name",
      label: "Name",
      type: "input",
      placeholder: "Enter Types of content",
    },
  ];

  const onSubmit = async (data: IAddTypesOfContent) => {
    try {
      if (id === "new") {
        const response = await addContentTypesOfContent(data);
        snackbar(response.message, "info");
        navigate("/content/type-of-content");
        outlet.reFetch && outlet.reFetch();
      } else {
        const response = await updateContentTypesOfContent({ _id: id, ...data });
        snackbar(response?.message, "info");
        navigate("/content/type-of-content");
        outlet.reFetch && outlet.reFetch();
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "error");
      console.log("Error in Add Types of content", err);
    }
  };
  return (
    <InlineDialog
      onClose={() => navigate("/content/type-of-content")}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={4}>
        {fields.map((field) => {
          if (field.type === "input") {
            return (
              <Grid
                key={field.name}
                item
                xs={field.width ? field.width : 12}
              >
                <Controller
                  name={field.name}
                  control={control}
                  render={(prop) => (
                    <TextField
                      label={field.label}
                      placeholder={field.placeholder}
                      inputProps={{
                        maxLength:30
                      }}
                      error={errors[field.name] ? true : false}
                      helperText={errors[field.name]?.message}
                      {...prop.field}
                    />
                  )}
                />
              </Grid>
            );
          }
        })}
      </Grid>
    </InlineDialog>
  );
};

export default AddTypesOfContent;
