import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { BarGraph, DonutGraph } from "../../../components/graphs";
import {
    useQuery
} from "@tanstack/react-query";
import ReportService from "../../../services/report";
import Header from "../components/header";
import { useSearchParams } from "react-router-dom";

const CpdAnalysis = () => {
    const { cpdReport } = ReportService();
    const [searchParams] = useSearchParams();
    const [filters, setFilters] = useState({
        department: searchParams.get("department") || "all",
        job: searchParams.get("job") || "all",
        typeOfLead: searchParams.get("typeOfLead") || "all",
        time: searchParams.get("time") || "all",
    });
    const getAllCount = useQuery({
        queryKey: ["report-overview", filters],
        queryFn: () => cpdReport({ ...filters })
    });

    useEffect(() => {
        const job = searchParams.get("job") ? String(searchParams.get("job")) : "all";
        const department = searchParams.get("department") ? String(searchParams.get("department")) : "all";
        const typeOfLead = searchParams.get("typeOfLead") ? String(searchParams.get("typeOfLead")).toLowerCase() : "all";
        const time = searchParams.get("time") ? String(searchParams.get("time")) : "all";

        setFilters(prev => ({
            ...prev,
            job,
            department,
            typeOfLead,
            time
        }));
    }, [searchParams]);

    const showData = (label: string) => {
        const multiColor = ["rgba(153, 102, 255, 0.3)", "rgba(54, 162, 235, 0.3)", "rgba(255, 99, 132, 0.3)", "rgba(255, 206, 86, 0.3)", "rgba(75, 192, 192, 0.2)", "rgba(254, 234, 230, 0.9)", "rgba(98, 54, 114, 0.3)", "rgba(54, 114, 61, 0.3)"];
        const data = {
            labels: [],
            datasets: [
                {
                    data: [],
                    backgroundColor: ["rgba(17, 140, 252, 0.3)"],
                },
            ],
        };

        const pipelineLabel: string[] = [];
        const pipelineData: number[] = [];
        getAllCount.data?.data?.pipeline?.forEach(cpd => {
            pipelineLabel.push(cpd._id);
            pipelineData.push(cpd.count);
        });

        const hiredLabel: string[] = [];
        const hiredData: number[] = [];
        getAllCount.data?.data?.statusBasedLeads?.forEach(cpd => {
            hiredLabel.push(cpd._id);
            hiredData.push(cpd.count);
        });

        const cpdLabels: string[] = [];
        const cpdCount: number[] = [];
        if (getAllCount.data?.data?.allCandidatesByDate) {
            Array.isArray(getAllCount.data?.data?.allCandidatesByDate) && getAllCount.data?.data?.allCandidatesByDate.forEach(cpd => {
                cpdLabels.push(cpd._id);
                cpdCount.push(cpd.count);
            });
        }
        if (label === "pipeline") {
            return {
                ...data,
                labels: pipelineLabel,
                datasets: [
                    {
                        label: "Count",
                        data: pipelineData,
                        backgroundColor: ["rgba(17, 140, 252, 0.3)"],
                    },
                ],
            };
        } else if (label === "status") {
            return {
                ...data,
                labels: hiredLabel,
                datasets: [
                    {
                        label: "Count",
                        data: hiredData,
                        backgroundColor: multiColor,
                    },
                ],
            };
        } else if (label === "totalApplied") {
            return {
                ...data,
                labels: cpdLabels,
                datasets: [
                    {
                        label: "Count",
                        data: cpdCount,
                        backgroundColor: ["rgba(17, 140, 252, 0.3)"],
                    },
                ],
            };
        } else {
            return data;
        }

    };

    return (
        <>
            <Box marginTop="10px" height="calc(100vh - 145px)">
                <Header />
                <Box marginTop="16px" height="calc(100% - 70px)" overflow="auto">
                    {/* show graph  */}
                    <Box marginTop="16px">
                        <Grid container spacing={2}>
                            <Grid item md={6}>
                                <DonutGraph
                                    label="On Campus Status"
                                    data={showData("status")}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <BarGraph
                                    label="On Campus Candidate Pipeline"
                                    data={showData("pipeline")}
                                    isStacked
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <BarGraph
                                    label="On Campus Total Applied"
                                    data={showData("totalApplied")}
                                    height={390}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default CpdAnalysis;