import "./style.scss";
import backArrowRoundIcon from "../../../../assets/images/back-arrow-round.svg";
import BasicDetail from "./basic-detail";
import Assignment from "./assignment";
import Content from "./content";
import BlogImage from "./blog-image";
import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { TabPanel } from "../../../../components/shared/mui-tabs";
import CommentIcon from "../../../../assets/images/Comment.svg";
import coloredCommentIcon from "../../../../assets/images/Comment-colored.svg";
import versionIcon from "../../../../assets/images/version-history.svg";
import timelineIcon from "../../../../assets/images/timeline.svg";

import coloredVersionIcon from "../../../../assets/images/version-history-colored.svg";

import Score from "./score";
import { useQuery } from "@tanstack/react-query";
import { blogService } from "../../../../services/blog";
import useResource from "../../../../hooks/useResource";
import useSnackbar from "../../../../hooks/useSnackbar";
import BlogTimeline from "../blog-timeline";
import Summary from "./summary";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface SideButtonProps {
  index: number;
  setActiveAction: (e: boolean) => void;
  // hasWritePermission: boolean;
}

const SideButton: FC<SideButtonProps> = ({ index, setActiveAction }) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };
  let label = "";
  let icon = "";
  let hoverIcon = "";
  if (index === 2) {
    label = "Comments";
    icon = CommentIcon;
    hoverIcon = coloredCommentIcon;
  } else if (index === 3) {
    label = "Version History";
    icon = versionIcon;
    hoverIcon = coloredVersionIcon;
  } else {
    return null;
  }

  return (
    <div
      className="note"
      onClick={() => setActiveAction(true)}
      style={{ cursor: "pointer" }}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
    >
      <Typography variant="caption">{label}</Typography>
      <img
        className="ml-2"
        src={isHovered && (index === 2 || index === 3) ? hoverIcon : icon}
      />
    </div>
  );
};

interface IState {
  redirectionLinks: string[];
}

const ManageBlog = () => {
  const [value, setValue] = useState(0);
  const { id } = useParams();
  const [blogId, setBlogId] = useState<string | undefined>("");
  const { resourceAllocate } = useResource();
  const [showTimeline, setShowTimeline] = useState(false);
  const { snackbar } = useSnackbar();
  const [activeAction, setActiveAction] = useState<boolean>(false);
  const [commentActiveAction, setCommentActiveAction] =
    useState<boolean>(false);
  const [searchParam, setSearchParam] = useSearchParams();
  const { getBlog } = blogService();

  const [state, setState] = useState<IState>({
    redirectionLinks: [],
  });

  useEffect(() => {
    const redirectionLinks = [...state.redirectionLinks];
    if (!redirectionLinks.length) {
      if (resourceAllocate("cms-blog.read")) {
        redirectionLinks.push("basic-detail");
      }
      if (resourceAllocate("cms-assignment.read")) {
        redirectionLinks.push("assignment");
      }
      if (resourceAllocate("cms-blog-image.read")) {
        redirectionLinks.push("blog-image");
      }
      if (resourceAllocate("cms-blog-content.read")) {
        redirectionLinks.push("content");
      }
      if (resourceAllocate("cms-blog-score.read")) {
        redirectionLinks.push("score");
      }
      if (resourceAllocate("cms-blog-summary.read")) {
        redirectionLinks.push("summary");
      }

      setState((prev) => ({
        ...prev,
        redirectionLinks,
      }));
    }
    const tab = searchParam.get("type")
      ? String(searchParam.get("type"))
      : "basic-detail";

    setValue(redirectionLinks.indexOf(tab));
  }, [state.redirectionLinks, searchParam]);

  const handlePublishClick = async () => {
    navigate({
      pathname: `/blog/manage/${id}/checklistPublish`,
      search: searchParam.toString(),
    });
  };

  const navigate = useNavigate();

  const hitQuery = id === "new" || id === "view" ? false : true;

  const blogData = useQuery({
    queryKey: ["blogs", id],
    queryFn: () => getBlog({ _id: id }),
    enabled: hitQuery,
  });

  useEffect(() => {
    if (blogData.data && blogData.data.data) {
      setBlogId(blogData?.data?.data?.blogId);
    }
  }, [blogData?.data?.data?.blogId, id]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    if (id === "new" && newValue !== 0) {
      snackbar("Please add basic details First.", "error");
      return;
    }
    searchParam.set("type", state.redirectionLinks[newValue]);
    setSearchParam(searchParam);
    setActiveAction(false);
  };
  const handleClick = () => {
    searchParam.delete("type");
    navigate({
      pathname: "/blog",
      search: searchParam.toString()
    });
  };
  const handleTimeLineClick = () => {
    setShowTimeline(true);
  };

  return (
    <div id="blog-manage">
      {/* header  */}
      <header className="header">
        <div className="title-box">
          <img src={backArrowRoundIcon} onClick={handleClick} />
          <Typography className="title" variant="h5">
            {id === "new" ? "Add New Blog" : "Blog Details"}
          </Typography>
        </div>

        <div className="save-box">
          <button
            className="p-2 header-buttons cursor-pointer"
            onClick={handleTimeLineClick}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "120px",
            }}
          >
            <img
              src={timelineIcon}
              alt="Timeline Icon"
              style={{ marginRight: "3px", height: "20px" }}
            />
            {/* <FilterListIcon color="inherit" /> */}
            <Typography variant="body1">Timeline</Typography>
          </button>
          {/* Remove this after discussion */}
          {(blogData?.data?.data?.status === "IN_PUBLISHING" ||
            blogData?.data?.data?.status === "COMPLETED" ||
            blogData?.data?.data?.status === "SCHEDULED") &&
            resourceAllocate("cms-blog-publish.write") && (
              <Button
                variant="outlined"
                className="ml-3 schedule-button"
                disabled={blogData?.data?.data?.isDraft}
                onClick={() =>
                  navigate({
                    pathname: `/blog/manage/${id}/schedule`,
                    search: searchParam.toString(),
                  })
                }
              >
                Schedule
              </Button>
            )}
          {(blogData?.data?.data?.status === "IN_PUBLISHING" ||
            blogData?.data?.data?.status === "COMPLETED" ||
            blogData?.data?.data?.status === "SCHEDULED") &&
            resourceAllocate("cms-blog-publish.write") && (
              <Button
                className="ml-3 publish-button header-buttons"
                disabled={blogData?.data?.data?.isDraft}
                onClick={handlePublishClick}
              >
                Publish
              </Button>
            )}
        </div>
      </header>

      {/* tabs  */}
      <Box width="100%">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {state.redirectionLinks &&
              state.redirectionLinks.map((link, index) => (
                <Tab
                  key={index}
                  className="tabs-space"
                  label={link.charAt(0).toUpperCase() + link.slice(1)}
                  {...a11yProps(0)}
                />
              ))}
          </Tabs>
        </Box>

        {state.redirectionLinks &&
          state.redirectionLinks.map(
            (link, index) =>
              link !== "basic-details" &&
              index === value && (
                <div key={index} className="blog-detail">
                  <div>
                    {id === "new" ? (
                      <Typography className="title" variant="body1">
                        Title
                      </Typography>
                    ) : (
                      <Typography className="title" variant="body1">
                        {blogData?.data?.data?.title}{" "}
                        {blogData?.data?.data?.isDraft && (
                          <span className="rectangle-badge">Draft</span>
                        )}
                      </Typography>
                    )}
                    {id !== "new" && (
                      <Typography
                        className="sub-text"
                        variant="caption"
                      >{`Blog ID : ${blogId}`}</Typography>
                    )}
                  </div>
                  {link === "blog-image" && (
                    <div
                      className="action"
                      onClick={() => setActiveAction(false)}
                    >
                      <SideButton
                        index={2}
                        setActiveAction={setCommentActiveAction}
                      />
                    </div>
                  )}
                  {link === "content" && (
                    <div style={{ display: "flex" }}>
                      <div
                        className="action"
                        onClick={() => setActiveAction(false)}
                      >
                        <SideButton
                          index={2}
                          setActiveAction={setCommentActiveAction}
                        />
                      </div>

                      <div
                        className="action ml-4"
                        onClick={() => setCommentActiveAction(false)}
                      >
                        <SideButton
                          index={3}
                          setActiveAction={setActiveAction}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )
          )}
        {showTimeline && <BlogTimeline setShowTimeline={setShowTimeline} />}

        {state.redirectionLinks &&
          state.redirectionLinks.map((link, index) => (
            <TabPanel key={index} value={value} index={index}>
              {link === "basic-detail" && <BasicDetail />}
              {link === "assignment" && (
                <Assignment
                  activeAction={activeAction}
                  setActiveAction={setActiveAction}
                />
              )}
              {link === "blog-image" && (
                <BlogImage
                  activeAction={commentActiveAction}
                  setActiveAction={setCommentActiveAction}
                />
              )}
              {link === "content" && (
                <Content
                  activeAction={activeAction}
                  setActiveAction={setActiveAction}
                  commentActiveAction={commentActiveAction}
                  setCommentActiveAction={setCommentActiveAction}
                />
              )}
              {link === "score" && <Score />}
              {link === "summary" && <Summary />}
            </TabPanel>
          ))}
      </Box>
    </div>
  );
};

export default ManageBlog;