import { FC, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";

interface props {
    value: string | undefined;
    onChange: (value: string) => void;
    height?: string;
}

const addKeyword = (keyword: string) => `<span style='background-color: #ed6c0259;  display: inline-block;  padding: 4px; border-radius: 4px;'><em>{{${keyword}}}</em></span>`;

const TinyEditor: FC<props> = ({ value, height, onChange }) => {

    // eslint-disable-next-line
    const uploadFile = (type: any) => new Promise((resolve, reject) => {
        const myfile = document.getElementById("myfile") as HTMLElement;
        myfile.click();

        // eslint-disable-next-line
        myfile.addEventListener("change", (e: any) => {
            const validate = type === "file" ? (/.*.(pdf|doc|docx|xls|xlsx)/i).test(e.target.value) : (/.*.(jpg|jpeg|png)$/i).test(e.target.value);
            if (validate) {
                const files = e.target.files;
                const formdata = new FormData();
                if (files?.length) {

                    // const fileName = files[0].name
                    formdata.append("file", files[0]);
                    // careerService.upload(formdata)
                    //     .then((response) => {
                    //         const payload = {
                    //             fileName,
                    //             url: response.data
                    //         }
                    //         resolve(payload)
                    //     })
                    //     .catch((error) => {
                    //         reject(error)
                    //         console.log(error);
                    //     })

                }
            }
        });
    });

    useEffect(() => {
        const handleFocusIn = (e: FocusEvent) => {
            const target = e.target as HTMLElement;
            if (target.closest(".mce-window") || target.closest(".tox-dialog")) {
                e.stopImmediatePropagation();
            }
        };

        document.addEventListener("focusin", handleFocusIn);

        return () => {
            document.removeEventListener("focusin", handleFocusIn);
        };
    }, []);

    return (
        <>
            <input type="file" id="myfile" hidden />
            <Editor
                onEditorChange={onChange}
                apiKey="skxa7v51z5447kqvwrn2537ip8zluwdrut616101llvcaj8m"
                // value={value}
                initialValue={value}

                init={{
                    // images_upload_handler: upload,
                    height: height ? height : 300,

                    readonly: false,
                    document_base_url: "http://www.example.com/path1/",
                    link_assume_external_targets: true,
                    plugins: [
                        "advlist", "autolink", "lists", "link", "image", "charmap", "preview",
                        "anchor", "searchreplace", "visualblocks", "code", "fullscreen",
                        "insertdatetime", "table", "code", "help", "wordcount"
                    ],
                    toolbar: `keywords  undo redo | blocks | 
                        bold italic forecolor | alignleft aligncenter 
                        alignright alignjustify | bullist numlist outdent indent | 
                        removeformat | help`,

                    file_picker_callback: function (callback, value, meta) {
                        // Provide file and text for the link dialog
                        if (meta.filetype === "file") {
                            uploadFile("file")
                                // eslint-disable-next-line
                                .then((file: any) => {
                                    callback(file.url, { text: file.fileName });
                                })
                                .catch((error) => console.log(error));
                        }

                        if (meta.filetype === "image") {
                            uploadFile("img")
                                // eslint-disable-next-line
                                .then((file: any) => {
                                    callback(file.url, { text: file.fileName });
                                })
                                .catch((error) => console.log(error));
                        }
                    },

                    setup: (editor) => {
                        editor.on("click", (e) => {
                            const target = e.target.closest("a");
                            if (target) {
                                const href = target.getAttribute("href");
                                if (href && href !== "#") {
                                    window.open(href, "_blank");
                                }
                            }
                        });
                        editor.ui.registry.addMenuButton("keywords", {
                            text: "Keywords",
                            fetch: function (callback) {
                                const items = [
                                    {
                                        type: "togglemenuitem",
                                        text: "Candidate Name",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("name"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "Engagement Type",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("engagementType"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "Email",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("email"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "Job Title",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("jobTitle"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "College Name",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("collegeName"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "Department",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("department"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "Location",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("location"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "Mobile Number",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("mobileNumber"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "Score",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("score"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "Stipend",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("stipend"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "Reporting Manager",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("reportingManager"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "Business Title",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("businessTitle"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "Date of Joining",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("DOJ"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "Portfolio URL",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("portfolioUrl"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "Campus Status",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("campusStatus"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "User",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("userName"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "User Phone Number",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("userNumber"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "User Department",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("userDepartment"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "User Designation",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("userDesignation"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "round",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("interviewRound"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "Interviewer",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("interviewer"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "date",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("interviewDate"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "Feedback Link",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("feedback_link"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "CPD Link",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("cpdLink"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "CoderByte Link",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("coderByteLink"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "Request Type",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("requestType"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "Work Location",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("workLocation"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "Skill Set Required",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("skillSetRequired"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "Hardware Name",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("hardwareName"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "Software Name",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("softwareName"));
                                        }
                                    },
                                    {
                                        type: "togglemenuitem",
                                        text: "Status",
                                        onAction: function () {
                                            editor.insertContent(addKeyword("status"));
                                        }
                                    },

                                ];
                                // eslint-disable-next-line
                                callback(items as any);
                            }
                        });
                    },

                    content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px } a { cursor: pointer; }",
                }}
            />
        </>
    );
};

export default TinyEditor;