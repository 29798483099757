import { Controller, useForm } from "react-hook-form";
import { Box, MenuItem, Grid, TextField, Checkbox } from "@mui/material";
import { useParams, useNavigate, useOutletContext, useLocation, useSearchParams } from "react-router-dom";
import { IColumn, IErrorResponse, IInterview, IInterviewField, IInterviewManageCheckBoxRow } from "../../../interfaces";
import { InterviewService, ParameterService } from "../../../services";
import Select from "../../../components/mui/select";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomDialog from "../../../components/mui/dialog";
import CustomTable from "../../../components/mui/table";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { joiResolver } from "@hookform/resolvers/joi";
import { InteriewValidation } from "../../../validations";
interface outletProps {
    reFetch: () => void
}

const ManageInterview = () => {
    let rows: IInterviewManageCheckBoxRow[] = [];
    const { id, type, types, typeOfLead, leadId } = useParams();
    const [searchParam] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { snackbar } = useSnackbar();
    const outlet = useOutletContext<outletProps>();
    const { getInterview, updateInterview } = InterviewService();
    const [focussedField, setFocussedField] = useState("");
    const { getParameters } = ParameterService();
    // eslint-disable-next-line
    const { handleSubmit, setValue, control, formState: { errors } } = useForm<any>({
        resolver: joiResolver(InteriewValidation),
        defaultValues: {
            status: "",
            score: "",
            feedback: "",
        }
    });
    const getInterviewDetail = useQuery({
        queryKey: ["interview"],
        queryFn: () => getInterview({
            _id: id
        })
    });
    const parameters = useQuery({
        queryKey: ["parameter"],
        queryFn: () => getParameters({})
    });
    const [state, setState] = useState<{
        checkboxTechnical: string[],
        checkboxNonTechnical: string[],
        inputTechnical: string[],
        inputNonTechnical: string[],
        skills: {
            [key: string]: string
        };
        parameters: {
            [key: string]: string
        };
    }>({
        checkboxTechnical: [
            "Technical skills",
            "Thought process",
            "logical ability"
        ],
        checkboxNonTechnical: [
            "Attitude towards work, technology, company, and colleagues",
            "Team player",
            "Leadership skills",
            "Communication Skills",
            "Clarity of thoughts",
            "Eagerness to learn and explore new things",
        ],
        inputTechnical: [
            "Long-term goals"
        ],
        inputNonTechnical: [
            "What new they can bring to the team"
        ],
        skills: {},
        parameters: {}
    });

    useEffect(() => {
        if (parameters.data?.data) {
            const checkboxTechnical = [
                "Technical skills",
                "Thought process",
                "logical ability"
            ];
            const checkboxNonTechnical = [
                "Attitude towards work, technology, company, and colleagues",
                "Team player",
                "Leadership skills",
                "Communication Skills",
                "Clarity of thoughts",
                "Eagerness to learn and explore new things",
            ];
            const inputTechnical = [
                "Long-term goals"
            ];
            const inputNonTechnical = [
                "What new they can bring to the team"
            ];

            parameters.data?.data?.map(parameter => {
                if (parameter.fieldType === "checkbox") {
                    if (parameter.type === "technical") {
                        checkboxTechnical.push(parameter.name);
                    } else {
                        checkboxNonTechnical.push(parameter.name);
                    }
                } else if (parameter.fieldType === "input") {
                    if (parameter.type === "technical") {
                        inputTechnical.push(parameter.name);
                    } else {
                        inputNonTechnical.push(parameter.name);
                    }
                }
            });

            setState(prevState => (
                {
                    ...prevState,
                    checkboxTechnical,
                    checkboxNonTechnical,
                    inputTechnical,
                    inputNonTechnical
                }
            ));
        }
    }, [parameters.data?.data]);

    useEffect(() => {
        if (getInterviewDetail.data?.data) {
            setState(prevState => (
                {
                    ...prevState,
                    skills: getInterviewDetail.data?.data?.skills ? getInterviewDetail.data?.data?.skills : {},
                    parameters: getInterviewDetail.data?.data?.parameters ? getInterviewDetail.data?.data?.parameters : {},
                }
            ));

            setValue("status", getInterviewDetail.data?.data.status);
            setValue("score", getInterviewDetail.data?.data.score || "");
            setValue("feedback", getInterviewDetail.data?.data.feedback);
        }
    }, [getInterviewDetail.data]);

    const handleSkill = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, skillName: string, skillLevel: string) => {

        const candidateSkill = state.skills;
        if (checked) {
            setState(prevState => (
                {
                    ...prevState,
                    skills: {
                        ...prevState.skills,
                        [skillName]: skillLevel
                    }
                }
            ));

        } else {
            delete candidateSkill[skillName];
            setState({
                ...state,
                skills: candidateSkill
            });
        }
    };

    const handleInputField = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => {
        if (event?.target) {
            const { name, value } = event.target;
            setState({
                ...state,
                parameters: {
                    ...state.parameters,
                    [name]: value
                }
            });
        }
    };

    const onClose = () => {
        if (location.pathname.split("/")[1] === "candidates") {
            navigate(-1);
        }
        else if (location.pathname.startsWith("/offered")) {
            navigate({ pathname: "/offered", search: searchParam.toString() });
        } else if (location.pathname.startsWith(`/assessments/review/${types}/${typeOfLead}`)) {
            navigate({ pathname: `/assessments/review/${types}/${typeOfLead}/manage/${leadId}`, search: searchParam.toString() });
        } else if (
            location.pathname.startsWith(`/assessments/interview/${type}/${typeOfLead}`)
        ) {
            navigate({ pathname: `/assessments/interview/${type}/${typeOfLead}/manage/${leadId}`, search: searchParam.toString() });
        }else {
            navigate({ pathname: "/assessments/interview/" + type, search: searchParam.toString() });
        }
    };

    const onSubmit = async (data: IInterview) => {
        try {
            if (id) {
                const payload = { ...data, parameters: state.parameters, skills: state.skills, _interview: id };
                const update = await updateInterview(id ? id : "", payload);
                snackbar(update.message, "info");
                onClose();
                outlet?.reFetch && outlet.reFetch();
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log(error);
        }
    };
    

    const fields: IInterviewField[] = [
        {
            label: "Status*",
            name: "status",
            type: "select",
            children:
                getInterviewDetail.data?.data?.title === "HR SCHEDULED"
                    ?
                    [
                        <MenuItem key={"HR SELECTED"} value="HR SELECTED">HR Selected</MenuItem>,
                        <MenuItem key={"HR REJECTED"} value="HR REJECTED">HR Rejected</MenuItem>
                    ]
                    :
                    getInterviewDetail.data?.data?.title === "TA SCHEDULED" ?
                    
                    [
                        <MenuItem key={"TA SELECTED"} value="TA SELECTED">TA Selected</MenuItem>,
                        <MenuItem key={"TA REJECTED"} value="TA REJECTED">TA Rejected</MenuItem>
                    ]
                    :
                    getInterviewDetail.data?.data?.title === "ROHIT SCHEDULED" ?
                    [
                        <MenuItem key={"ROHIT SELECTED"} value="ROHIT SELECTED">Rohit Selected</MenuItem>,
                        <MenuItem key={"ROHIT REJECTED"} value="ROHIT REJECTED">Rohit Rejected</MenuItem>
                    ]
                    :
                    undefined
        },
        {
            label: "Overall Score*",
            name: "score",
            type: "input",
        }
    ];

    const columns: IColumn[] = [
        {
            id: "parameter",
            label: "Parameters",
            minWidth: 120
        },
        {
            id: "not_good",
            label: "Not Good",
            minWidth: 90
        },
        {
            id: "below_average",
            label: "Below Average",
            minWidth: 90
        },
        {
            id: "average",
            label: "Average",
            minWidth: 90
        },
        {
            id: "above_average",
            label: "Above Average",
            minWidth: 90
        },
        {
            id: "good",
            label: "Good",
            minWidth: 90
        },
        {
            id: "very_good",
            label: "Very Good",
            minWidth: 90
        }
    ];

    const isChecked = (skillName: string, skillLevel: string) => {
        const candidateSkill = state?.skills || {};
        const keys = Object.keys(candidateSkill);
        let result = false;
        if (keys.includes(skillName)) {
            result = candidateSkill[skillName] === skillLevel ? true : false;
        }
        return result;
    };

    const createRow = (parameter: string) =>
    ({
        parameter: parameter,
        not_good: <Checkbox
            checked={isChecked(parameter?.toLowerCase(), "not good") ? true : false}
            onChange={(e, c) =>
                handleSkill(e, c, parameter?.toLowerCase(), "not good")
            }
        />,
        below_average: <Checkbox
            checked={isChecked(parameter?.toLowerCase(), "below")}
            onChange={(e, c) =>
                handleSkill(e, c, parameter?.toLowerCase(), "below")
            }
        />,
        average: <Checkbox
            checked={isChecked(parameter?.toLowerCase(), "average")}
            onChange={(e, c) =>
                handleSkill(e, c, parameter?.toLowerCase(), "average")
            }
        />,
        above_average: <Checkbox
            checked={isChecked(parameter?.toLowerCase(), "above average")}
            onChange={(e, c) =>
                handleSkill(e, c, parameter?.toLowerCase(), "above average")
            }
        />,
        good: <Checkbox
            checked={isChecked(parameter?.toLowerCase(), "good")}
            onChange={(e, c) =>
                handleSkill(e, c, parameter?.toLowerCase(), "good")
            }
        />,
        very_good: <Checkbox
            checked={isChecked(parameter?.toLowerCase(), "very good")}
            onChange={(e, c) =>
                handleSkill(e, c, parameter?.toLowerCase(), "very good")
            }
        />
    });

    if (state.checkboxTechnical.length) {
        rows = state.checkboxTechnical.map(parameter => createRow(parameter));
    }

    return (
        <Box>
            <CustomDialog
                title={"Edit Interview"}
                isOpen={id ? true : false}
                onClose={onClose}
                onSubmit={handleSubmit(onSubmit)}
            >
                <CustomTable
                    columns={columns}
                    rows={rows}
                />

                <Grid container spacing={4} style={{ marginTop: "1px" }}>
                    {
                        fields.map(field => {
                            if (field.type === "input") {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <TextField
                                            label={field.label}
                                            className="disable-text"
                                            variant="outlined"
                                            size="small"
                                            error={errors[field.name] ? true : false}
                                            helperText={errors[field.name]?.message?.toString() ?? ""}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                );
                            } else {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <Select
                                        control={control}
                                        name={field.name}
                                        label={field.label}
                                        size="small"
                                        variant="outlined"
                                        error={errors[field.name] ? true : false}
                                        helperText={errors[field.name]?.message?.toString() ?? ""}
                                    >
                                        {field.children}
                                    </Select>
                                </Grid>
                                );
                            }
                        })
                    }

                    {
                        state.inputTechnical.map(parameter => (<Grid key={parameter} item xs={12} md={6}>
                            <TextField
                                label={parameter}
                                className="disable-text"
                                variant="outlined"
                                size="small"
                                InputLabelProps={{
                                    shrink: focussedField === parameter || (state.parameters[parameter?.toLowerCase()]?.length ? true : false)
                                }}
                                onFocus= {() => {
                                    setFocussedField(parameter);
                                }}
                                onBlur={() => {
                                    setFocussedField("");
                                }}
                                onChange={e => handleInputField(e)}
                                name={parameter?.toLowerCase()}
                                value={state.parameters[parameter?.toLowerCase()] ? state.parameters[parameter?.toLowerCase()] : ""}
                            />
                            {/* <Controller
                                control={control}
                                name={parameter}
                                render={(prop) => <TextField
                                    label={parameter}
                                    className="disable-text"
                                    variant="outlined"
                                    size="small"
                                    error={errors[parameter] ? true : false}
                                    {...prop.field}
                                    onChange={e => handleInputField(e)}
                                    value={state.parameters[parameter?.toLowerCase()]}
                                />}
                            /> */}
                        </Grid>
                        )
                        )
                    }
                </Grid>
                <Grid item xs={12} style={{ paddingTop: "32px" }}>
                    <Controller
                        control={control}
                        name="feedback"
                        render={(prop) => (
                            <TextField
                                label="Note"
                                className="disable-text"
                                variant="outlined"
                                size="small"
                                placeholder="Type note here"
                                multiline
                                minRows={3}
                                {...prop.field}
                            />
                        )}
                    />
                </Grid>
            </CustomDialog>
        </Box>
    );

};

export default ManageInterview;