import { Autocomplete, Box, Chip, Grid, MenuItem, SelectChangeEvent, TextField } from "@mui/material";
import { IErrorResponse } from "../../../interfaces";
import { IVendor, IVendorField } from "../../../interfaces/vendor";
import { useNavigate, useOutletContext } from "react-router-dom";
import useSnackbar from "../../../hooks/useSnackbar";
import { VendorsService } from "../../../services/vendor";
import { Controller, useForm } from "react-hook-form";
import CustomDialog from "../../../components/mui/dialog";
import CustomLabel from "../../../components/mui/custom-label";
import { useQuery } from "@tanstack/react-query";
import { capitalize } from "../../../utilities/helper";
import Select from "../../../components/mui/select";
import { SyntheticEvent, useEffect, useState } from "react";
import { AddVendorValidation } from "../../../validations/vendor/add-vendor";
import { joiResolver } from "@hookform/resolvers/joi";
import { VendorCategoryService } from "../../../services/vendor/vendor-category";
import { VendorServiceService } from "../../../services/vendor/vendor-service";
import { IVendorService } from "../../../interfaces/vendor/vendor-service";
import MobileNumber from "../../../components/mui/mobile-number";

interface outletProps {
    reFetch: () => void
}

const AddVendor = () => {
    const navigate = useNavigate();
    const { snackbar } = useSnackbar();
    const outlet = useOutletContext<outletProps>();
    const { addVendor } = VendorsService();
    const { handleSubmit, control, setValue, trigger, watch, getValues, formState: { errors } } = useForm<IVendor>({
        resolver: joiResolver(AddVendorValidation),
        defaultValues: {
            vendorName: "",
            email: "",
            website: "",
            address: "",
            phoneNumber: "",
            _vendorCategory: "",
            _vendorPrimaryServices: [],
            _vendorSecondaryServices: []
        }
    });

    const [ selectedPrimaryServices, setSelectedPrimaryServices ] = useState<string[]>([]);
    const [ selectedSecondaryServices, setSelectedSecondaryServices ] = useState<string[]>([]);

    const { getVendorPartialCategory } = VendorCategoryService();
    const { getVendorPartialService } = VendorServiceService();

    const categories = useQuery({
      queryKey: ["allCategores"],
      queryFn: () =>
        getVendorPartialCategory({
          type: "SERVICE",
        }),
    });

    const services = useQuery({
        queryFn: () => getVendorPartialService({ _category: watch("_vendorCategory") }),
        enabled: !!watch("_vendorCategory"), 
      });
    const servicesData = (services && services.data && services.data.data) || [];
    
      useEffect(() => {
        if (watch("_vendorCategory")) {
            services.refetch();
        }
      }, [watch("_vendorCategory")]);

    const onChangeServiceAutoComplete = (
        event: SyntheticEvent<Element, Event>,
        value: IVendorService[],
        field: IVendorField
      ) => {
        const serviceData = getValues(field.name) as string[];
        const isExist = serviceData && serviceData.includes(value[0]._id);
            if (!isExist) {
                if(field.name === "_vendorPrimaryServices"){
                    setSelectedPrimaryServices(prevServices => [...prevServices, value[0]._id]);
                }else{
                    setSelectedSecondaryServices(prevServices => [...prevServices, value[0]._id]);
                }
              serviceData && serviceData.push(value[0]._id);
              setValue(field.name, serviceData);
            }
        trigger(field.name);
      };

      const [stateNo, setStateNo] = useState({
        phoneNumber: {
            country: "INDIA",
            dialCode: "+91",
            iso2: "IN"
        },
    });

      const getFilteredOptions = (fieldName: string) => {
        if (fieldName === "_vendorSecondaryServices") {
          return servicesData.filter(service => !selectedPrimaryServices.includes(service._id));
        } else {
          return servicesData.filter(service => !selectedSecondaryServices.includes(service._id));
        }
      };

      const onNumberChange = (e: SelectChangeEvent<string>) => {
        const { name, value } = e.target;
        const data = value.split(":");
    
        setStateNo(prev => ({
            ...prev,
            [name]: {
                country: data[0],
                iso2: data[1],
                dialCode: data[2]
            }
        }));
    };


    const onSubmit = async (data: IVendor) => {
        try {
            const payload = {
                ...data,
                mobileNumber: {
                    ...stateNo.phoneNumber,
                    number: data.phoneNumber,
                },
            };
            delete payload.phoneNumber;
            const add = await addVendor(payload);
            snackbar(add.message, "info");
            navigate("/directory/vendors");
            outlet?.reFetch && outlet.reFetch();
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log(error);
        }
    };
    

    const fields: IVendorField[] = [
        {
            label: "Name",
            name: "vendorName",
            type: "input",
            placeholder: "Type vendor name here",
            required: true
        },
        {
            label: "Email Address",
            name: "email",
            type: "input",
            placeholder: "Type vendor email here",
        },
        {
            label: "Website",
            name: "website",
            type: "input",
            placeholder: "Type vendor website here",
        },
        {
            label: "Address",
            name: "address",
            type: "input",
            placeholder: "Type vendor address here",
        },
        {
            label: "Category",
            name: "_vendorCategory",
            type: "select",
            required: true,
            children: categories?.data?.data.map((category) => <MenuItem key={category._id} value={category._id} >{capitalize(category.name)}</MenuItem>) 
        },
        {
            label: "Primary Services",
            name: "_vendorPrimaryServices",
            type: "select",
            required: true,
            disabled: !watch("_vendorCategory")
        },
        {
            label: "Secondary Services",
            name: "_vendorSecondaryServices",
            type: "select",
            disabled: !watch("_vendorCategory")
        },
        {
            label: "Payment Frequency",
            name: "paymentFrequency",
            type: "select",
            children: [
              <MenuItem key="ONCE" value="ONCE" >Once</MenuItem>,
              <MenuItem key="DAILY" value="DAILY" >Daily</MenuItem>,
              <MenuItem key="WEEKLY" value="WEEKLY" >Weekly</MenuItem>,
              <MenuItem key="MONTHLY" value="MONTHLY" >Monthly</MenuItem>,
              <MenuItem key="QUARTERLY" value="QUARTERLY" >Quarterly</MenuItem>,
              <MenuItem key="SEMI-ANNUALLY" value="SEMI-ANNUALLY" >Semi-annually</MenuItem>,
              <MenuItem key="ANNUALLY" value="ANNUALLY" >Annually</MenuItem>
            ],
            required: true,
        },
        {
            type: "mobile-number",
            name: "phoneNumber",
            label: "Mobile Number",
            placeholder: "Type mobile number here",
            required: true
        },
        {
            label: "Rating",
            name: "rating",
            type: "number",
            placeholder: "Type vendor rating here",
        },
    ];

    return (
        <Box>
            <CustomDialog
                isOpen={true}
                onClose={() => navigate("/directory/vendors")}  
                title="Add Vendor"
                onSubmit={handleSubmit(onSubmit)}
                confirmText="Add Vendor"
            >
                <Grid container spacing={4}>
                    {
                        fields.map(field => {
                            if (field.type === "input") {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <TextField
                                            label={<CustomLabel label={field.label} required={field?.required} />}
                                            className="disable-text"
                                            variant="outlined"
                                            size="small"
                                            placeholder={field.placeholder}
                                            error={!!errors[field.name]}
                                            helperText={errors[field.name]?.message}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                );
                            }
                            else if (field.type === "number") {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <TextField
                                            label={<CustomLabel label={field.label} required={field?.required} />}
                                            className="disable-text"
                                            variant="outlined"
                                            size="small"
                                            error={!!errors[field.name]}
                                            helperText={errors[field.name]?.message}
                                            {...prop.field}
                                            onKeyDown={(evt) => ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()}
                                            inputProps={{
                                                pattern: "[0-9.]*",
                                                inputMode: "decimal",
                                                onInput: (e) => {
                                                    const inputValue = e.currentTarget.value;
                                                    const sanitizedValue = inputValue.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
                                                    const valueAsNumber = parseFloat(sanitizedValue);
                                                    if (!isNaN(valueAsNumber) && valueAsNumber <= 5) {
                                                        e.currentTarget.value = sanitizedValue;
                                                        prop.field.onChange(sanitizedValue);
                                                    } else if (sanitizedValue === "" || valueAsNumber <= 5) {
                                                        e.currentTarget.value = sanitizedValue;
                                                        prop.field.onChange(sanitizedValue);
                                                    } else {
                                                        e.currentTarget.value = sanitizedValue.slice(0, -1);
                                                    }
                                                },
                                            }}
                                        />}
                                    />
                                </Grid>
                                );
                            }
                            else if (field.type === "mobile-number") {
                                const numberState = "phoneNumber";
                                return <Grid key={field.label} item xs={12} md={6} >
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <MobileNumber
                                            key={field.label}
                                            className="disable-text"
                                            NumberFieldLabel={<CustomLabel label={field.label} required={field?.required} />}
                                            dialCodeValue={`${stateNo[numberState].country}:${stateNo[numberState].iso2}:${stateNo[numberState].dialCode}`}
                                            dialCodeName={field.name}
                                            onChange={onNumberChange}
                                            error={!!errors[field.name]}
                                            helperText={errors[field.name]?.message}
                                            other={prop.field}
                                        />}
                                    />
                                </Grid>;
                            }
                            else if(field.name === "_vendorCategory"){
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <Select
                                        control={control}
                                        name={field.name}
                                        label={<CustomLabel label={field.label} required={field?.required} />}
                                        size="small"
                                        variant="outlined"
                                        error={!!errors[field.name]}
                                        helperText={errors[field.name]?.message}
                                    >
                                        {field.children}
                                    </Select>
                                </Grid>
                                );
                            }else if(field.name === "paymentFrequency") {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                  <Select
                                    control={control}
                                    name={field.name}
                                    label={<CustomLabel label={field.label} required={field?.required} />}
                                    error={!!errors[field.name]}
                                    helperText={errors[field.name]?.message}
                                  >
                                    {field.children}
                                  </Select>
                                </Grid>
                                );
                            }else{
                                return(
                                    <Grid key={field.label} item xs={12} md={6}>
                                    <Controller
                                      control={control}
                                      name={field.name}
                                      render={(prop) => (
                                        <>
                                            <Autocomplete
                                                fullWidth
                                                options= {getFilteredOptions(field.name)}
                                                getOptionLabel={(option) => capitalize(option.name)}
                                                renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    size="small"
                                                    label={<CustomLabel label={field.label} required={field?.required} />}
                                                    placeholder={field.placeholder}
                                                    error={!!errors[field.name]}
                                                    helperText={errors[field.name]?.message}
                                                />
                                                )}
                                                {...prop.field}
                                                value={[]}
                                                onChange={(e, value) =>
                                                    onChangeServiceAutoComplete(e, value, field)
                                                }
                                                disabled={field.disabled}
                                                multiple
                                            />
                                                {((getValues(field.name) as string[]) || []).map(
                                                (item) => (
                                                    <Chip
                                                    key={item}
                                                    style={{margin: "5px"}}
                                                    label={
                                                        servicesData.find(
                                                          (title) => title._id === item
                                                        )?.name
                                                      }
                                                    color="primary"
                                                    onDelete={() => {
                                                        setValue(
                                                        field.name,
                                                        (getValues(field.name) as string[])?.filter(
                                                            (value) => value !== item
                                                        )
                                                        );
                                                        if(field.name === "_vendorPrimaryServices"){
                                                            setSelectedPrimaryServices(prevServices => 
                                                                prevServices.filter(service => service !== item)
                                                            );
                                                        }else{
                                                            setSelectedSecondaryServices(prevServices => 
                                                                prevServices.filter(service => service !== item)
                                                            );
                                                        }
                                                    }}
                                                    variant="outlined"
                                                    />
                                                ))}
                                            </>
                                            )}
                                        />
                                    </Grid>
                                );
                            }
                        })
                    }
                </Grid>
            </CustomDialog>
        </Box>
    );

};

export default AddVendor;