import { ChangeEvent, MouseEvent } from "react";
import { Box, Chip, Typography, IconButton, FormControl, Select, MenuItem, SelectChangeEvent, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { IPagination, IStatus, IUser } from "../../../interfaces";
import { cpdDriveService } from "../../../services";
import { Outlet, useNavigate, useSearchParams, useOutletContext } from "react-router-dom";
import { ICpdDriveRow, ICpdDriveState, ICpdDrive ,IExportCpdDataResponse} from "../../../interfaces";
import { capitalize, createIndex, formatDate, displayName } from "../../../utilities/helper";
import Header from "../../../components/header";
import useDebounce from "../../../hooks/useDebounce";
import useSnackbar from "../../../hooks/useSnackbar";
import DriveFilters from "../Components/drive-filter";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import useResource from "../../../hooks/useResource";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSelector } from "react-redux";
import useUser from "../../../hooks/useUser";
import FeedIcon from "@mui/icons-material/FileCopy";

interface outletProps {
  refetchDrivess: () => void;
}


const Drives = () => {
  let rows: ICpdDriveRow[] = [];
  const { getCpdDrives, updateCpdDrive, deleteCpdDrives, downloadCpDriveData } = cpdDriveService();
  const { snackbar } = useSnackbar();
  const { resourceAllocate, allowPermission } = useResource();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user } = useUser();
  const users = useSelector<{ user: { list: IUser[] } }, IUser[]>(state => state.user.list);
  const userIds = users.map(user => user._id);
  const statusList = useSelector<{ status: { list: IStatus[] } }, IStatus[]>(state => state.status.list);
  const statusNames = statusList.map(user => user.name.toUpperCase());
  const outlet = useOutletContext<outletProps>();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<ICpdDriveState>({
    deleteWarning: false,
    _cpdDrive: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    filters: {
      search: "",
      status: [],
      _recruiter: [],
      category: [],
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filterCount: 0,
  });

  const getDriveData = useQuery({
    queryKey: ["allDrives", state.pagination.page, state.filters],
    queryFn: () => getCpdDrives({
      pagination: true, limit: state.pagination.limit, page: state.pagination.page, ...state.filters
    })
  });

  const actionNavigate = (_id: string, type: string) => {
    searchParams.set("type", type);
    navigate({ pathname: "action/" + _id, search: searchParams.toString() });
  };

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      const prevParams: { [index: string]: string } = {};
      searchParams.forEach((value, key) => {
        prevParams[key] = value;
      });

      setSearchParams(prev => ({
        ...prev,
        ...prevParams,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (getDriveData.data?.data.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: getDriveData.data.meta.page,
          totalPages: getDriveData.data.meta.totalPages,
          totalRecords: getDriveData.data.meta.totalRecords
        }
      }));
    }
  }, [getDriveData.data?.meta]);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
    const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
    const _recruiter: { key: string, value: string }[] = searchParams.get("_recruiter") ? JSON.parse(String(searchParams.get("_recruiter"))) : [];
    const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
    filterCount += status.length ? 1 : 0;
    filterCount += _recruiter.length ? 1 : 0;
    filterCount += category.length ? 1 : 0;
    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search,
        status: status.map(status => status.key),
        _recruiter: _recruiter.map(_recruiter => _recruiter.key),
        category: category.map(category => category.key),
      },
      filterCount
    }));
  }, [searchParams]);

  const removeFilters = () => {
    searchParams.delete("status");
    searchParams.delete("_recruiter");
    searchParams.delete("category");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const fullName = `${(displayName(user))}`;
    searchParams.set("_recruiter", JSON.stringify([{ key: user?._id, value: fullName }]));
    setSearchParams(searchParams);
  }, []);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const handleDelete = (_cpdDrive = "") => {
    setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _cpdDrive
    }
    ));
  };

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const onDelete = async () => {
    try {
      const deleted = await deleteCpdDrives({ _id: state._cpdDrive });
      snackbar(deleted.message, "info");
      handleDelete();
      getDriveData.refetch();
      outlet?.refetchDrivess && outlet.refetchDrivess();
    } catch (error) {
      console.log(error);
    }
  };

  const onUpdate = async (event: SelectChangeEvent<string>, _id: string, collegeId: string) => {
    const { name, value } = event.target;
    try {
      const payload = {
        _id,
        collegeId,
        [name]: value
      };

      const updated = await updateCpdDrive(_id, payload);
      snackbar(updated.message, "info");
      getDriveData.refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const onCopy = (label: string) => {
    navigator.clipboard.writeText(label);
    snackbar(`${(label)} ID copied`, "info");
  };

  const onDownloadClick = async (id: string) => {
    try {
      const data: IExportCpdDataResponse = await downloadCpDriveData({ "cpdId": id});
      if( !data?.data){
        snackbar(`No candidates found for cpdId ${id}`);
        return;
      }
      const binaryData = atob(data?.data);
      const bytes = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        bytes[i] = binaryData.charCodeAt(i);
      }
      const blob = new Blob([bytes], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const file = new File([blob], "temp.xlsx", { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const link = document.createElement("a");
      document.body.appendChild(link);
      link.setAttribute("href", window.URL.createObjectURL(file));
      link.setAttribute("download", `${id}.xlsx`);
      link.click();
    }
  
    catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "cpd_college",
      label: "College"
    },
    {
      id: "cpd_category",
      label: "Category"
    },
    {
      id: "cpd_driveDate",
      label: "Drive Date"
    },
    {
      id: "cpd_startDate",
      label: "Start Date"
    },
    {
      id: "cpd_endDate",
      label: "End Date"
    },
    {
      id: "cpd_assignTo",
      label: "Assign To"
    },
    {
      id: "cpd_status",
      label: "Status"
    },
    {
      id: "action",
      label: "Actions"
    },
  ];

  const createRow = (index: number, cpdDrive: ICpdDrive, pagination: IPagination) => {

    const cpdLink =
      <Box className="center">
        <Tooltip title={`${String(process.env.REACT_APP_CPD_URL)}/?cpdId=${cpdDrive?.cpdId}`}>
          <IconButton className="ml-2" onClick={() => onCopy(`${String(process.env.REACT_APP_CPD_URL)}/?cpdId=${cpdDrive?.cpdId}`)} size="small" disabled={cpdDrive.status !== "CPD SCHEDULED"}>
            <FeedIcon fontSize="small" color={cpdDrive.status !== "CPD SCHEDULED" ? "disabled" : "primary"} />
          </IconButton >
        </Tooltip>
      </Box>;

    const CpdId =
      <Box className="center">
        <Tooltip title={(cpdDrive?.cpdId)}>
          <IconButton className="ml-2" onClick={() => onCopy(cpdDrive?.cpdId)} size="small">
            <ContentCopyIcon fontSize="small" color="primary" />
          </IconButton >
        </Tooltip>
      </Box>;

    const action =
      (<Box sx={{ display: "flex", alignItems: "center" }}>
        {CpdId}
        {cpdLink}
        <GetActions
          icons={
            [
              {
                name: "Export", method: () => onDownloadClick(cpdDrive?.cpdId),
                disabled: ((typeof cpdDrive._recruiter !== "string" && allowPermission(cpdDrive._recruiter?._id)) ?
                  false :
                  true)
              },
              {
                name: "Mail", method: () => actionNavigate(cpdDrive._id, "mail"),
                disabled:
                  ((typeof cpdDrive._recruiter !== "string" && allowPermission(cpdDrive._recruiter?._id)) ?
                    false :
                    true)
              },
              {
                name: "Calendar Invite", method: () => actionNavigate(cpdDrive._id, "calendar-invite"),
                disabled:
                  ((typeof cpdDrive._recruiter !== "string" && allowPermission(cpdDrive._recruiter?._id)) ?
                    false :
                    true)
              },
              {
                name: "Edit",
                method: () => navigate({ pathname: "" + cpdDrive._id, search: searchParams.toString() }),
                disabled:
                  ((typeof cpdDrive._recruiter !== "string" && allowPermission(cpdDrive._recruiter?._id)) ?
                    false :
                    true)
              },
              {
                name: "Delete", method: () => handleDelete(cpdDrive._id),
                disabled: resourceAllocate("college-recruitment.remove") ?
                  ((typeof cpdDrive._recruiter !== "string" && allowPermission(cpdDrive._recruiter?._id)) ?
                    false :
                    true)
                  : true
              },
            ]
          }
        />
      </Box>);

    const cpdCollegeName = (
      <Tooltip title={capitalize(cpdDrive?.collegeId?.name)}>
        <Typography
          onClick={() => navigate({ pathname: "manage/" + cpdDrive._id, search: searchParams.toString() })}
          variant="body2"
          color={["CALL_NA", "CREATED", "INITIATED", "TBC_LATER"].includes(cpdDrive.status)
            ? "error"
            : "primary"
          }
          className="link-none-underline"
        >
          {cpdDrive?.collegeId?.shortName.toUpperCase()}
        </Typography>
      </Tooltip>
    );

    const assignTo =
      <FormControl
        sx={{ width: 150 }}>
        <Select
          size="small"
          disabled={
            ((typeof cpdDrive._recruiter !== "string" && allowPermission(cpdDrive._recruiter?._id)) ?
              false :
              true)
          }
          onChange={e => onUpdate(e, cpdDrive._id, cpdDrive.collegeId._id)}
          name="_recruiter"
          value={
            (typeof cpdDrive._recruiter !== "string" && userIds.includes(cpdDrive._recruiter?._id))
              ?
              cpdDrive._recruiter?._id
              :
              "none"
          }
        >
          <MenuItem disabled value="none">Select</MenuItem>
          {
            users.map((user, i) => <MenuItem key={i} value={user._id}>{`${capitalize(displayName(user))}`}</MenuItem>)
          }
        </Select>
      </FormControl>;

    const status =
      <FormControl
        sx={{ width: 150 }}
      >
        <Select
          size="small"
          name="status"
          disabled={
            ((typeof cpdDrive._recruiter !== "string" && allowPermission(cpdDrive._recruiter?._id)) ?
              false :
              true)
          }
          onChange={e => onUpdate(e, cpdDrive._id, cpdDrive.collegeId._id)}
          value={
            statusNames.includes(cpdDrive.status) ||
              cpdDrive.status === "CREATED" ||
              cpdDrive.status === "INITIATED" ||
              cpdDrive.status === "CALL_NA" ||
              cpdDrive.status === "NOT_INTERESTED" ||
              cpdDrive.status === "TBC_LATER"
              ?
              cpdDrive.status
              :
              "none"
          }
        >
          <MenuItem disabled value="none">Select</MenuItem>
          <MenuItem value="CREATED">Created</MenuItem>,
          <MenuItem value="INITIATED">Initiated</MenuItem>,
          <MenuItem value="CALL_NA">Called NA</MenuItem>,
          <MenuItem value="NOT_INTERESTED">Not Interested</MenuItem>,
          <MenuItem value="TBC_LATER">TBC Later</MenuItem>,
          {
            statusList
              .filter(status => status.type === "CPD")
              .map((user, i) => <MenuItem key={i} value={user?.name.toUpperCase()}>{capitalize(user?.name)}</MenuItem>)
          }
        </Select>
      </FormControl>;

    return {
      id: createIndex(pagination, index),
      cpd_driveDate: cpdDrive?.driveDate ? formatDate(cpdDrive?.driveDate) : "",
      cpd_startDate: cpdDrive?.startDate ? formatDate(cpdDrive?.startDate) : "",
      cpd_endDate: cpdDrive?.endDate ? formatDate(cpdDrive?.endDate) : "",
      cpd_college: cpdCollegeName,
      cpd_category: capitalize(cpdDrive?.collegeId?.category),
      cpd_assignTo: assignTo,
      cpd_status: status,
      action,
    };
  };
  if (getDriveData.data?.data.length) {
    rows = getDriveData.data?.data.map((cpdDrive, i) => createRow(i, cpdDrive, state.pagination));
  }

  return (
    <>
      {/* Add Data  */}
      <Header
        className='my-2'
        searchPlaceholder="Search by college name"
        onSearch={onSearch}
        btnText="ADD PLACEMENT DRIVE"
        onBtnClick={resourceAllocate("college-recruitment.write") ? () => navigate("new") : undefined}
        onFilter={openFilter}
      onImport={() => navigate("import")}
      >

        <DriveFilters
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          OnClose={closeFilter}
        />
        {
          state.filterCount > 0 &&
          <Chip
            className="ml-2"
            label={`Filter Applied (${state.filterCount})`}
            color="warning"
            onDelete={removeFilters}
          />
        }
      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Placement Drive"
        description="Are you sure you want to delete this drive?"
      />
      <Outlet context={{ ...outlet, reFetch: getDriveData.refetch }} />
    </>
  );
};
export default Drives;
