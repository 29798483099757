import { Box, Checkbox, Typography } from "@mui/material";
import Header from "../../../components/header";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useSearchParams, Outlet } from "react-router-dom";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import useSnackbar from "../../../hooks/useSnackbar";
import {
  ICategoryStatus
} from "../../../interfaces/content/category";
import useResource from "../../../hooks/useResource";
import useDebounce from "../../../hooks/useDebounce";
import { useQuery } from "@tanstack/react-query";
import { IColumn, IErrorResponse, IPagination } from "../../../interfaces";
import {
  createIndex,
} from "../../../utilities/helper";
import { handleSelect, handleSelectAll } from "../common/helper";
import AuthorService from "../../../services/content/author";
import { IAuthor, IAuthorRow } from "../../../interfaces/content/author";

const AuthorList = () => {
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const { getContentAuthors, deleteContentAuthor } = AuthorService();
  const [search, setSearch] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
  const { resourceAllocate } = useResource();
  const [addState, setAddState] = useState(false);
  let rows: IAuthorRow[] = [];
  const [state, setState] = useState<ICategoryStatus>({
    deleteWarning: false,
    selectAll: [],
    _category: "",
    pagination: {
      page: page,
      limit: 20,
      totalPages: 1,
      totalRecords: 0,
    },
    filters: {
      search: "",
    },
    multiDeleteWarning: false
  });

  const categorys = useQuery({
    queryKey: ["author", state.pagination.page, state.filters],
    queryFn: () =>
      getContentAuthors({
        pagination: true,
        page: state.pagination.page,
        limit: state.pagination.limit,
        ...state.filters,
      }),
  });

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);
  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord?.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);
  useEffect(() => {
    if (categorys?.data?.data?.length) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: categorys.data.meta.page,
          totalPages: categorys.data.meta.totalPages,
          totalRecords: categorys.data.meta.totalRecords,
        },
      }));
    }
  }, [categorys?.data]);

  useEffect(() => {
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";
    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        ...prevState.filters,
        search,
      },
    }));
  }, [searchParams]);



  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
    navigate(`?${searchParams.toString()}`);
  };


  const handleDelete = (_category = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _category,
    }));
  };

  const isChecked = (categorys.data?.data?.length && state.selectAll?.length === categorys.data?.data?.length) ? true : false;
  const isIndeterminateChecked = (state.selectAll.length > 0 && state.selectAll.length < Number(categorys.data?.data.length)) ? true : false;
  const columns: IColumn[] = [
    {
      id: "all",
      label: <Checkbox onChange={e => handleSelectAll(e, categorys?.data?.data ? categorys?.data?.data : [], setState)} checked={isChecked} indeterminate={isIndeterminateChecked} />,
      maxWidth: 0
    },
    {
      id: "author_name",
      label: "Name",
      minWidth: 200
    },
    {
      id: "action",
      label: "Actions",
      align: "right"
    },
  ];

  const createRows = (
    index: number,
    category: IAuthor,
    selectedAll: string[],
    pagination: IPagination
  ) => {
    const action = (
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () => {
              setAddState(true);
              navigate({
                pathname: "" + category._id,
                search: searchParams.toString(),
              });
            },
            disabled: resourceAllocate("cms-author.write") ? false : true,
          },
          {
            name: "Delete",
            method: () => handleDelete(category._id),
            disabled: resourceAllocate("cms-author.remove") ? false : true,
          },
        ]}
      />
    );

    return {
      all: <Checkbox onChange={e => handleSelect(e, category._id, state, setState)} checked={selectedAll.includes(category._id)} />,
      id: createIndex(pagination, index),
      author_name: category?.name,
      action,
    };
  };

  if (categorys.data?.data?.length) {
    rows = categorys.data?.data?.map((category: IAuthor, i: number) =>
      createRows(i, category, state.selectAll, state.pagination)
    );
  }

  const onMultiDelete = async () => {
    try {
      const category = await deleteContentAuthor({ ids: state.selectAll });
      snackbar(category.message, "info");
      categorys.refetch();
      setState(prevState => ({
        ...prevState,
        multiDeleteWarning: false,
        selectAll: []
      }));
      navigate(
        {pathname : "/content/author",
        search: searchParams.toString()}
      );
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      handleMultiDelete();
      console.log({ "Error in delete author": error });
    }
  };

  const handleMultiDelete = () => setState(prevState => ({
    ...prevState,
    multiDeleteWarning: !prevState.multiDeleteWarning
  }));

  const onDelete = async () => {
    try {
      const deleted = await deleteContentAuthor({ ids: [state._category] });
      setState(prevState => ({
        ...prevState,
        selectAll : prevState.selectAll.filter((category) => category !== state._category)
      }));
      snackbar(deleted.message, "info");
      handleDelete();
      categorys.refetch();
      navigate(
        {pathname : "/content/author",
        search: searchParams.toString()}
      );
    } catch (error) {
      console.log("Error in Deleting Author", error);
    }
  };

  const openAddBox = () => {
    setAddState(true);
    navigate("/content/author/new");
  };


  return (
    <div>
      {addState && <Outlet context={{ ...Outlet, reFetch: categorys.refetch }} />}
      <Header
        className="my-2"
        searchPlaceholder="Search by name"
        onSearch={onSearch}
        btnText="Add Author"
        onDelete={resourceAllocate("cms-author.remove") ? handleMultiDelete : undefined}
        isDeleteDisable={state.selectAll.length ? false : true}
        onBtnClick={resourceAllocate("cms-author.write") ? () => openAddBox() : undefined}
      />
      <Box display="flex" className="mb-2" justifyContent="flex-end" alignItems="center">
        <Box display="flex" alignItems="center">
          <Typography variant="body1">Total Authors:</Typography>
          <Typography className="ml-3" variant="body1" >{categorys?.data?.meta?.totalRecords}</Typography>
        </Box>
      </Box>
      <Box id="custom-table" marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 285px)"
          errorMessage="Add author to see the data here"
          pagination={{
            page: state.pagination.page,
            totalPages: state.pagination.totalPages,
          }}
          onPageChange={onPageChange}
        />
      </Box>

      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Author"
        description="Are you sure you want to delete this author?"
      />

      {/* Multiple Delete  */}
      <WarningDialog
        isOpen={state.multiDeleteWarning}
        onClose={() => handleMultiDelete()}
        onConfirm={onMultiDelete}
        title="Delete All Authors"
        description={`Are you sure you want to delete ${state.selectAll.length} selected ${state.selectAll.length > 1 ? "authors" : "author"}?`}
      />
    </div>
  );
};
export default AuthorList;
