import "./style.scss";
import { Box, Button, Tooltip } from "@mui/material";
import { FC, MouseEvent, ChangeEvent, ReactNode, LegacyRef } from "react";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { FileDownload } from "@mui/icons-material";

interface props {
  onSearch?: (e: ChangeEvent<HTMLInputElement>) => void;
  searchPlaceholder?: string;
  btnText?: string;
  anotherBtnText?: string;
  inputRef?: LegacyRef<HTMLInputElement>;
  onBtnClick?: (e: MouseEvent<HTMLElement>) => void;
  anotherOnBtnClick?: (e: MouseEvent<HTMLElement>) => void;
  onDelete?: (e: MouseEvent<HTMLElement>) => void;
  onFilter?: (e: MouseEvent<HTMLButtonElement>) => void;
  onImport?: (e: MouseEvent<HTMLButtonElement>) => void;
  onExport?: (e: MouseEvent<HTMLButtonElement>) => void;
  isDeleteDisable?: boolean;
  className?: string;
  children?: ReactNode;
}

const TestHeader: FC<props> = ({
  onSearch,
  searchPlaceholder,
  btnText,
  anotherBtnText,
  inputRef,
  onBtnClick,
  anotherOnBtnClick,
  onDelete,
  onFilter,
  onImport,
  onExport,
  isDeleteDisable,
  className,
  children,
}) => (
  <Box
    id="header"
    justifyContent="space-between"
    className={`center ${className}`}
  >
    <Box className="h-100">
      {onBtnClick && (
        <Button
          color="primary"
          variant="contained"
          className="add-btn"
          startIcon={<AddIcon />}
          onClick={onBtnClick}
        >
          {btnText}
        </Button>
      )}
      {anotherOnBtnClick && (
        <Button
          color="primary"
          variant="contained"
          className="add-btn ml-2"
          startIcon={<AddIcon />}
          onClick={anotherOnBtnClick}
        >
          {anotherBtnText}
        </Button>
      )}
      {onImport && (
        <Tooltip title="Import">
          <Button
            variant="outlined"
            startIcon={<CloudDownloadOutlinedIcon />}
            onClick={onImport}
            sx={{ marginX: "10px" }} 
          >
            Import
          </Button>
        </Tooltip>
      )}
      {onExport && (
        <Tooltip title="Export">
          <Button variant="outlined" className="ml-2 h-100" onClick={onExport}>
            <FileDownload />
          </Button>
        </Tooltip>
      )}
      {onDelete && (
        <Tooltip title="Delete">
          <span>
            <Button
              color="error"
              variant="outlined"
              className="ml-2 h-100"
              disabled={isDeleteDisable}
              onClick={onDelete}
            >
              <DeleteIcon />
            </Button>
          </span>
        </Tooltip>
      )}
      {onFilter && (
        <Tooltip title="Filter">
          <Button
            color="primary"
            variant="outlined"
            className="ml-2 h-100"
            onClick={onFilter}
          >
            <FilterAltIcon />
          </Button>
        </Tooltip>
      )}
      {children}
    </Box>
    {onSearch && (
      <Box className="search-box">
        <SearchIcon className="search-icon" />
        <input
          className="search-input"
          name="search"
          ref={inputRef}
          placeholder={searchPlaceholder}
          onChange={onSearch}
        />
      </Box>
    )}
  </Box>
);

TestHeader.defaultProps = {
  className: "",
  onDelete: undefined,
  isDeleteDisable: true,
};
export default TestHeader;