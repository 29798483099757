import Joi from "joi";
import { required, } from "../../shared";

export const collegeValidation = Joi.object({
    image: Joi.string()
        .required()
        .label("Image")
        .messages({
            "string.empty": required,
        }),
    name: Joi.string()
        .required()
        .label("College Name")
        .messages({
            "string.empty": required,
        }),
    shortName: Joi.string()
        .required()
        .label("College Short Name")
        .messages({
            "string.empty": required,
        }),
    city: Joi.string()
        .required()
        .label("City")
        .messages({
            "string.empty": required,
        }),
    state: Joi.string()
        .required()
        .label("State")
        .messages({
            "string.empty": required,
        }),
    category: Joi.string()
        .required()
        .label("Category")
        .messages({
            "string.empty": required,
        }),
    helpline: Joi.string()
        .min(3)
        .max(14)
        .allow(""),
    mobileNumber: Joi.string()
        .min(8)
        .max(14)
        .allow(""),
    secondaryNumber: Joi.string()
        .min(8).max(14).allow(""),
    emailText: Joi.string()
        .label("Email")
        .allow(""),
    email: Joi.array()
        .items(
            Joi.string()
                .email({ tlds: { allow: false } })
        )
        .allow("")
        .label("Email"),
    status: Joi.string().allow(""),
    internshipStartMonth: Joi.string().allow(""),
    duration: Joi.string().allow(""),
    linkedInPage: Joi.string().allow(""),
    facebookPage: Joi.string().allow(""),
    offerRule: Joi.string().allow(""),
    remark: Joi.string().allow(""),
});

export const collegeMailValidation = Joi.object({
    to: Joi.string()
        .email({ tlds: { allow: false } })
        .label("To")
        .required()
        .messages({
            "string.empty": required,
        }),

    template: Joi.string()
        .label("Email Template")
        .allow(""),

    ccText: Joi.string()
        .label("CC")
        .allow(""),
    bccText: Joi.string()
        .label("CC")
        .allow(""),

    cc: Joi.array()
        .items(
            Joi.string()
                .email({ tlds: { allow: false } })
        )
        .allow("")
        .label("CC"),
    bcc: Joi.array()
        .items(
            Joi.string()
                .email({ tlds: { allow: false } })
        )
        .allow("")
        .label("BCC"),
    subject: Joi.string()
        .required()
        .label("Subject")
        .messages({
            "string.empty": required,
        }),
    content: Joi.string()
        .required()
        .label("Body")
        .messages({
            "string.empty": required,
        }),
});

export const ContactPersonValidation = Joi.object({

    name: Joi.string()
        .required()
        .label("Name")
        .messages({
            "string.empty": required,
        }),
    emailText: Joi.string()
        .when(
            Joi.ref("email"), {
            is: Joi.array().length(0),
            then: Joi.string().required(),
            otherwise: Joi.string().allow(""),
        }
        )
        .label("Email")
        .messages({
            "string.empty": required,
        }),
    email: Joi.array()
        .items(
            Joi.string()
                .email({ tlds: { allow: false } })
        )
        .allow("")
        .label("Email")
        .messages({
            "array.length": "Must be a valid email address or empty",
        }),
    number: Joi.string()
        .required()
        .min(8)
        .label("Mobile Number")
        .messages({
            "string.empty": required,
        }),
    _id: Joi.string()
        .label("_id"),
    designation: Joi.string()
        .required()
        .label("Designation")
        .messages({
            "string.empty": required,
        }),
    notes: Joi.string()
        .allow(""),
});
