import { Box, capitalize, FormControl, MenuItem, Select, Tooltip} from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ChangeEvent } from "react";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import {
  createIndex,
  formatDateTime,
  formatHtmlString,
} from "../../../utilities/helper";
import { IErrorResponse, IPagination } from "../../../interfaces";
import Header from "../../../components/header";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import useDebounce from "../../../hooks/useDebounce";

import CustomTypography from "../../../components/mui/max-length-limit";
import useResource from "../../../hooks/useResource";

import { ITechnicalQuestion, ITechnicalQuestionRow, ITechnicalQuestionState } from "../../../interfaces/configuration/technical-question";
import { TechnicalQuestionService } from "../../../services";

interface outletProps {
  reFetch: () => void;
}
const TechnicalQuestion = () => {
  let rows: ITechnicalQuestionRow[] = [];
  const { snackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getTechnicalQuestions, deleteTechnicalQuestion } = TechnicalQuestionService();
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { resourceAllocate } = useResource();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<ITechnicalQuestionState>({
    deleteWarning: false,
    _question: "",
    selectAll: [],
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    },
    filters: {
      search: "",
    },
  });
  const technicalQuestions = useQuery({
    queryKey: ["allQuestions", state.pagination.page, state.filters],
    queryFn: () =>
      getTechnicalQuestions({
        pagination: true,
        limit: state.pagination.limit,
        page: state.pagination.page,
        search: state.filters.search,
      }),
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    searchRecord.length
      ? setSearchParams((prev) => ({
          ...prev,
          page: 1,
          search: searchRecord,
        }))
      : (searchParams.delete("search"), setSearchParams(searchParams));
  }, [searchRecord]);

  useEffect(() => {
      technicalQuestions.data &&
      technicalQuestions.data.data &&
      technicalQuestions.data.data.length &&
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: technicalQuestions.data.meta.page,
          totalPages: technicalQuestions.data.meta.totalPages,
          totalRecords: technicalQuestions.data.meta.totalRecords,
        },
      }));
  }, [technicalQuestions.data && technicalQuestions.data.data && technicalQuestions.data.meta]);

  useEffect(() => {
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        ...prevState.filters,
        search,
      },
    }));
  }, [searchParams]);


  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const handleDelete = (_test = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _test,
    }));
  };


  const onDelete = async () => {
    try {
      const deleted = await deleteTechnicalQuestion({ ids: [state._question] });
      snackbar(deleted.message, "info");
      handleDelete();
      technicalQuestions.refetch();
      outlet.reFetch && outlet.reFetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "error");
    }
  };

  const columns = [
    {
      id: "id",
      label: "S No.",
    },
    {
      id: "question_title",
      label: "Title",
    },
    {
      id: "question_description",
      label: "Description",
    },
    {
      id: "question_difficulty",
      label: "Difficulty",
    },
    {
      id: "question_weightage",
      label: "Weightage",
    },
    {
      id: "question_languages",
      label: "Languages"
    },
    {
      id: "question_duration",
      label: "Duration(mins)",
    },
    {
      id: "question_status",
      label: "Status",
    },
    {
      id: "question_createdBy",
      label: "Created By",
    },
    {
      id: "question_createdAt",
      label: "Created At",
    },
    {
      id: "action",
      label: "Actions",
    },
  ];
  const createRow = (index: number, question: ITechnicalQuestion, pagination: IPagination) => {
    const action = (
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () =>
              navigate({
                pathname: "" + question._id,
                search: searchParams.toString(),
              }),
            disabled: !resourceAllocate("technical-question.write"),
          },
        ]}
      />
    );


    const status =
      <FormControl
        sx={{ width: 150 }}
      >
        <Select
          size="small"
          name="status"
          value={question.status}
          disabled={true}
        >
          <MenuItem disabled value="none">Select</MenuItem>
          <MenuItem value="CREATED">Created</MenuItem>
          <MenuItem value="ARCHIVED">Archived</MenuItem>
          <MenuItem value="PUBLISHED">Published</MenuItem>
        </Select>
      </FormControl>;

    const languages =
      <Tooltip title={(question.languages || []).map(sub => sub.language).join(", ")}>
        <span>
          {question && question.languages && question.languages.length ? (
            <>
              {question.languages[0].language}{" "}
              {question.languages.length > 1 && (
                <span style={{ color: "blue" }}>+{question.languages.length - 1}</span>
              )}
            </>
          ) : (
            ""
          )}
        </span>
      </Tooltip>;

    return {
      id: createIndex(pagination, index),
      question_title: (
        <CustomTypography
          limit={50}
          label={question.title ?? ""}
        />
      ),
      question_description:
        <Tooltip title={formatHtmlString(question.description) ?? ""}>
          <span>
            {formatHtmlString(question.description) && formatHtmlString(question.description).length > 60
              ? `${formatHtmlString(question.description).slice(0, 60)}...`
              : formatHtmlString(question.description)}
          </span>
        </Tooltip>,
      question_difficulty: question.difficulty,
      question_weightage: question.weightage,
      question_languages: languages,
      question_status: status,
      question_duration: question.duration,
      question_createdBy: capitalize(question.createdBy.name),
      question_createdAt: formatDateTime(question?.createdAt),
      action,
    };
  };

  if (
    technicalQuestions.data &&
    technicalQuestions.data.data &&
    technicalQuestions.data.data.length) {
    rows = ((
      technicalQuestions.data &&
      technicalQuestions.data.data &&
      technicalQuestions.data.data) || []).map(
        (test, i) => createRow(i, test, state.pagination)
      );
  }

  return (
    <>
      <Header
        className="my-2"
        searchPlaceholder="Search by title"
        onSearch={onSearch}
        btnText="ADD NEW QUESTION"
        onBtnClick={
          resourceAllocate("technical-question.write") ? () => navigate({pathname:"/configurations/technical-questions/new", search: "type=basic-detail"}) : undefined
        }
      />
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Technical Question"
        description="Are you sure you want to delete this question?"
      />
      <Outlet context={{ ...outlet, reFetch: technicalQuestions.refetch }} />
    </>
  );
};
export default TechnicalQuestion;