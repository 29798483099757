import { Box, Grid, Typography, capitalize } from "@mui/material";
import Header from "../components/header";
import { useEffect, useState } from "react";
import ReportService from "../../../services/report";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { DonutGraph, BarGraph } from "../../../components/graphs";
import CustomTable from "../../../components/mui/table";
import { ISourcePerformanceRow } from "../../../interfaces";

const sourceColumns = [
  { id: "sourceName", label: "Source Name", minWidth: 100, maxWidth: 100 },
  { id: "candidateSource", label: "Candidate Imported", minWidth: 100, maxWidth: 100 },
  { id: "applied", label: "Applied", minWidth: 100, maxWidth: 100 },
  { id: "interviewScheduled", label: "Interview Scheduled", minWidth: 100, maxWidth: 100 },
  { id: "hired", label: "Hired", minWidth: 100, maxWidth: 100 },
  { id: "rejected", label: "Rejected", minWidth: 100, maxWidth: 100 },
  { id: "rateOfHiring", label: "Rate Of Hiring", minWidth: 100, maxWidth: 100 },
];

/**
  * It map table column keys with table row data 
  * @params are the candidate source performance
  * All the map keys will pass in table rows
  **/
const createSourceData = (sourceName: string, candidateSource: number, applied: number, interviewScheduled: number, hired: number, rejected: number, rateOfHiring: number) => {
  if (rateOfHiring?.toString() === "Infinity") {
    rateOfHiring = 100;
  } else if (rateOfHiring?.toString() === "NaN") {
    rateOfHiring = 0;
  }

  return {
    sourceName,
    candidateSource,
    applied,
    interviewScheduled,
    hired,
    rejected,
    rateOfHiring: rateOfHiring + "%"
  };
};

const Candidates = () => {
  let sourcePerformanceRows: ISourcePerformanceRow[] = [];
  const { candidateReport } = ReportService();
  const [searchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    department: searchParams.get("department") || "all",
    job: searchParams.get("job") || "all",
    typeOfLead: searchParams.get("typeOfLead") || "all",
    time: searchParams.get("time") || "all",
  });
  const getCandidate = useQuery({
    queryKey: ["report-overview", filters],
    queryFn: () => candidateReport({ ...filters })
  });

  useEffect(() => {
    const job = searchParams.get("job") ? String(searchParams.get("job")) : "all";
    const department = searchParams.get("department") ? String(searchParams.get("department")) : "all";
    const typeOfLead = searchParams.get("typeOfLead") ? String(searchParams.get("typeOfLead")).toLowerCase() : "all";
    const time = searchParams.get("time") ? String(searchParams.get("time")) : "all";

    setFilters(prev => ({
      ...prev,
      job,
      department,
      typeOfLead,
      time
    }));
  }, [searchParams]);


  const showData = (label: string) => {
    const multiColor = ["rgba(153, 102, 255, 0.3)", "rgba(54, 162, 235, 0.3)", "rgba(255, 99, 132, 0.3)", "rgba(255, 206, 86, 0.3)", "rgba(75, 192, 192, 0.2)", "rgba(254, 234, 230, 0.9)", "rgba(98, 54, 114, 0.3)", "rgba(54, 114, 61, 0.3)"];
    const data = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: ["rgba(17, 140, 252, 0.3)"],
        },
      ],
    };

    const jobsLabel: string[] = [];
    const jobsDataSet: { label: string, data: number[], backgroundColor: string }[] = [];
    getCandidate.data?.data?.jobPerformance?.forEach((candidate) => {
      jobsLabel.push(candidate._id);
      let isExist = false;
      jobsDataSet.map((data, index) => {
        if (data.label === "Hired") {
          isExist = true;
          jobsDataSet[index]?.data.push(candidate.hired);
        } else if (data.label === "Rejected") {
          isExist = true;
          jobsDataSet[index]?.data.push(candidate.rejected);
        } else if (data.label === "Source") {
          isExist = true;
          jobsDataSet[index]?.data.push(candidate.source);
        } else if (data.label === "Applied") {
          isExist = true;
          jobsDataSet[index]?.data.push(candidate.applied);
        }
      });
      if (!isExist) {
        jobsDataSet.push({
          label: "Hired",
          data: [candidate.hired],
          backgroundColor: "rgba(153, 102, 255, 0.3)"
        });
        jobsDataSet.push({
          label: "Rejected",
          data: [candidate.rejected],
          backgroundColor: "rgba(54, 162, 235, 0.3)"
        });
        jobsDataSet.push({
          label: "Source",
          data: [candidate.source],
          backgroundColor: "rgba(255, 99, 132, 0.3)"
        });
        jobsDataSet.push({
          label: "Applied",
          data: [candidate.applied],
          backgroundColor: "rgba(255, 206, 86, 0.3)"
        });
      }
    });

    const pipelineLabel: string[] = [];
    const pipelineData: number[] = [];
    getCandidate.data?.data?.pipeline?.forEach(candidate => {
      pipelineLabel.push(candidate._id);
      pipelineData.push(candidate.count);
    });

    const sourceLabel: string[] = [];
    const sourceData: number[] = [];
    getCandidate.data?.data?.totalSource?.forEach(candidate => {
      sourceLabel.push(candidate._id);
      sourceData.push(candidate.count);
    });

    const hiredLabel: string[] = [];
    const hiredData: number[] = [];
    getCandidate.data?.data?.hiredSource?.forEach(candidate => {
      hiredLabel.push(candidate._id);
      hiredData.push(candidate.count);
    });

    if (label === "overview") {
      return {
        ...data,
        labels: jobsLabel,
        datasets: jobsDataSet
      };
    } else if (label === "pipeline") {
      return {
        ...data,
        labels: pipelineLabel,
        datasets: [
          {
            label: "Candidates",
            data: pipelineData,
            backgroundColor: ["rgba(17, 140, 252, 0.3)"],
          },
        ],
      };
    } else if (label === "source") {
      return {
        ...data,
        labels: sourceLabel,
        datasets: [
          {
            label: "Candidates",
            data: sourceData,
            backgroundColor: multiColor,
          },
        ],
      };
    } else if (label === "hired") {
      return {
        ...data,
        labels: hiredLabel,
        datasets: [
          {
            label: "Candidates",
            data: hiredData,
            backgroundColor: multiColor,
          },
        ],
      };
    } else {
      return data;
    }
  };

  if (getCandidate.data?.data?.sourcePerformance) {
    sourcePerformanceRows = getCandidate.data?.data?.sourcePerformance?.map(candidate => createSourceData(
      capitalize(candidate._id),
      candidate.source,
      candidate.applied,
      candidate.scheduled,
      candidate.hired,
      candidate.rejected,
      Math.round((candidate.hired / (candidate.source + candidate.applied)) * 100)
    )
    );
  }


  return (
    <>
      {/* Show Data  */}
      <Box marginTop="10px" height="calc(100vh - 145px)">
        <Header />

        <Box marginTop="16px" height="calc(100% - 70px)" overflow="auto">
          {/* show graph  */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <BarGraph
                label="Candidate Overview"
                data={showData("overview")}
                isStacked
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BarGraph
                label="Candidate Pipeline"
                data={showData("pipeline")}
                isStacked
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DonutGraph
                label="Candidate's source of application"
                data={showData("source")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DonutGraph
                label="Hires per Source"
                data={showData("hired")}
              />
            </Grid>
            <Grid item xs={12}>
              <Box className="report-container">
                <Typography className="mb-1" variant="h6">
                  Recruiter Performance
                </Typography>
                <CustomTable
                  columns={sourceColumns}
                  rows={sourcePerformanceRows}
                  width={"calc(100% - 2px)"}
                  height="100%"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

      </Box>
    </>
  );
};
export default Candidates;