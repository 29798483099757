import { ChangeEvent, useEffect, useState } from "react";
import Header from "../../../components/header";
import CustomTable from "../../../components/mui/table";
import { Outlet, useOutletContext, useSearchParams } from "react-router-dom";
import useDebounce from "../../../hooks/useDebounce";
import { useNavigate } from "react-router-dom";
import ProjectsService from "../../../services/content/projects-products";
import { useQuery } from "@tanstack/react-query";
import { IProjectRows, IProjectStateStatus, IProjectData } from "../../../interfaces/content/projects-products";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import { IColumn, IErrorResponse } from "../../../interfaces";
import useSnackbar from "../../../hooks/useSnackbar";
import { Box, Chip, Switch, Typography } from "@mui/material";
import "./style.scss";
import useResource from "../../../hooks/useResource";

interface outletProps {
    refetchProjects: () => void;
}

const ProjectsList = () => {


    const [search, setSearch] = useState("");
    const navigate = useNavigate();
    const { snackbar } = useSnackbar();
    const { resourceAllocate } = useResource();
    const outlet = useOutletContext<outletProps>();
    const [searchParams, setSearchParams] = useSearchParams();
    const { getContentProjects, deleteContentProject, updateContentProjects } = ProjectsService();
    const [state, setState] = useState<IProjectStateStatus>({
        pagination: {
            page: 1,
            limit: 20,
            totalPages: 1,
            totalRecords: 0
        },
        multiDeleteWarning: false,
        selectAll: [],
        searching: {
            search: ""
        },
        deleteWarning: false,
        _deleteId: ""
    });
    const projectsList = useQuery({
        queryKey: ["projects", state.pagination.page, state.searching],
        queryFn: () => getContentProjects({
            pagination: true, page: state.pagination.page, limit: state.pagination.limit, ...state.searching
        }),
    });


    //setting pagination information coming from API(meta) to state
    useEffect(() => {
        if (projectsList?.data?.data?.length) {
            setState(prevState => ({
                ...prevState,
                pagination: {
                    ...prevState.pagination,
                    page: projectsList?.data?.meta?.page,
                    totalPages: projectsList?.data?.meta?.totalPages,
                    totalRecords: projectsList?.data?.meta?.totalRecords
                }
            }));
        }
    }, [projectsList?.data?.meta]);

    // searching Functionality start 
    const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

    const searchRecord = useDebounce(search, 1000);

    useEffect(() => {
        if (searchRecord.length) {
            setSearchParams(prev => ({
                ...prev,
                page: 1,
                search: searchRecord
            }));
        } else {
            searchParams.delete("search");
            setSearchParams(searchParams);
        }

    }, [searchRecord]);

    useEffect(() => {
        const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
        const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
        setState(prev => ({
            ...prev,
            pagination: {
                ...prev.pagination,
                page
            },
            searching: {
                ...prev.searching,
                search: search
            }
        }));

    }, [searchParams]);

    // Searching functionality end 

    const columns: IColumn[] = [
        {
            id: "name",
            label: "Name",

        },
        {
            id: "status",
            label: "Mapping Status",

        },
        {
            id: "isActive",
            label: "Status",
        },
        {
            id: "action",
            label: "Action",
            align: "right"
        }
    ];

    const handleDelete = (_deleteId = "") => {
        setState((prevState) => ({
            ...prevState,
            deleteWarning: !prevState.deleteWarning,
            _deleteId
        }));
    };

    const onStatsusUpdate = async (
        event: ChangeEvent<HTMLInputElement>,
        checked: boolean,
        id: string
      ) => {
        const isActive = checked ? "true" : "false";
        try {
          const response = await updateContentProjects({ _id: id, isActive });
          snackbar(response.message, "info");
          projectsList.refetch();
          outlet?.refetchProjects && outlet.refetchProjects();
        } catch (err) {
          console.log("Error in Category Update", err);
        }
      };

    const createRows = (project: IProjectData) => {
        const action = <GetActions
            icons={[
                {
                    name: "Edit", method: () => {
                        navigate({
                            pathname: "" + project._id,
                            search: searchParams.toString()
                        });
                    },
                    disabled: resourceAllocate("cms-product-title.write") ? false : true

                }
            ]}
        />;

        const status = (
            <Chip
                variant="outlined"
                color={project?.isMapped ? "success" : "error"}
                label={project?.isMapped ? "Mapped" : "Not Mapped"}
                style={{ width: "110px" }}
            />
        );

        const isActive = <Switch
            disabled={resourceAllocate("cms-product-title.write") ? false : true}
            checked={project?.isActive}
            onChange={(event, checked) => onStatsusUpdate(event, checked, project._id)}
        />;
        return {
            name: project?.name,
            status: status,
            isActive,
            action
        };
    };


    let rows: IProjectRows[] = [];
    if (projectsList?.data?.data.length) {
        rows = projectsList?.data?.data.map((project: IProjectData) => createRows(project));
    }

    const openManageComponent = () => {
        navigate("/content/products/new");
    };
    const onDelete = async () => {
        try {
            const response = await deleteContentProject({ ids: [state._deleteId] });
            navigate("/content/products");
            snackbar(response?.message, "info");
            handleDelete();
            projectsList.refetch();
            outlet?.refetchProjects && outlet.refetchProjects();
        } catch (error) {
            const err = error as IErrorResponse;
            console.log("Error in Add Projects", err);
            snackbar(err?.data?.message, "error");
        }
    };
    const handleMultiDelete = () => setState(prevState => ({
        ...prevState,
        multiDeleteWarning: !prevState.multiDeleteWarning
    }));
    const onMultiDelete = async () => {
        try {
            const goal = await deleteContentProject({ ids: state.selectAll });
            snackbar(goal.message, "info");
            projectsList.refetch();
            outlet?.refetchProjects && outlet.refetchProjects();
            setState(prevState => ({
                ...prevState,
                multiDeleteWarning: false,
                selectAll: []
            }));
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            handleMultiDelete();
            console.log({ "Error in delete project": error });
        }
    };
    const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
        searchParams.set("page", page.toString());
        setSearchParams(searchParams);
    };

    return (
        <div>
            <Outlet context={{ ...outlet, reFetch: projectsList.refetch }} />

            <Header
                className="my-2"
                searchPlaceholder="Search by name"
                onSearch={onSearch}
                btnText="Add Product"
                onBtnClick={resourceAllocate("cms-product-title.write") ? () => openManageComponent() : undefined}
            />
            <Box display="flex" className="mb-2" justifyContent="flex-end" alignItems="center">
                <Box display="flex" alignItems="center">
                    <Typography variant="body1">Total Products:</Typography>
                    <Typography className="ml-3" variant="body1" >{projectsList?.data?.meta?.totalRecords}</Typography>
                </Box>
            </Box>
            <CustomTable
                rows={rows}
                columns={columns}
                height="calc(100vh - 285px)"
                pagination={state.pagination}
                onPageChange={onPageChange}
            />

            <WarningDialog
                isOpen={state.deleteWarning}
                onClose={() => handleDelete()}
                onConfirm={onDelete}
                title="Delete Project/Product"
                description="Are you sure you want to delete this project/product?"
            />

            {/* Multiple Delete  */}
            <WarningDialog
                isOpen={state.multiDeleteWarning}
                onClose={() => handleMultiDelete()}
                onConfirm={onMultiDelete}
                title="Delete All Project/Product"
                description={`Are you sure you want to delete ${state.selectAll.length} selected ${state.selectAll.length > 1 ? "projects" : "project"}?`}
            />
        </div>
    );
};

export default ProjectsList;