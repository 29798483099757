import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
    Box, Button, Checkbox, Chip, Divider, Grid, IconButton, List, ListItemButton,
    ListItemText, Menu, Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../../../components/shared/filter";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { useSelector } from "react-redux";
import { IDepartmentFIlter, ITeam } from "../../../interfaces";
import { MenuItem } from "../../../components/shared/filter";
import { capitalize } from "../../../utilities/helper";
interface props {
    anchorEl: null | HTMLElement;
    isOpen: boolean;
    OnClose: () => void;
}

const DepartmentFIlter: FC<props> = ({ anchorEl, isOpen, OnClose }) => {
    const location = useLocation();
    const type = location.pathname.split("/")[2];
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [state, setState] = useState<IDepartmentFIlter>({
        selectedMenu: 0,
        _department: [],
    });
    const teams = useSelector<{ team: { list: ITeam[] } }, ITeam[]>(
        (state) => state.team.list
    );

    useEffect(() => {
        const _department: { key: string, value: string }[] = searchParams.get("_department") ? JSON.parse(String(searchParams.get("_department"))) : [];
        setState(prevState => ({
            ...prevState,
            _department,
        }));
    }, [searchParams]);

    const handleLeftListItem = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setState(prevState => ({
            ...prevState,
            selectedMenu: index
        }));
    };

    const handleRightListItem = (name: "_department", key: string, value: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];

        const isExist = state[name].find(ele => ele.key === key);
        if (isExist) {
            payload = state[name].filter(ele => ele.key !== key);
        } else {
            payload = state[name];
            payload.push({
                key,
                value
            });
        }

        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const deleteChip = (name: "_department", key: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];

        payload = state[name].filter(ele => ele.key !== key);
        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const resetFilter = () => {
        setState({
            selectedMenu: 0,
            _department: [],
        });
    };

    const onApply = () => {
        searchParams.set("_department", JSON.stringify(state._department));

        searchParams.set("page", "1");
        navigate({
            pathname:  `/configurations/${type}`,
            search: searchParams.toString()
        });
        OnClose();
    };

    const onClose = () => {
        const _department: { key: string, value: string }[] = searchParams.get("_department") ? JSON.parse(String(searchParams.get("_department"))) : [];
        setState(prevState => ({
            ...prevState,
            _department,
        }));
        OnClose();
    };

    return <>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={OnClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >
            <Box id="filters-container">
                <Box className="center mb-3" justifyContent="space-between" alignItems="start">
                    <div className="active-filter mb-1">
                        {
                            (state._department.length) ?
                                <>
                                    {state._department.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("_department", ele.key)} label={ele.value} variant="outlined" />)}
                                </>
                                :
                                <Box className="mt-2" display="flex" alignItems="center">
                                    <FilterAltOffIcon />
                                    <Typography className="ml-2">No filters are applied</Typography>
                                </Box>
                        }
                    </div>
                    <IconButton
                        onClick={onClose}
                        style={{ marginRight: "-10px" }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Grid className="filter-box" container>
                    <Grid id="left" item xs={5}>
                        <List component="nav">

                            <MenuItem
                                index={0}
                                label="Department"
                                selectedMenu={state.selectedMenu === 0}
                                onChange={handleLeftListItem}
                                count={state._department}
                            />

                        </List>
                    </Grid>
                    <Divider orientation="vertical" />

                    <Grid id="right" item xs={6}>
                        <List component="nav">
                            {
                                state.selectedMenu === 0 &&
                                teams.map(i => <ListItemButton
                                    key={i._id}
                                    selected={!!state._department.find(ele => ele.key === i._id)}
                                    onClick={() => handleRightListItem("_department", i._id, capitalize(i.name))}
                                >
                                    <ListItemText primary={capitalize(i.name)} />
                                    <Checkbox edge="end" checked={!!state._department.find(ele => ele.key === i._id)} />
                                </ListItemButton>)
                            }
                        </List>

                    </Grid>
                </Grid>

                <Box className="actions-btn" marginTop="8px" textAlign="end">
                    <Button variant="outlined" color="error" onClick={() => resetFilter()}>Clear All</Button>
                    <Button className="ml-2" onClick={onApply}>Apply</Button>
                </Box>
            </Box>
        </Menu>
    </>;
};

export default DepartmentFIlter;