import HttpService from "../http";
import { authentication } from "../endpoints";
import { IAuth } from "../../interfaces/auth";

const AuthService = () => {
    const { httpRequest } = HttpService();

    const login = async (payload: IAuth) => new Promise((resolve, reject) => {
        httpRequest(
            "POST",
            `${authentication}/login`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const generateGoogleToken = async () => new Promise((resolve, reject) => {
        httpRequest(
            "GET",
            `${authentication}/generate-code`,
        )
            .then(resolve)
            .catch(reject);
    });

    const verifyGoogleCredentials = async (payload: { code: string }) => new Promise((resolve, reject) => {
        httpRequest(
            "GET",
            `${authentication}/user/verify-google-credentials`,
            {},
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const forgetPassword = async (payload: IAuth) => new Promise((resolve, reject) => {
        httpRequest(
            "POST",
            `${authentication}/forgot-password`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const resetPassword = async (payload: IAuth, id: string) => new Promise((resolve, reject) => {
        httpRequest(
            "PUT",
            `${authentication}/reset-password/${id}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    return { login, generateGoogleToken, verifyGoogleCredentials, forgetPassword, resetPassword };
};

export default AuthService;
