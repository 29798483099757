import "./style.scss";
import { useEffect, useState, } from "react";
import { useSelector } from "react-redux";
import { IJob, ITeam } from "../../../../interfaces";
import { useSearchParams } from "react-router-dom";
import { Box, FormControl, InputLabel, MenuItem, Select, Typography, SelectChangeEvent } from "@mui/material";

const Header = () => {
  const teams = useSelector<{ team: { list: ITeam[] } }, ITeam[]>(state => state.team.list) || [];
  const jobs = useSelector<{ job: { list: IJob[] } }, IJob[]>(state => state.job.list) || [];
  const [searchParams, setSearchParams] = useSearchParams();

  const [state, setState] = useState({
    department: searchParams.get("department") || "all",
    job: searchParams.get("job") || "all",
    typeOfLead: searchParams.get("typeOfLead") || "all",
    time: searchParams.get("time") || "all",
  });

  useEffect(() => {
    const job = searchParams.get("job") ? String(searchParams.get("job")) : "all";
    const department = searchParams.get("department") ? String(searchParams.get("department")) : "all";
    const typeOfLead = searchParams.get("typeOfLead") ? String(searchParams.get("typeOfLead")) : "all";
    const time = searchParams.get("time") ? String(searchParams.get("time")) : "all";

    setState(prev => ({
      ...prev,
      job,
      department,
      typeOfLead,
      time
    }));
  }, [searchParams]);


  const onChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;

    if (name === "typeOfLead") {
      if (value === "all") {
        setState((prev) => ({
          ...prev,
          [name]: value,
          department: "all",
          job: "all"
        }));
        searchParams.delete(name);
        searchParams.delete("department");
      } else {
        setState((prev) => ({
          ...prev,
          [name]: value,
          job: "all"
        }));
        searchParams.set(name, value.toUpperCase());
      }
      searchParams.delete("job");
    } else if (name === "job") {
      if (value === "all") {
        setState((prev) => ({
          ...prev,
          [name]: value,
          department: "all",
        }));
        searchParams.delete(name);
        searchParams.delete("department");
      } else {
        const job = jobs.find(job => job._id === value);
        console.log({ job });
        setState((prev) => ({
          ...prev,
          [name]: value
        }));
        searchParams.set(name, value);
      }
    } else if (name === "department") {
      if (value === "all") {
        searchParams.delete(name);
      } else {
        searchParams.set(name, value);
      }
      searchParams.delete("job");
      setState((prev) => ({
        ...prev,
        [name]: value
      }));
    } else {
      if (value === "all") {
        searchParams.delete(name);
      } else {
        searchParams.set(name, value);
      }
      setState((prev) => ({
        ...prev,
        [name]: value
      }));
    }

    setSearchParams(searchParams);
  };

  const filteredJob = state.typeOfLead === "all" ? jobs : jobs.filter(job => job.type === state.typeOfLead.toLowerCase());
  return (
    <div className="report-header-container">
      <Box className="center" justifyContent="space-between">
        <Typography variant="h6">All Times</Typography>
        <Box display="flex">

          <FormControl
            fullWidth
            style={{ width: "170px" }}
            variant="outlined"
            className="mr-2"
            size="small"
          >
            <InputLabel >Engagement</InputLabel>
            <Select
              required
              name="typeOfLead"
              label="Engagement"
              onChange={onChange}
              value={state.typeOfLead}
            >
              <MenuItem value="all">All Engagement Type</MenuItem>
              <MenuItem value="INTERN">Intern</MenuItem>
              <MenuItem value="FULL-TIME">Full Time</MenuItem>
              <MenuItem value="CONSULTANT">Consultant</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            style={{ width: "170px" }}
            variant="outlined"
            className="mr-2"
            size="small"
          >
            <InputLabel >Department</InputLabel>
            <Select
              required
              name="department"
              label="Department"
              onChange={onChange}
              value={state.department}
            >
              <MenuItem value="all">All Department</MenuItem>
              {teams.map((department, index) =>
                <MenuItem
                  key={index}
                  value={department.name}
                >
                  {department.name}
                </MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            style={{ width: "170px" }}
            variant="outlined"
            className="mr-2"
            size="small"
          >
            <InputLabel >Job</InputLabel>
            <Select
              required
              name="job"
              label="job"
              onChange={onChange}
              value={state.job}
            >
              <MenuItem value="all">All Jobs</MenuItem>
              {
                filteredJob.map((department, index) =>
                  <MenuItem
                    key={index}
                    value={department._id}
                  >
                    {department.title}
                  </MenuItem>
                )}
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            style={{ width: "170px" }}
            variant="outlined"
            className="mr-2"
            size="small"
          >
            <InputLabel >Time</InputLabel>
            <Select
              required
              name="time"
              label="Time"
              onChange={onChange}
              value={state.time}
            >
              <MenuItem value="all">All Time</MenuItem>
              <MenuItem value="today">Today</MenuItem>
              <MenuItem value="yesterday">Yesterday</MenuItem>
              <MenuItem value="thisWeek">This Week</MenuItem>
              <MenuItem value="thisMonth">This Month</MenuItem>
              <MenuItem value="lastWeek">Last Week</MenuItem>
              <MenuItem value="lastMonth">Last Month</MenuItem>
              <MenuItem value="thisQuarter">This Quater</MenuItem>
            </Select>
          </FormControl>

        </Box>
      </Box>
    </div>
  );
};

export default Header;