import "../style.scss";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import { IVendorEdit, IVendorEditField } from "../../../../interfaces/vendor";
import { Autocomplete, Box, Button, capitalize, Chip, Divider, Grid, MenuItem, SelectChangeEvent, TextField } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import useSnackbar from "../../../../hooks/useSnackbar";
import { VendorsService } from "../../../../services/vendor";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { IErrorResponse } from "../../../../interfaces/shared";
import CustomLabel from "../../../../components/mui/custom-label";
import Select from "../../../../components/mui/select";
import { VendorCategoryService } from "../../../../services/vendor/vendor-category";
import { VendorServiceService } from "../../../../services/vendor/vendor-service";
import { IVendorService } from "../../../../interfaces/vendor/vendor-service";
import { joiResolver } from "@hookform/resolvers/joi";
import { EditVendorValidation } from "../../../../validations/vendor/edit-vendor";
import MobileNumber from "../../../../components/mui/mobile-number";

interface props {
  vendor: IVendorEdit | undefined;
  isDisable: boolean;
  setIsDisable: (e: boolean) => void;
}

interface outletProps {
    reFetch: () => void
  }
const BasicDetail: FC<props> = ({ vendor, isDisable, setIsDisable}) => {
    const navigate = useNavigate();
    const { snackbar } = useSnackbar();
    const outlet = useOutletContext<outletProps>();
    const { handleSubmit, control, setValue, trigger, getValues, watch, formState: { errors } } = useForm<IVendorEdit>({
        resolver: joiResolver(EditVendorValidation),
        defaultValues: {
            vendorId: "",
            vendorName: "",
            email: "",
            website: "",
            address: "",
            _category: "",
            _primaryServices: [],
              phoneNumber: "",
            _secondaryServices: [],
            paymentFrequency: ""
        }
    });
    const [stateNo, setStateNo] = useState({
        phoneNumber: {
            country: "INDIA",
            dialCode: "+91",
            iso2: "IN"
        },
    });
    const { getVendorPartialCategory } = VendorCategoryService();
    const { getVendorPartialService } = VendorServiceService();
    const { getVendor, updateVendor } = VendorsService();
    
    const vendorData = useQuery({
        queryKey: ["vendor"],
        queryFn: () =>
          getVendor({
            _id: vendor?._id,
          }),
      });
      
    const [ selectedPrimaryServices, setSelectedPrimaryServices ] = useState<string[]>([]);
    const [ selectedSecondaryServices, setSelectedSecondaryServices ] = useState<string[]>([]);

    useEffect(() => {
        if (vendorData?.data?.data) {
            const vendor = vendorData.data.data;
            setValue("vendorId", vendor.vendorId ?? "");
            setValue("vendorName", vendor.vendorName ?? "");
            setValue("email", vendor.email ?? "");
            setValue("website", vendor.website ?? "");
            setValue("address", vendor.address ?? "");
            setValue("_category", vendor._vendorCategory?._id ?? "");
            setValue("_primaryServices", vendor._vendorPrimaryServices.map(service => service._id) ?? []);
            setSelectedPrimaryServices(vendor._vendorPrimaryServices.map(service => service._id) ?? []);
            setValue("_secondaryServices", vendor._vendorSecondaryServices.map(service => service._id) ?? []);
            setSelectedSecondaryServices(vendor._vendorSecondaryServices.map(service => service._id) ?? []);
            setValue("paymentFrequency", vendor.paymentFrequency ?? "");
            setValue("rating", vendor?.rating);
            setValue("phoneNumber", vendor?.mobileNumber?.number);
        }
    }, [vendorData?.data?.data, isDisable]);
    
    
    const categories = useQuery({
      queryKey: ["allCategores"],
      queryFn: () =>
        getVendorPartialCategory({
          type: "SERVICE",
        }),
    });

    const services = useQuery({
        queryFn: () => getVendorPartialService({ _category: watch("_category") }),
        enabled: !!watch("_category"), 
      });
    const servicesData = (services && services.data && services.data.data) || [];
    
      useEffect(() => {
        if (watch("_category")) {
            services.refetch();
        }
      }, [watch("_category")]);

    const onChangeServiceAutoComplete = (
        event: SyntheticEvent<Element, Event>,
        value: IVendorService[],
        field: IVendorEditField
      ) => {
        const serviceDate = getValues(field.name) as string[];
        const isExist = serviceDate && serviceDate.includes(value[0]?._id);
            if (!isExist) {
                if(field.name === "_primaryServices"){
                    setSelectedPrimaryServices(prevServices => [...prevServices, value[0]._id]);
                }else{
                    setSelectedSecondaryServices(prevServices => [...prevServices, value[0]._id]);
                }
              serviceDate && serviceDate.push(value[0]?._id);
              setValue(field.name, serviceDate);
            }
        trigger(field.name);
      };


    const onSubmit = async (data: IVendorEdit) => {
        try {
            const payload = {
                ...data,
                _id: vendor?._id,
                _vendorCategory: data._category,
                _vendorPrimaryServices: data._primaryServices,
                _vendorSecondaryServices: data._secondaryServices,
                mobileNumber: {
                    ...stateNo.phoneNumber,
                    number: data.phoneNumber,
                },
            };
            delete payload.phoneNumber;
            delete payload.vendorId;
            delete payload._primaryServices;
            delete payload._secondaryServices;
            delete payload._category;
            const add = await updateVendor(payload);
            snackbar(add.message, "info");
            navigate("/directory/vendors");
            outlet?.reFetch && outlet.reFetch();
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log(error);
        }
    };

    const getFilteredOptions = (fieldName : string) => {
        if (fieldName === "_secondaryServices") {
          return servicesData.filter(service => !selectedPrimaryServices.includes(service._id)) || [];
        }else{
            return servicesData.filter(service => !selectedSecondaryServices.includes(service._id)) || [];
        }
      };

    const handleInput = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, onChange: (value: string) => void) => {
        const inputValue = e.currentTarget.value;
        const sanitizedValue = inputValue.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
        const valueAsNumber = parseFloat(sanitizedValue);
    
        if (!isNaN(valueAsNumber) && valueAsNumber <= 5) {
            e.currentTarget.value = sanitizedValue;
            onChange(sanitizedValue);
        } else if (sanitizedValue === "" || valueAsNumber <= 5) {
            e.currentTarget.value = sanitizedValue;
            onChange(sanitizedValue);
        } else {
            e.currentTarget.value = sanitizedValue.slice(0, -1);
        }
    };

    const handleDelete = (item: string, fieldName:  "vendorId" | "vendorName" | "email" | "rating" | "address" | "website" | "paymentFrequency" | "_category" | "_primaryServices" | "_secondaryServices" | "phoneNumber") => {
        setValue(
          fieldName,
          (getValues(fieldName) as string[])?.filter((value) => value !== item)
        );
        
        if (fieldName === "_primaryServices") {
          setSelectedPrimaryServices((prevServices) => 
            prevServices.filter((service) => service !== item)
          );
        } else {
          setSelectedSecondaryServices((prevServices) => 
            prevServices.filter((service) => service !== item)
          );
        }
      };

      const [ratingShrink, setRatingShrink] = useState(false);

      const onNumberChange = (e: SelectChangeEvent<string>) => {
        const { name, value } = e.target;
        const data = value.split(":");
    
        setStateNo(prev => ({
            ...prev,
            [name]: {
                country: data[0],
                iso2: data[1],
                dialCode: data[2]
            }
        }));
    };
    

    const fields: IVendorEditField[] = [
        {
            label: "Vendor ID",
            name: "vendorId",
            type: "input",
            required: true,
        },
        {
            label: "Name",
            name: "vendorName",
            type: "input",
            placeholder: "Type vendor name here",
            required: true,
        },
        {
            label: "Email Address",
            name: "email",
            type: "input",
            placeholder: "Type vendor email here"
        },
        {
            label: "Website",
            name: "website",
            type: "input",
            placeholder: "Type vendor website here"
        },
        {
            label: "Address",
            name: "address",
            type: "input",
            placeholder: "Type vendor address here",
        },
        {
            label: "Category",
            name: "_category",
            type: "select",
            required: true,
        },
        {
            label: "Primary Services",
            name: "_primaryServices",
            type: "select",
            required: true,
        },
        {
            label: "Secondary Services",
            name: "_secondaryServices",
            type: "select",
        },
        {
            label: "Payment Frequency",
            name: "paymentFrequency",
            type: "select",
            children: [
              <MenuItem key="ONCE" value="ONCE" >Once</MenuItem>,
              <MenuItem key="DAILY" value="DAILY" >Daily</MenuItem>,
              <MenuItem key="WEEKLY" value="WEEKLY" >Weekly</MenuItem>,
              <MenuItem key="MONTHLY" value="MONTHLY" >Monthly</MenuItem>,
              <MenuItem key="QUARTERLY" value="QUARTERLY" >Quarterly</MenuItem>,
              <MenuItem key="SEMI-ANNUALLY" value="SEMI-ANNUALLY" >Semi-annually</MenuItem>,
              <MenuItem key="ANNUALLY" value="ANNUALLY" >Annually</MenuItem>
            ],
            required: true,
        },
        {
            label: "Rating",
            name: "rating",
            type: "number",
            placeholder: "Type vendor rating here",
            required: !isDisable
        },
        {
            type: "mobile-number",
            name: "phoneNumber",
            label: "Mobile Number",
            placeholder: "Type mobile number here",
            required: true
        },
    ];
    return(
        <Box paddingTop="10px">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={4}>
                    {
                        fields.map(field => {
                            if (field.type === "input") {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <TextField
                                            label={<CustomLabel label={field.label} required={field?.required} />}
                                            className="disable-text"
                                            variant="outlined"
                                            size="small"
                                            placeholder={field.placeholder}
                                            disabled={field.name === "vendorId" ? true : isDisable}
                                            error={!!errors[field.name]}
                                            helperText={errors[field.name]?.message}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                );
                            }
                            else if (field.type === "number") {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <TextField
                                            label={<CustomLabel label={field.label} required={field?.required} />}
                                            className="disable-text"
                                            variant="outlined"
                                            size="small"
                                            error={!!errors[field.name]}
                                            helperText={errors[field.name]?.message}
                                            disabled={isDisable}
                                            {...prop.field}
                                            onKeyDown={(evt) => ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()}
                                            inputProps={{
                                                pattern: "[0-9.]*",
                                                inputMode: "decimal",
                                                onInput: (e) => {
                                                    handleInput(e, prop.field.onChange);
                                                },
                                            }}
                                            onFocus={() => setRatingShrink(true)}
                                            onBlur={() => setRatingShrink(false)}
                                            InputLabelProps={{
                                                shrink: !!vendor?.rating || ratingShrink || !!getValues("rating"),
                                            }}
                                        />}
                                    />
                                </Grid>
                                );
                            }
                            else if (field.type === "mobile-number") {
                                const numberState = "phoneNumber";
                                return <Grid key={field.label} item xs={12} md={6} >
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <MobileNumber 
                                            key={field.label}
                                            className="disable-text"
                                            NumberFieldLabel={<CustomLabel label={field.label} required={field?.required} />}
                                            dialCodeValue={`${stateNo[numberState].country}:${stateNo[numberState].iso2}:${stateNo[numberState].dialCode}`}
                                            dialCodeName={field.name}
                                            onChange={onNumberChange}
                                            disabled={isDisable}
                                            error={!!errors[field.name]}
                                            helperText={errors[field.name]?.message}
                                            other={prop.field}
                                            vendor={true}
                                        />}
                                    />
                                </Grid>;
                            }
                            else if(field.name === "_category"){
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <Controller
                                      control={control}
                                      name={field.name}
                                      render={(prop) => (
                                            <Autocomplete
                                                fullWidth
                                                options={
                                                    categories?.data?.data.map((category) => category) || []
                                                }
                                                getOptionLabel={(option) => capitalize(option.name)}
                                                renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    size="small"
                                                    label={<CustomLabel label={field.label} required={field?.required} />}
                                                    placeholder={field.placeholder}
                                                    error={errors[field.name] && true}
                                                    helperText={errors[field.name]?.message}
                                                />
                                                )}
                                                {...prop.field}
                                                disabled={isDisable}
                                                value={categories?.data?.data?.find(data => data._id === getValues("_category")) || null}
                                                onChange={(e, value) =>
                                                {
                                                    if(value){
                                                        setValue("_category", value._id);
                                                        setValue("_primaryServices", []);
                                                        setValue("_secondaryServices", []);
                                                    }
                                                }
                                                }
                                            />
                                            )}
                                        />
                                    </Grid>
                                );
                            }else if(field.name === "paymentFrequency") {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                  <Select
                                    control={control}
                                    name={field.name}
                                    label={<CustomLabel label={field.label} required={field?.required} />}
                                    error={!!errors[field.name]}
                                    helperText={errors[field.name]?.message}
                                    disabled={isDisable}
                                    className="disable-text"
                                  >
                                    {field.children}
                                  </Select>
                                </Grid>
                                );
                              }else {
                                return(
                                    <Grid key={field.label} item xs={12} md={6}>
                                    <Controller
                                      control={control}
                                      name={field.name}
                                      render={(prop) => (
                                        <>
                                            <Autocomplete
                                                fullWidth
                                                options= {getFilteredOptions(field.name)}
                                                getOptionLabel={(option) => capitalize(option.name)}
                                                renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    size="small"
                                                    label={<CustomLabel label={field.label} required={field?.required} />}
                                                    placeholder={field.placeholder}
                                                    disabled={isDisable}
                                                    error={errors[field.name] && true}
                                                    helperText={errors[field.name]?.message}
                                                />
                                                )}
                                                {...prop.field}
                                                disabled={isDisable}
                                                value={[]}
                                                onChange={(e, value) =>
                                                    onChangeServiceAutoComplete(e, value, field)
                                                }
                                                multiple
                                            />
                                                {((getValues(field.name) as string[]) || []).map(
                                                (item) => (
                                                    <Chip
                                                    key={item}
                                                    label={
                                                        servicesData.find(
                                                          (title) => title._id === item
                                                        )?.name
                                                      }
                                                    style={{margin:"5px"}}
                                                    color="primary"
                                                    onDelete={isDisable ? undefined : () => handleDelete(item, field.name)}
                                                    variant="outlined"
                                                    />
                                                ))}
                                            </>
                                            )}
                                        />
                                    </Grid>
                                );
                            }
                        })
                    }
                </Grid>

                {
                    !isDisable &&
                    <>
                        <Box className="action-box">
                        <Divider sx={{ marginBottom: "20px" }} />
                        <Button variant="outlined" onClick={() => setIsDisable(true)}>Cancel</Button>
                        <Button type="submit">Save</Button>
                        </Box>
                    </>
                }
            </form>
        </Box>
    );
};

export default BasicDetail;