import Joi from "joi";
import { required } from "../../../shared";

export const noteValidation = Joi.object({
    note: Joi.string()
        .required()
        .label("Note")
        .max(200)
        .messages({
            "string.empty": required,
        }),
});
