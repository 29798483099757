import { useEffect, useState } from "react";
import { ChangeEvent, MouseEvent } from "react";
import { OfferedService } from "../../services";
import { useQuery } from "@tanstack/react-query";
import { IOfferedRow, IOfferedState } from "../../interfaces";
import { IPagination, IErrorResponse, ICandidate } from "../../interfaces";
import { useNavigate, useSearchParams, useParams, Outlet } from "react-router-dom";
import { capitalize, createIndex, formatMobileNumber } from "../../utilities/helper";
import { Box, IconButton,FormControl, MenuItem, Select, SelectChangeEvent, Chip, Checkbox, Tooltip, Button } from "@mui/material";
import OfferedFilter from "./filter";
import Header from "../../components/header";
import useDebounce from "../../hooks/useDebounce";
import useSnackbar from "../../hooks/useSnackbar";
import GroupIcon from "@mui/icons-material/Group";
import CustomTable from "../../components/mui/table";
import GetActions from "../../components/get-actions";
import CustomTypography from "../../components/mui/max-length-limit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const Offers = () => {
  let rows: IOfferedRow[] = [];
  const { id } = useParams();
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const { getOffereds, updateOfferedStatusAssignTo } = OfferedService();
  const [search, setSearch] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState<IOfferedState>({
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    selectAll: [],
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filters: {
      assignTo: "all",
      search: "",
      jobId: [],
    },
    filterCount: 0,
  });

  const actionNavigate = (_id: string, type: string) => {
    searchParams.set("type", type);
    navigate({ pathname: "action/" + _id, search: searchParams.toString() });
  };

  const getOffered = useQuery({
    queryKey: ["allOffereds", state.pagination.page, state.filters],
    queryFn: () => getOffereds({
      page: state.pagination.page, limit: state.pagination.limit, sort: false,
    },
      { status: ["ROHIT SELECTED","HR SELECTED", "JOINED", "OFFER DECLINED", "OFFER SENT"], ...state.filters }
    )
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      const prevParams: { [index: string]: string } = {};
      searchParams.forEach((value, key) => {
        prevParams[key] = value;
      });
      setSearchParams(prev => ({
        ...prev,
        ...prevParams,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (getOffered.data?.data.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: getOffered.data.meta.page,
          totalPages: getOffered.data.meta.totalPages,
          totalRecords: getOffered.data.meta.totalRecords
        }
      }));
    }
  }, [getOffered.data?.meta]);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
    const jobId: { key: string, value: string }[] = searchParams.get("jobId") ? JSON.parse(String(searchParams.get("jobId"))) : [];
    const ctc: { isApply: boolean, value: number[] } = searchParams.get("ctc") ? JSON.parse(String(searchParams.get("ctc"))) : { isApply: false };
    const ectc: { isApply: boolean, value: number[] } = searchParams.get("ectc") ? JSON.parse(String(searchParams.get("ectc"))) : { isApply: false };
    const noticePeriod: { isApply: boolean, value: number[] } = searchParams.get("notice-period") ? JSON.parse(String(searchParams.get("notice-period"))) : { isApply: false };
    const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
    filterCount += jobId.length ? 1 : 0;
    filterCount += ctc.isApply ? 1 : 0;
    filterCount += ectc.isApply ? 1 : 0;
    filterCount += noticePeriod.isApply ? 1 : 0;
    filterCount += date.length ? 1 : 0;
    
    let createdAt: {
      startDate: string;
      endDate: string;
    } | undefined = undefined;
    if (date.length) {
      createdAt = {
        startDate: date[0]?.startDate,
        endDate: date[0]?.endDate,
      };
    }
    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search,
        jobId: jobId.map(jobId => jobId.key),
        ctc: ctc?.isApply ? { startAmount: ctc.value[0], endAmount: ctc.value[1] } : undefined,
        ectc: ectc?.isApply ? { startAmount: ectc.value[0], endAmount: ectc.value[1] } : undefined,
        noticePeriod: noticePeriod?.isApply ? { startAmount: noticePeriod.value[0], endAmount: noticePeriod.value[1] } : undefined,
        createdAt,
      },
      filterCount
    }));
  }, [searchParams]);

  const removeFilters = () => {
    searchParams.delete("jobId");
    searchParams.delete("ctc");
    searchParams.delete("ectc");
    searchParams.delete("notice-period");
    searchParams.delete("date");
    setSearchParams(searchParams);
  };

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const handleStatus = async (event: SelectChangeEvent<string>, _lead: string) => {
    const { name, value } = event.target;
    console.log({ name, value });
    try {
      const payload = {
        _lead,
        [name]: value,

      };
      const candidate = await updateOfferedStatusAssignTo(id ? id : "", payload);
      snackbar(candidate.message, "info");
      getOffered.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log({ "Error in update job status": error });
    }
  };

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const handleSelect = (
    e: ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    let payload: Array<string> = [];
    if (e.target.checked) {
      payload = state.selectAll;
      payload.push(id);
    } else {
      payload = state.selectAll.filter((ele) => ele !== id);
    }
    setState(prevState => ({
      ...prevState,
      selectAll: payload
    }));
  };

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    let payload: Array<string> = [];
    if (e.target.checked) {
      if (getOffered.data?.data.length) {
        payload = getOffered.data?.data.map(getOffered => getOffered._id);
      }
    } else {
      payload = [];
    }
    setState(prevState => ({
      ...prevState,
      selectAll: payload
    }));
  };

  const handleNewJoinee = () => {
    searchParams.set("ids", JSON.stringify(state.selectAll));
    // setSearchParams(searchParams);
    navigate({ pathname: "new-joinee", search: searchParams.toString() });
  };

  const createColumn = (handleSelectAll: (event: ChangeEvent<HTMLInputElement>) => void, isChecked: boolean, isIndeterminateChecked: boolean) => [
    {
      id: "all",
      label: <Checkbox onChange={handleSelectAll} checked={isChecked} indeterminate={isIndeterminateChecked} />
    },
    {
      id: "id",
      label: "S No."
    },
    {
      id: "offered_name",
      label: "Name"
    },
    {
      id: "offered_email",
      label: "Email",
    },
    {
      id: "offered_phone",
      label: "Phone"
    },
    {
      id: "offered_engagementType",
      label: "Engagement Type"
    },
    {
      id: "offered_jobTitle",
      label: "Designation"
    },
    {
      id: "offered_experience",
      label: "Experience"
    },
    {
      id: "offered_ctc",
      label: "CTC"
    },
    {
      id: "offered_ectc",
      label: "ECTC"
    },
    {
      id: "offered_noticePeriod",
      label: "Notice Period"
    },
    {
      id: "offered_status",
      label: "Status"
    },
    {
      id: "action",
      label: "Actions"
    },
  ];

  const onCopy = (url:string,label:string) => {
    navigator.clipboard.writeText(url);
    snackbar(`${capitalize(label)} ID copied to clipboard`, "info");
  };

  const createRow = (
    index: number,
    candidate: ICandidate,
    pagination: IPagination,
    selectedAll: string[],
    handleSelect: (e: ChangeEvent<HTMLInputElement>, id: string) => void,
    handleStatus: (e: SelectChangeEvent<string>, id: string) => void,) => {

    const action = <GetActions
      icons={[
        { name: "Mail", method: () => actionNavigate(candidate._id, "mail"), disabled: candidate.status === "JOINED" },
        { name: "WhatsApp", method: () => actionNavigate(candidate._id, "whatsApp"), disabled: candidate.status === "JOINED" },
        { name: "Onboard", method: () => actionNavigate(candidate._id, "onboard"), disabled: candidate.status === "JOINED" },
      ]}
    />;

    const status =
      <FormControl
        sx={{ width: 150 }}
      >
        <Select
          size="small"
          name="status"
          onChange={e => handleStatus(e, candidate._id)}
          value={candidate.status}
        >
          <MenuItem value="ROHIT SELECTED">Rohit Selected</MenuItem>
          <MenuItem value="HR SELECTED">HR Selected</MenuItem>
          <MenuItem value="OFFER SENT">Offer Sent</MenuItem>
          <MenuItem value="OFFER DECLINED">Offer Declined</MenuItem>
          <MenuItem value="NOT JOINED">Not Joined</MenuItem>
          <MenuItem value="JOINED">Joined</MenuItem>
        </Select>
      </FormControl>;

    return {
      all: <Checkbox onChange={e => handleSelect(e, candidate._id)} checked={selectedAll.includes(candidate._id)} />,
      id: createIndex(pagination, index),
      offered_name: (
        <CustomTypography
          limit={30}
          label={capitalize(candidate.name)}
          onClick={() => navigate({ pathname: `${candidate.typeOfLead.toLowerCase()}/manage/${candidate._id}`, search: searchParams.toString() })}
          color={["CALL_NA", "APPLIED"].includes(candidate.status) ? "error" : "primary"}
        />
      ),

      
      offered_email: (
        <Box display="flex" alignItems="center">
        <IconButton className="ml-2" onClick={() => onCopy(candidate.email,"email")} size="small">
          <ContentCopyIcon fontSize="small" />
        </IconButton >
        <CustomTypography
          limit={20}
          label={candidate.email}
        />
        </Box>
      ),
      offered_phone: formatMobileNumber(candidate?.mobileNumber),
      offered_engagementType: capitalize(candidate?.typeOfLead || ""),
      offered_jobTitle: capitalize(candidate?.jobId?.title || ""),
      offered_experience: (candidate?.experience || 0),
      offered_ctc: (candidate?.ctc || 0),
      offered_ectc: (candidate?.ectc || 0),
      offered_noticePeriod: (candidate?.noticePeriod || 0),
      offered_status: status,
      action,
    };
  };

  const isChecked = (getOffered.data?.data.length && state.selectAll.length === getOffered.data?.data.length) ? true : false;

  const isIndeterminateChecked = (state.selectAll.length > 0 && state.selectAll.length < Number(getOffered.data?.data.length)) ? true : false;
  
  const columns = createColumn(handleSelectAll, isChecked, isIndeterminateChecked);

  if (getOffered.data?.data.length) {
    rows = getOffered.data?.data.map((candidate, i) => createRow(i, candidate, state.pagination, state.selectAll, handleSelect, handleStatus));
  }

  return (
    <>
      {/* Add Data  */}
      <Header
        searchPlaceholder="Search by name, email and phone"
        onSearch={onSearch}
        onFilter={openFilter}
      >
        <Tooltip title='New Joinees'>
          <span>
            <Button
              variant='outlined'
              className='ml-2 h-100'
              onClick={handleNewJoinee}
              disabled={state.selectAll.length === 0}
            >
              <GroupIcon />
            </Button>
          </span>
        </Tooltip>


        <OfferedFilter
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          OnClose={closeFilter}
        />
        {
          state.filterCount > 0 &&
          <Chip
            className="ml-2"
            label={`Filter Applied (${state.filterCount})`}
            color="warning"
            onDelete={removeFilters}
          />
        }
      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 191.3px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      <Outlet context={{ candidatesRefetch: getOffered.refetch }} />
    </>
  );
};
export default Offers;