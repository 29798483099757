import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { RoleService } from "../../../../services";
import { Box, Checkbox, FormHelperText, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { IErrorResponse, IRole, IRoleField } from "../../../../interfaces";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { roleValidation } from "../../../../validations";
import Select from "../../../../components/mui/select";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
import CustomTable from "../../../../components/mui/table";
import roleResources from "../../../../assets/json/resources.json";
import { capitalize } from "../../../../utilities/helper";
interface outletProps {
    reFetch: () => void
}

interface IResource {
    name: string;
    read?: string;
    module: string;
    write?: string;
    remove?: string;
    edit?: string;
}

interface IState {
    resources: string[];
}

const ManageRole = () => {
    const { id } = useParams();
    const [searchParam] = useSearchParams();
    const { snackbar } = useSnackbar();
    const outlet = useOutletContext<outletProps>();
    const hitQuery = (id === "new" || id === "view") ? false : true;
    const navigate = useNavigate();
    const { getRole, addRole, updateRole } = RoleService();
    const team = useQuery({ queryKey: [hitQuery], queryFn: () => getRole({ _id: id }), enabled: hitQuery });
    const { handleSubmit, control, reset, setValue, formState: { errors } } = useForm<IRole>({
        resolver: joiResolver(roleValidation),
        defaultValues: {
            name: "",
            description: ""
        }
    });
    const [state, setState] = useState<IState>({
        resources: []
    });

    useEffect(() => {
        if (id !== "new") {
            if (team.data?.data) {
                setValue("name", capitalize(team.data.data?.name));
                setValue("description", capitalize(team.data.data?.description));

                setState(prevState => ({
                    ...prevState,
                    resources: team.data.data?.resources
                }));

                setValue("resources", team.data.data?.resources);
            }
        }
    }, [id, team.data]);

    const handleResource = (checked: boolean, resources: Array<string | undefined>) => {
        const filterResources: string[] = [];
        let stateResources: string[] = [];
        if (checked) {
            resources.map(v => v && filterResources.push(v));
            stateResources = [...state.resources, ...filterResources];

            if (resources.includes("lead.write")) {
                if (!stateResources.includes("job.read")) {
                    stateResources.push("job.read");
                }
                if (!stateResources.includes("college-recruitment.read")) {
                    stateResources.push("college-recruitment.read");
                }
                if (!stateResources.includes("college.read")) {
                    stateResources.push("college.read");
                }
                if (!stateResources.includes("user.read")) {
                    stateResources.push("user.read");
                }
            }

            stateResources = [...new Set(stateResources)];
            setState(prevState => ({
                ...prevState,
                resources: stateResources
            }));
        } else {
            resources.map(v => v && filterResources.push(v));
            stateResources = [...state.resources];
            stateResources = stateResources.filter(resource => !filterResources.includes(resource));

            setState(prevState => ({
                ...prevState,
                resources: stateResources
            }));
        }

        setValue("resources", stateResources);
    };

    const onSubmit = async (data: IRole) => {
        let resources: string[] = [...state.resources];

        resources.forEach(resource => {
            const roleResource = roleResources.find(roleResource =>
                (roleResource.read === resource) ||
                (roleResource.write === resource) ||
                (roleResource.edit === resource) ||
                (roleResource.remove === resource)
            );

            if (roleResource) {
                resources.push(roleResource.module);
            }

        });

        const roleModules = roleResources.filter(roleResource =>
            (!resources.includes(roleResource.read || "")) &&
            (!resources.includes(roleResource.write || "")) &&
            (!resources.includes(roleResource.remove || "")) &&
            (!resources.includes(roleResource.edit || "")) &&
            (resources.includes(roleResource.module))
        );



        if (roleModules) {
            roleModules.forEach(roleResource => {
                resources.splice(resources.indexOf(roleResource.module), 1);
            });
        }


        resources = [...new Set(resources)];
        const payload = { ...data, resources };
        console.log(payload);


        try {
            if (id === "new") {
                const add = await addRole(payload);
                snackbar(add.message, "info");
                navigate({
                    pathname: "/configurations/roles",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
            }
            else {
                const update = await updateRole({ ...payload, _id: id });
                snackbar(update.message, "info");
                navigate({
                    pathname: "/configurations/roles",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
            }
            reset();
            setState(prev => ({ ...prev, resources: [] }));
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log(error);
        }

    };

    const onClose = () => {
        navigate({
            pathname: "/configurations/roles",
            search: searchParam.toString()
        });
    };

    const fields: IRoleField[] = [
        {
            label: "Name*",
            name: "name",
            type: "input",
        },
        {
            label: "Description*",
            name: "description",
            type: "input",
        },
    ];

    const columns = [
        {
            id: "title",
            label: "Title"
        },
        {
            id: "all",
            label: "All"
        },
        {
            id: "read",
            label: "Read"
        },
        {
            id: "write",
            label: "Write"
        },
        {
            id: "edit",
            label: "Edit"
        },
        {
            id: "remove",
            label: "Delete"
        },
    ];

    const createRow = (resource: IResource) => ({
        title: resource.name,
        all: <Checkbox
            onChange={(e, c) => handleResource(c, [resource.read || undefined, resource?.write || undefined, resource?.edit || undefined, resource?.remove || undefined])}
            checked={state.resources?.includes(resource.read || "") && state.resources?.includes(resource.write || "") &&
             state.resources?.includes(resource.remove || "") && state.resources?.includes(resource.edit || "")} />,
        read: <Checkbox
            checked={state.resources?.includes(resource.read || "") ? true : false}
            disabled={resource.read ? false : true}
            onChange={(e, c) => handleResource(c, [resource.read])} />,
        write: <Checkbox
            checked={state.resources?.includes(resource.write || "") ? true : false}
            disabled={resource.write ? false : true}
            onChange={(e, c) => handleResource(c, [resource?.write || undefined])} />,
        edit: <Checkbox
            checked={state.resources?.includes(resource.edit || "")}
            disabled={!resource.edit}
            onChange={(e, c) => handleResource(c, [resource?.edit || undefined])} />,
        remove: <Checkbox
            checked={state.resources?.includes(resource.remove || "") ? true : false}
            disabled={resource.remove ? false : true}
            onChange={(e, c) => handleResource(c, [resource?.remove || undefined])} />,
    });

    const rows = roleResources.map((resource) => createRow(resource));

    return (
        <Box>
            <CustomDialog
                size='md'
                title={id !== "new" ? "Edit Role" : "Add Role"}
                isOpen={id ? true : false}
                onClose={onClose}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container spacing={4}>
                    {
                        fields.map(field => {
                            if (field.type === "input") {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <TextField
                                            label={field.label}
                                            className="disable-text"
                                            variant="outlined"
                                            size="small"
                                            error={errors[field.name] ? true : false}
                                            helperText={errors[field.name]?.message}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                );
                            } else {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <Select
                                        control={control}
                                        name={field.name}
                                        label={field.label}
                                        size="small"
                                        variant="outlined"
                                        error={errors[field.name] ? true : false}
                                        helperText={errors[field.name]?.message}
                                    >
                                        {field.children}
                                    </Select>
                                </Grid>
                                );
                            }
                        })
                    }
                </Grid>

                <Box marginTop="20px">
                    <CustomTable
                        columns={columns}
                        rows={rows}
                        height="calc(100vh - 400px)"
                    />

                    {errors?.resources && <FormHelperText error={errors?.resources ? true : false}>{errors?.resources?.message}</FormHelperText>}
                </Box>

            </CustomDialog>
        </Box>
    );
};

export default ManageRole;