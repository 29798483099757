import HttpService from "../../http";
import { IUsersResponse, IUserResponse, IUserSampleResponse } from "../../../interfaces";
import { user } from "../../endpoints";

const UsersService = () => {
    const { httpRequest } = HttpService();
    const addUser = async (payload: object): Promise<IUsersResponse> => new Promise((resolve, reject) => {
        httpRequest<IUsersResponse>(
            "POST",
            `${user}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });
    const addUsers = async (payload: object): Promise<IUsersResponse> => new Promise((resolve, reject) => {
        httpRequest<IUsersResponse>(
            "POST",
            `${user}/many`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getPartialUsers = async (): Promise<IUsersResponse> => new Promise((resolve, reject) => {
        httpRequest<IUsersResponse>(
            "GET",
            `${user}/list/partial`,
            {},
            {}
        )
            .then(resolve)
            .catch(reject);
    });

    const getUsers = async (search: object): Promise<IUsersResponse> => new Promise((resolve, reject) => {
        httpRequest<IUsersResponse>(
            "GET",
            `${user}/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getUser = async (query: object): Promise<IUserResponse> => new Promise((resolve, reject) => {
        httpRequest<IUserResponse>(
            "GET",
            user,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const updateUser = async (payload: object): Promise<IUserResponse> => new Promise((resolve, reject) => {
        httpRequest<IUserResponse>(
            "PUT",
            user,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteUser = async (query: object): Promise<IUserResponse> => new Promise((resolve, reject) => {
        httpRequest<IUserResponse>(
            "DELETE",
            `${user}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const myProfile = async (): Promise<IUserResponse> => new Promise((resolve, reject) => {
        httpRequest<IUserResponse>(
            "GET",
            `${user}/my-profile`
        )
            .then(resolve)
            .catch(reject);
    });

    const getRoleBasedUser = async (query: object): Promise<IUsersResponse> => new Promise((resolve, reject) => {
        httpRequest<IUsersResponse>(
            "GET",
            `${user}/role-based-users`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const getUserSample = async (search: object): Promise<IUserSampleResponse> =>
        new Promise((resolve, reject) => {
            httpRequest<IUserSampleResponse>(
                "GET",
                `${user}/sample`,
                {},
                search
            )
                .then(resolve)
                .catch(reject);
        });

    return { addUser, addUsers, getPartialUsers, getUsers, getRoleBasedUser, getUser, updateUser, deleteUser, myProfile , getUserSample};
};

export { UsersService };