import { Box, Grid, TextField, MenuItem, Autocomplete, capitalize } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  ISubCategoryField,
  IAddSubCategory,
} from "../../../../interfaces/content/sub-category";
import { joiResolver } from "@hookform/resolvers/joi";

import CategoryService from "../../../../services/content/category";
import { useQuery } from "@tanstack/react-query";
import SubCategoryService from "../../../../services/content/sub-category";
import useSnackbar from "../../../../hooks/useSnackbar";
import { useEffect } from "react";
import { useSearchParams, useOutletContext } from "react-router-dom";
import { IErrorResponse } from "../../../../interfaces";
import { SubCategoryValidation } from "../../../../validations/content/sub-category";
import InlineDialog from "../../../../components/mui/inlineDialogue";
import CustomLabel from "../../../../components/mui/custom-label";
import { ICategory } from "../../../../interfaces/content/category";
interface outletProps {
  reFetch: () => void
}

const AddSubCategory = () => {
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const { id } = useParams();
  const { getContentCategorys } = CategoryService();
  const { addContentSubCategory, getContentSubCategory, updateContentSubCategory } = SubCategoryService();
  const hitQuery = id === "new" ? false : true;
  const outlet = useOutletContext<outletProps>();
  const [searchParam] = useSearchParams();


  const subCategory = useQuery({
    queryKey: ["hitQuery"],
    queryFn: () => getContentSubCategory({ _id: id }),
    enabled: hitQuery
  });
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<IAddSubCategory>({
    resolver: joiResolver(SubCategoryValidation),
    defaultValues: {
      name: "",
      categoryId: "",
    },
  });

  useEffect(() => {
    if (id !== "new") {
      if (subCategory?.data?.data) {
        setValue("name", subCategory?.data?.data?.name || "");
        setValue("categoryId", subCategory?.data?.data?.categoryId?._id || "");
        trigger("categoryId");
      }
    }
  }, [id, subCategory.data]);

  const handleCategoryChange = async (
    event: React.SyntheticEvent<Element, Event>,
    value: ICategory | null
  ) => {
    if (value) {
      setValue("categoryId", value._id);
      trigger("categoryId");
    }
  };
  const onSubmit = async (data: IAddSubCategory) => {
    try {
      if (id === "new") {
        const payload = { ...data };
        const add = await addContentSubCategory(payload);
        snackbar(add.message, "info");
        navigate("/content/sub-category");
        outlet?.reFetch && outlet?.reFetch();
      } else {
        const payload = { ...data, _id: id };
        const response = await updateContentSubCategory({ ...payload, _id: id });
        snackbar(response.message, "info");
        navigate(
          {
            pathname: "/content/sub-category",
            search: searchParam.toString()
          }
        );
        outlet?.reFetch && outlet?.reFetch();
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      console.log("Error in add or update in sub-category", err);
    }
  };
  const categorys = useQuery({
    queryKey: ["category"],
    queryFn: () =>
      getContentCategorys({
        isActive: true,
      }),
  });
  const categoryData = categorys?.data?.data;
  const fields: ISubCategoryField[] = [
    {
      label: "Category",
      name: "categoryId",
      type: "select",
      placeholder: "Please Select Category",
      children: categoryData?.map((data) => (
        <MenuItem key={data._id} value={data._id}>
          {data.name}
        </MenuItem>
      )),
    },
    {
      label: "Title",
      name: "name",
      type: "input",
      placeholder: "Type Title here",
    },
  ];
  return (
    <Box>
      <InlineDialog
        onClose={() => navigate("/content/sub-category")}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={4}>
          {fields.map((field) => {
            if (field.type === "input") {
              return (
                <Grid key={field.label} item xs={12}>
                  <Controller
                    name={field.name}
                    control={control}
                    render={(prop) => (
                      <TextField
                        label={field.label}
                        className="disable-text"
                        variant="outlined"
                        size="small"
                        inputProps={{
                          maxLength: 30,
                        }}
                        placeholder={field.placeholder}
                        error={errors[field.name] ? true : false}
                        helperText={errors[field.name]?.message}
                        {...prop.field}
                      />
                    )}
                  />
                </Grid>
              );
            } else if (field.type === "select") {
              return (
                <Grid key={field.label} item xs={12}>
                  <Controller
                    control={control}
                    name={field.name}
                    render={(prop) => <Autocomplete
                      className="disable-text"
                      options={categorys?.data?.data?.map(
                        (data) => data
                      ) || []}
                      clearIcon={null}
                      getOptionLabel={(option) => capitalize(option.name)}
                      renderInput={(params) => <TextField
                        {...params}
                        error={errors[field.name] ? true : false}
                        helperText={errors[field.name]?.message}
                        size={"small"}
                        variant={"outlined"}
                        label={<CustomLabel label="Category*" />}
                        placeholder={field.placeholder}
                      />}
                      {...prop.field}
                      value={categorys?.data?.data?.find(category => category._id === getValues(field.name)) || null}
                      onChange={(e, value) => {
                        handleCategoryChange(e, value);
                      }}
                    />}
                  />
                </Grid>
              );
            }
          })}
        </Grid>
      </InlineDialog>
    </Box>
  );
};

export default AddSubCategory;
