import { ISkillCategoriesResponse, ISkillCategoryResponse } from "../../../interfaces/skill-matrix/categories";
import { skillMatrix } from "../../endpoints";
import HttpService from "../../http";

const SkillItemCategoryService = () => {
    const { httpRequest } = HttpService();
    const addSkillItemCategory = async (payload: object): Promise<ISkillCategoriesResponse> => new Promise((resolve, reject) => {
        httpRequest<ISkillCategoriesResponse>(
            "POST",
            `${skillMatrix}/category`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const getSkillItemCategories = async (search: object): Promise<ISkillCategoriesResponse> => new Promise((resolve, reject) => {
        httpRequest<ISkillCategoriesResponse>(
            "GET",
            `${skillMatrix}/category/list`,
            {},
            search
        )
            .then(resolve)
            .catch(reject);
    });

    const getSkillItemCategory = async (query: object): Promise<ISkillCategoryResponse> => new Promise((resolve, reject) => {
        httpRequest<ISkillCategoryResponse>(
            "GET",
            `${skillMatrix}/category`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    const updateSkillItemCategory = async (payload: object): Promise<ISkillCategoryResponse> => new Promise((resolve, reject) => {
        httpRequest<ISkillCategoryResponse>(
            "PUT",
            `${skillMatrix}/category`,
            payload,
        )
            .then(resolve)
            .catch(reject);
    });

    const deleteSkillItemCategory = async (payload: object): Promise<ISkillCategoriesResponse> => new Promise((resolve, reject) => {
        httpRequest<ISkillCategoriesResponse>(
            "DELETE",
            `${skillMatrix}/category`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });
    return { getSkillItemCategory, addSkillItemCategory, getSkillItemCategories, updateSkillItemCategory, deleteSkillItemCategory };
};

export { SkillItemCategoryService };
