import { Box, Chip } from "@mui/material";

import { useEffect, useState, ChangeEvent, MouseEvent } from "react";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useOutletContext,
  useParams,
} from "react-router-dom";
import {
  capitalize,
  createIndex,
  formatDate,
} from "../../../../../../utilities/helper";
import { IPagination } from "../../../../../../interfaces";
import Header from "../../../../../../components/header";
import CustomTable from "../../../../../../components/mui/table";
import GetActions from "../../../../../../components/get-actions";
import useDebounce from "../../../../../../hooks/useDebounce";
import { TestsService } from "../../../../../../services/configuration/tests";
import {
  ITestUserResponse,
  ITestUserRow,
} from "../../../../../../interfaces/configuration/tests";
import { useQuery } from "@tanstack/react-query";
import useResource from "../../../../../../hooks/useResource";
import Filters from "./filter";

interface outletProps {
  refetchTests: () => void;
}

interface IState {
  deleteWarning: boolean;
  _test: string;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
  };
  filterDialog: {
    anchorEl: null | HTMLElement;
    isOpen: boolean
  };
  filters: {
    search: string;
    createdAt?: {
      startDate: string,
      endDate: string
    }
  };
  filterCount?: number
}

const TestResponse = () => {
  let rows: ITestUserRow[] = [];
  const { resourceAllocate } = useResource();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getTestPsyResponse  } = TestsService();
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const [search, setSearch] = useState<string>("");

  const [state, setState] = useState<IState>({
    deleteWarning: false,
    _test: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false
    },
    filters: {
      search: "",
      createdAt: {
        startDate: "",
        endDate: ""
      }
    },
    filterCount: 0
  });

  const { testId } = useParams();

  const tests = useQuery({
    queryKey: ["allTestResponse", state.pagination.page, state.filters],

    queryFn: () =>
      getTestPsyResponse({
        _test: testId,
        pagination: true,
        limit: state.pagination.limit,
        page: state.pagination.page,
        search: state.filters.search,
        startDate: state.filters.createdAt?.startDate,
        endDate: state.filters.createdAt?.endDate,
      }),
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    searchRecord.length
      ? setSearchParams((prev) => ({ ...prev,type: searchParams.get("type"), page: 1, search: searchRecord }))
      : setSearchParams((prev) => {
          prev.delete("search");
          return new URLSearchParams(prev);
        });
  }, [searchRecord]);

  useEffect(() => {
    tests.data &&
      tests.data &&
      tests.data.data.length &&
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: tests.data.meta.page,
          totalPages: tests.data.meta.totalPages,
          totalRecords: tests.data.meta.totalRecords,
        },
      }));
  }, [tests.data && tests.data && tests.data.meta]);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";

      const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
      filterCount += date.length ? 1 : 0;
  
      let createdAt: {
        startDate: string;
        endDate: string;
      } | undefined = undefined;
      if (date?.length) {
        createdAt = {
          startDate: date[0]?.startDate,
          endDate: date[0]?.endDate,
        };
      }
  

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        ...prevState.filters,
        search,
        createdAt
      },
      filterCount
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const removeFilters = () => {
    searchParams.delete("date");
    setSearchParams(searchParams);
  };

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const columns = [
    {
      id: "id",
      label: "S No.",
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "email",
      label: "Email",
    },
    {
      id: "mobileNo",
      label: "Mobile No.",
    },
    {
      id: "marksObtained",
      label: "Marks Obtained",
    },
    {
      id: "submitted",
      label: "Submitted",
    },
    {
      id: "action",
      label: "Actions",
    },
  ];

  const createRow = (
    index: number,
    test: ITestUserResponse,
    pagination: IPagination
  ) => {
    const paramsObj = Object.fromEntries(searchParams.entries());

    const search = new URLSearchParams({ ...paramsObj, subType: "Basic Details" }).toString();
    const action = <GetActions
    icons={[
      {
        name: "View", method: () =>  navigate({
          pathname: `response-details/${test?.sessionId}/${test.leadId}`,
          search: search
        }),
        disabled: !resourceAllocate("test.read")
      },
    ]}
  />;

    return {
      id: createIndex(pagination, index),
      name: test?.name ? capitalize(test?.name) : "",
      email: test?.email ?? "",
      mobileNo: test?.mobileNumber ? `${test.mobileNumber.dialCode} ${test.mobileNumber.number}` : "",
      marksObtained:
        String(test?.obtained ?? 0) +
        "/" +
        String(test?.total ?? 0),
      submitted: test.submitted ? formatDate(test.submitted) : "",
      action,
    };
  };

  if (tests.data && tests.data && tests.data.data.length) {
    rows = ((tests.data && tests.data.data && tests.data.data) || []).map((test, i) =>
      createRow(i, test, state.pagination)
    );
  }
  return (
    <div id="test-manage">
      <Header
        className="my-2"
        searchPlaceholder="Search by name, email or phone number"
        onSearch={onSearch}
        onFilter={openFilter}
        >
          <Filters
            anchorEl={state.filterDialog.anchorEl}
            isOpen={state.filterDialog.isOpen}
            OnClose={closeFilter}
          />
          {
            state.filterCount ?
            <Chip
              className="ml-2"
              label={`Filter Applied (${state.filterCount})`}
              color="warning"
              onDelete={removeFilters}
            /> : 
            null
          }
        </Header>
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 330px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>
      <Outlet context={{ ...outlet }} />
    </div>
  );
};

export default TestResponse;
