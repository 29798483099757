import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Box, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { SkillItemCategoryService } from "../../../services/skill-matrix/skill-items-categories";
import useSnackbar from "../../../hooks/useSnackbar";
import { ISkillItemCategory, ISkillItemCategoryFields } from "../../../interfaces/skill-matrix/add-skill-matrix";
import { skillItemCategoryValidation } from "../../../validations/skill-matrix/skill-item-category";
import { capitalize } from "../../../utilities/helper";
import { IErrorResponse } from "../../../interfaces";
import CustomDialog from "../../../components/mui/dialog";
import CustomLabel from "../../../components/mui/custom-label";
interface outletProps {
    reFetch: () => void;
    refetchSkillItemCategories: () => void;
    reFetchSkillItemData: () => void;
    reFetchUserSkills: () => void;
}

const ManageSkillItemCategory = () => {
    const { id, categoryId } = useParams();
    const [searchParam] = useSearchParams();
    const { snackbar } = useSnackbar();
    const outlet = useOutletContext<outletProps>();
    const hitQuery = !(categoryId === "new");
    const navigate = useNavigate();
    const { getSkillItemCategory, addSkillItemCategory, updateSkillItemCategory } = SkillItemCategoryService();
    const skillItemCategory = useQuery({ queryKey: [hitQuery], queryFn: () => getSkillItemCategory({ _id: categoryId }), enabled: hitQuery });
    const { handleSubmit, control, setValue, formState: { errors } } = useForm<ISkillItemCategory>({
        resolver: joiResolver(skillItemCategoryValidation),
        defaultValues: {
            name: "",
        }
    });

    useEffect(() => {
        if (categoryId !== "new") {
            if (skillItemCategory.data?.data) {
                setValue("name", capitalize(skillItemCategory.data.data.name) || "");
            }
        }
    }, [categoryId, skillItemCategory.data]);

    const onSubmit = async (data: ISkillItemCategory) => {
        try {
            if (categoryId === "new") {
                const add = await addSkillItemCategory({...data, _skillParameter: id});
                snackbar(add.message, "info");
                navigate({
                    pathname: `/skill-matrix/manage/${id}`,
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
                outlet?.reFetchSkillItemData && outlet.reFetchSkillItemData();
                outlet?.reFetchUserSkills && outlet.reFetchUserSkills();
            }
            else {
                const payload = { ...data, _id: categoryId, _skillParameter: id };
                const update = await updateSkillItemCategory(payload);
                snackbar(update.message, "info");
                navigate({
                    pathname: `/skill-matrix/manage/${id}`,
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
                outlet?.reFetchSkillItemData && outlet.reFetchSkillItemData();
                outlet?.reFetchUserSkills && outlet.reFetchUserSkills();
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
        }

    };

    const onClose = () => {
        navigate({
            pathname: `/skill-matrix/manage/${id}`,
            search: searchParam.toString()
        });
    };

    const fields: ISkillItemCategoryFields[] = [
        {
            label: "Name",
            name: "name",
            type: "input",
            placeholder: "Type your category name here",
            required: true
        },
    ];

    return (
        <Box>
            <CustomDialog
                size='sm'
                title={categoryId !== "new" ? "Edit Skill Category" : "Add Skill Category"}
                confirmText={categoryId !== "new" ? "Update" : "Add"}
                isOpen={!!categoryId}
                onClose={onClose}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container spacing={4}>
                    {
                        fields.map(field => {
                            if (field.type === "input") {
                                return (<Grid key={field.label} item xs={12}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <TextField
                                            label={<CustomLabel label={field.label} required={field.required} />}
                                            className="disable-text"
                                            variant="outlined"
                                            size="small"
                                            placeholder={field.placeholder}
                                            error={!!errors[field.name]}
                                            helperText={errors[field.name]?.message}
                                            inputProps={{maxLength: 80}}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                );
                            }
                        })
                    }
                </Grid>
            </CustomDialog>
        </Box>
    );
};

export default ManageSkillItemCategory;