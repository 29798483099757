import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
	ClassicEditor,
    Base64UploadAdapter,
    List,
	ListProperties,
	Alignment,
	Autoformat,
	AutoImage,
	AutoLink,
	Autosave,
	Bold,
	CKBox,
	CloudServices,
	Code,
	CodeBlock,
	Essentials,
	GeneralHtmlSupport,
	Heading,
	HtmlEmbed,
	ImageBlock,
	ImageCaption,
	ImageInline,
	ImageInsert,
	ImageInsertViaUrl,
	ImageResize,
	ImageStyle,
	ImageTextAlternative,
	ImageToolbar,
	ImageUpload,
	Italic,
	Link,
	LinkImage,
	Paragraph,
	PictureEditing,
	SelectAll,
	ShowBlocks,
	SourceEditing,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	TextTransformation,
	Underline,
	Undo
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";
import { IErrorResponse } from "../../../../../interfaces";
import { useForm } from "react-hook-form";

import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";

import useSnackbar from "../../../../../hooks/useSnackbar";
import CustomDialog from "../../../../../components/mui/dialog";
import { Box, Grid } from "@mui/material";

import { blogContentService } from "../../../../../services/blog/content";



interface outletProps {
    reFetchContent: () => void;
    reFetchVersions: () => void

}

export interface IContent {
    content: string | undefined;
}

const ViewVersionContent = () => {
    const { id, contentId } = useParams();

    const [searchParam] = useSearchParams();
    const navigate = useNavigate();
    const { snackbar } = useSnackbar();
    const outlet = useOutletContext<outletProps>();

    const { updateContentVersion, getBlogContent } = blogContentService();
    const { handleSubmit, watch, setValue } = useForm<IContent>({
        defaultValues: {
            content: "",
        }
    });

    const getBlogVersionContent = useQuery({
        queryKey: ["blog-details"],
        queryFn: () => getBlogContent({
            _id: contentId
        })
    });

    useEffect(() => {
        if (getBlogVersionContent.data?.data) {
            setValue("content", getBlogVersionContent.data?.data?.content || "");
        }
    }, [getBlogVersionContent?.data?.data]);

    const onSubmit = async () => {
        try {
            const content = await updateContentVersion({ _id: contentId });
            snackbar(content.message, "info");
            outlet?.reFetchContent && outlet.reFetchContent();
            outlet?.reFetchVersions && outlet.reFetchVersions();
            onClose();
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            outlet?.reFetchContent && outlet.reFetchContent();
            outlet?.reFetchVersions && outlet.reFetchVersions();
            console.log(error);
        }
    };

    const onClose = () => {
        navigate({
            pathname: `/blog/manage/${id}`,
            search: searchParam.toString()
        });
    };


    return (
        <>
            <Box>
                <CustomDialog
                    title={"Blog Content"}
                    isOpen={id ? true : false}
                    onClose={onClose}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={12}>
                            <CKEditor
                                editor={ClassicEditor}
                                data={watch("content")}
                                config={{
                                    toolbar: {
                                        items: [
                                            "undo",
                                            "redo",
                                            "|",
                                            "sourceEditing",
                                            "showBlocks",
                                            "selectAll",
                                            "|",
                                            "heading",
                                            "|",
                                            "bold",
                                            "italic",
                                            "underline",
                                            "code",
                                            "|",
                                            "link",
                                            "insertImage",
                                            "ckbox",
                                            "insertTable",
                                            "codeBlock",
                                            "htmlEmbed",
                                            "|",
                                            "alignment",
                                            "|",
                                            "bulletedList",
                                            "numberedList",
                                        ],
                                        shouldNotGroupWhenFull: false
                                    },
                                    plugins: [
                                        Alignment,
                                        Autoformat,
                                        Base64UploadAdapter,
                                        List,
                                        ListProperties,
                                        AutoImage,
                                        AutoLink,
                                        Autosave,
                                        Bold,
                                        CKBox,
                                        CloudServices,
                                        Code,
                                        CodeBlock,
                                        Essentials,
                                        GeneralHtmlSupport,
                                        Heading,
                                        HtmlEmbed,
                                        ImageBlock,
                                        ImageCaption,
                                        ImageInline,
                                        ImageInsert,
                                        ImageInsertViaUrl,
                                        ImageResize,
                                        ImageStyle,
                                        ImageTextAlternative,
                                        ImageToolbar,
                                        ImageUpload,
                                        Italic,
                                        Link,
                                        LinkImage,
                                        Paragraph,
                                        PictureEditing,
                                        SelectAll,
                                        ShowBlocks,
                                        SourceEditing,
                                        Table,
                                        TableCaption,
                                        TableCellProperties,
                                        TableColumnResize,
                                        TableProperties,
                                        TableToolbar,
                                        TextTransformation,
                                        Underline,
                                        Undo
                                    ],
                                    heading: {
                                        options: [
                                            { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
                                            { model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
                                            { model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
                                            { model: "heading3", view: "h3", title: "Heading 3", class: "ck-heading_heading3" },
                                            { model: "heading4", view: "h4", title: "Heading 4", class: "ck-heading_heading4" },
                                            {
                                                model: "heading5",
                                                view: "h5",
                                                title: "Heading 5",
                                                class: "ck-heading_heading5"
                                            },
                                            {
                                                model: "heading6",
                                                view: "h6",
                                                title: "Heading 6",
                                                class: "ck-heading_heading6"
                                            }
                                        ]
                                    },
                                    htmlSupport: {
                                        allow: [
                                            {
                                                name: /^.*$/,
                                                styles: true,
                                                attributes: true,
                                                classes: true
                                            }
                                        ]
                                    },
                                    image: {
                                        toolbar: [
                                            "toggleImageCaption",
                                            "imageTextAlternative",
                                            "|",
                                            "imageStyle:inline",
                                            "imageStyle:wrapText",
                                            "imageStyle:breakText",
                                            "|",
                                            "resizeImage",
                                            "|",
                                            "ckboxImageEdit"
                                        ]
                                    },
                                    table: {
                                        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableProperties", "tableCellProperties"]
                                    }
                                }}
                                disabled={true}
                            />
                        </Grid>
                    </Grid>

                </CustomDialog>
            </Box>
        </>
    );

};

export default ViewVersionContent;