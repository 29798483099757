import Joi from "joi";
import { required } from "../../shared";

export const reviewParameterValidation = Joi.object({
    name: Joi.string()
        .required()
        .label("Name")
        .messages({
            "string.empty": required
        }),
    jobTitle: Joi.string()
        .required()
        .label("Job Title")
        .messages({
            "string.empty": required
        }),

});
