import Joi from "joi";
import { required, valid_string } from "../../shared";

export const faqValidation = Joi.object({
    title: Joi.string()
        .required()
        .label("Title")
        .messages({
            "string.empty": required
        }),
    type: Joi.string()
        .required()
        .label("Type")
        .messages({
            "any.only": valid_string
        }),
    description: Joi.string()
        .required()
        .label("Description")
        .messages({
            "string.empty": required
        }),
});