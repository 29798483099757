import Joi from "joi";
import { required } from "../../shared";

export const productValidations = Joi.object({
    name:Joi.string()
        .min(3)
        .required()
        .label("Product")
        .messages({
            "string.empty":required,
            "string.min": "{#label} should have at least {#limit} characters",
        }),
    domain:Joi.string()
        .min(3)
        .required()
        .label("Domain")
        .messages({
            "string.empty":required,
            "string.min": "{#label} should have at least {#limit} characters",
        })

});