import { Box, Chip, Tooltip, Typography } from "@mui/material";
import { FC, useState } from "react";
import CustomTable from "../../mui/table";
import { IAssignedTestSessionRow, IColumn, IPagination, ITestSession } from "../../../interfaces";
import { checkTestStatusBackgroundColor, checkTestStatusColor, createIndex } from "../../../utilities/helper";
import GetActions from "../../get-actions";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useResource from "../../../hooks/useResource";
import { IAssignedTestStates } from "../../../interfaces/candidate/manage-candidate/psy-test";
import { useQuery } from "@tanstack/react-query";

import ManageAssessment from "../manage-assessments";
import { TestSessionService } from "../../../services";

interface props {
  currentTab: number;
  activeAction: boolean;
  setActiveAction: (e: boolean) => void;
  candidateDetailRefetch: () => void;
  candidateListRefetch: () => void
}

const AssignedAssessments: FC<props> = ({ currentTab, activeAction, setActiveAction, candidateDetailRefetch, candidateListRefetch }) => {
  const navigate = useNavigate();
  const { leadId } = useParams();
  const { resourceAllocate } = useResource();
  const [searchParams] = useSearchParams();
  const { getAssignedTestSessions } = TestSessionService();
  const [state] = useState<IAssignedTestStates>({
    selectAll: [],
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    }
  });

  const assignedTests = useQuery({
    queryKey: ["assigned-tests-sessions"],
    queryFn: () => getAssignedTestSessions({ _lead: leadId }),
    enabled: !activeAction
  });
  let rows: IAssignedTestSessionRow[] = [];

  const createRow = (index: number, test: ITestSession, pagination: IPagination) => {
    const paramsObj = Object.fromEntries(searchParams.entries());

    const search = new URLSearchParams({ ...paramsObj, subType: "results" }).toString();
    const action = <GetActions
      icons={[
        {
          name: "View", method: () => navigate({ pathname: "assessment-response-details/" + test._id + "/" +test._assessment._id, search: search }),
          disabled: resourceAllocate("test.read") ? false : true
        },
      ]}
    />;
      const testStatus = test.isSubmitted ? "SUBMITTED" : "PENDING";
    return {
      id: createIndex(pagination, index),
      assessment_name: 
        <Tooltip title={test._assessment.title ?? ""}>
          <span>
            {test._assessment.title && test._assessment.title.length > 30
              ? `${test._assessment.title.slice(0, 30)}...`
              : test._assessment.title}
          </span>
        </Tooltip>,
      marks_obtained: `${test.overallScores.obtained ?? "--"}/${test.overallScores.total ?? "--"}`,
      status:
        <Chip variant={"outlined"} sx={{ borderRadius: "4px", width: "110px", backgroundColor: checkTestStatusBackgroundColor(testStatus) }} color={checkTestStatusColor(testStatus)} label={testStatus} />,
      action,
    };
  };
  if (assignedTests?.data?.data?.length) {
    rows = assignedTests.data.data.map((test, i) => createRow(i, test, state.pagination));
  }

  const columns: IColumn[] = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "assessment_name",
      label: "Assessment Name",
      maxWidth: 400,
      whiteSpace: "normal"
    },
    {
      id: "marks_obtained",
      label: "Marks Obtained"
    },
    {
      id: "status",
      label: "Status"
    },
    {
      id: "action",
      label: "Actions"
    },
  ];

  const handleClose = () => {
    setActiveAction(false);
    assignedTests.refetch();
  };

  const isActionActive = currentTab === 7 && activeAction;

  return (
    <Box paddingTop="10px">
      <Box height="56vh" overflow="auto" paddingTop="10px">

        {/* Add Data  */}
        {
          isActionActive &&
          <ManageAssessment
            isOpen={isActionActive}
            onClose={handleClose}
            candidateDetailRefetch = {candidateDetailRefetch}
            candidateListRefetch = {candidateListRefetch}
          />
        }
        <Typography variant="body1" sx={{ fontWeight: "bold" }} className="mb-2 mt-3">
          Assigned Assessments
        </Typography>
        <CustomTable
          columns={columns}
          rows={rows}
          height={"calc(100% - 60px)"}
          width="calc(100% - 2px)"
        />

      </Box>
    </Box>
  );
};

export default AssignedAssessments;