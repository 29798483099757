import { useEffect, useState, MouseEvent } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { DonutGraph } from "../../../components/graphs";
import {
    useQuery
} from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";
import CmsTag from "./component/index";
import "./index.scss";
import CustomTable, { IColumn } from "../../../components/mui/table";
import CmsReportService from "../../../services/cms-report";
import { IBlogGoogleAnalytics, ICmsAssignedBlogRows, ICmsAssignedBlogsReport, ICmsGoogleAnalyticsRows, ICmsKeywordReport, ICmskeywordRows, ICmsPlannedVsActualReport, ICmsPlannedVsActualRows, ICmsTopPerformanceReport, ICmsTopPerformanceRows, ICmsWeeklyPerformanceReport, ICmsWeeklyPerformanceRows } from "../../../interfaces";
import CmsReportFilter from "./filter";
import CustomTypography from "../../../components/mui/max-length-limit";
import { capitalize, increaseDate } from "../../../utilities/helper";
import { FunnelChart } from "react-funnel-pipeline";
import "react-funnel-pipeline/dist/index.css";
import ErrorMessage from "../../../components/shared/error-message";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ProjectsService from "../../../services/content/projects-products";


interface Filters {
    productId: string[];
    createdAt: {
        startDate: string,
        endDate: string
    }
}

interface FilterDialog {
    anchorEl: HTMLButtonElement | null;
    isOpen: boolean;
}

interface IState {
    selectedTag: string;
    analyticsPeriod: string;
    analytics: {
        labels: string[];
        data: number[];
    }
    dateRange: {
        start: Date,
        end: Date
    },
    filters: Filters;
    filterDialog: FilterDialog;
    filterCount: number;
}

function formatDateRange(startDateString: string, endDateString: string) {

    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    const formattedStartDate = formatDateString(startDate);
    const formattedEndDate = formatDateString(endDate);

    return `${formattedStartDate} - ${formattedEndDate}`;
}

// Function to format date string as "mon DD, YYYY"
function formatDateString(date: Date) {
    const monthNames = ["Jan","Feb", "March", "April","May","June","July","Aug","Sept","Oct","Nov","Dec"];
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
}


const CmsAnalytics = () => {
    const navigate = useNavigate();
    const { cmsOverviewStats, cmsFunnelStats, topPerformanceStats, plannedVsActualReport, assignedBlogsStats, keywordStats, categoryStats, weeklyPerformanceStats, blogGoogleAnalytics } = CmsReportService();
    const [searchParams] = useSearchParams();
    const [state, setState] = useState<IState>({
        selectedTag: "Total Jobs",
        analyticsPeriod: "weekly",
        analytics: {
            labels: [],
            data: []
        },
        dateRange: {
            start: new Date(new Date().setHours(new Date().getHours() - 1)),
            end: new Date()
        },
        filters: {
            productId: [],
            createdAt: {
                startDate: "",
                endDate: ""
            }
        },
        filterDialog: {
            anchorEl: null,
            isOpen: false,
        },
        filterCount: 0
    });

    const openFilter = (e: MouseEvent<HTMLButtonElement>) => {
        const anchorElement = e.currentTarget;

        setState((prevState: IState) => ({
            ...prevState,
            filterDialog: {
                ...prevState.filterDialog,
                anchorEl: anchorElement,
                isOpen: !state.filterDialog.isOpen
            }
        }));
    };

    const closeFilter = () => {
        setState((prevState: IState) => ({
            ...prevState,
            filterDialog: {
                ...prevState.filterDialog,
                isOpen: false
            }
        }));
    };

    const getAllCount = useQuery({
        queryKey: ["category-stats", state.filters],
        queryFn: () => categoryStats({ startDate: state.filters?.createdAt?.startDate || "", endDate: state.filters?.createdAt?.endDate || "", productId: state.filters?.productId })
    });

    useEffect(() => {
        const product: { key: string, value: string }[] = searchParams.get("product") ? JSON.parse(String(searchParams.get("product"))) : [];
        const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
        let createdAt: {
            startDate: string;
            endDate: string;
        } = {
            startDate: "",
            endDate: ""
        };
        if (date?.length) {
            createdAt = {
                startDate: date[0] && date[0].startDate,
                endDate: increaseDate(date[0] && date[0].endDate, 1)
            };
        }
        setState((prevState) => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                createdAt,
                productId: product.map(item => item.key)
            },
        }));
    }, [searchParams]);
    let plannedVsActualRows: Array<ICmsPlannedVsActualRows> = [];
    let assignedBlogsRows: Array<ICmsAssignedBlogRows> = [];
    let keywordRows: Array<ICmskeywordRows> = [];
    let topPerformanceRows: Array<ICmsTopPerformanceRows> = [];
    let weeklyPerformanceRows: Array<ICmsWeeklyPerformanceRows> = [];
    let blogGoogleAnalyticsRows: Array<ICmsGoogleAnalyticsRows> = [];
    let totalPublished = 0;
    let totalImpressions = 0;
    let totalClicks = 0;


    const plannedVsActualColumns: IColumn[] = [
        { id: "date", label: "Date", minWidth: 160, maxWidth: 160 },
        { id: "plannedBlogs", label: "Planned Blogs", minWidth: 160, maxWidth: 160 },
        { id: "actualBlogs", label: "Actual Blogs", minWidth: 80, maxWidth: 80 },
    ];

    const createPlannedVsActualRow = (index: number, data: ICmsPlannedVsActualReport) => {
        const title =( <Box display="flex" alignItems="center">
        <CustomTypography
          limit={30}
          label={data?.quarter?.name ? `${data.quarter.name} (${data.quarter._productTitle?.name || ""})` : ""}
          onClick={() => {
            navigate(`/planner/${data?.quarter?._productTitle?._id}/manage/${data?.quarter?._id}`);
          }}
        />
        </Box>
      );
       return {
            date: title,
            plannedBlogs: data?.planned,
            actualBlogs: data?.actual
        };
    };

    const assignedBlogsColumns: IColumn[] = [
        { id: "blogTitle", label: "Blog Title", minWidth: 130, maxWidth: 130 },
        { id: "writer", label: "Writer" },
        { id: "designer", label: "Designer" },
        { id: "reviewer", label: "Reviewer" },
        { id: "publisher", label: "Publisher" },

    ];

    const createAssignedBlogsRow = (index: number, data: ICmsAssignedBlogsReport) => {
       const title =( <Box display="flex" alignItems="center">
        <CustomTypography
          limit={20}
          label={data?._blog?.title}
        />
        </Box>
      );

       return {
            blogTitle: title,
            writer: data?.writer?.userId?.name || "",
            designer: data?.illustrator?.userId?.name || "",
            reviewer: data?.reviewer?.userId?.name || "",
            publisher: data?.publisher?.userId?.name || ""
        };
    };
    const keywordColumns: IColumn[] = [
        { id: "keyword", label: "Keyword", minWidth: 380, maxWidth: 380 },
        { id: "numberOfMentions", label: "Number Of Mentions" },
    ];
    const createKeywordStatsRow = (index: number, data: ICmsKeywordReport) => (
        {
            keyword: data?.keyword,
            numberOfMentions: data.count
        }
    );

    const topPerformanceColumns: IColumn[] = [
        { id: "name", label: "Name", minWidth: 125, maxWidth: 125 },
        { id: "role", label: "Role", minWidth: 125, maxWidth: 125 },
        { id: "assigned", label: "Assigned", minWidth: 125, maxWidth: 125 },
        { id: "submitted", label: "Submitted", minWidth: 80, maxWidth: 80 }
    ];

    const createTopPerformanceRow = (index: number, data: ICmsTopPerformanceReport) => (
        {
            name: capitalize(data?.name),
            role: capitalize(data?.role),
            assigned: data?.assigned,
            submitted: data?.submitted
        }
    );

    const weeklyPerformanceColumns: IColumn[] = [
        { id: "date", label: "Week"},
        { id: "assigned", label: "Assigned"},
        { id: "written", label: "Written" },
        { id: "reviewed", label: "Reviewed" },
        { id: "published", label: "Published" }
    ];
    const blogGoogleAnalyticsColumns: IColumn[] = [
        { id: "writers", label: "Writers"},
        { id: "publishedCount", label: "Published Count"},
        { id: "impressions", label: "Impressions" },
        { id: "clicks", label: "Clicks" }
    ];

    const createWeeklyPerformanceRow = (index: number, data: ICmsWeeklyPerformanceReport) => (
        {
            reviewed: data?.reviewed,
            published: data?.published,
            assigned: data?.assigned,
            written: data?.written,
            date: formatDateRange(
                String(data?.date?.startDate),
                String(data?.date?.endDate)
            )
        }
    );

    const { getContentProjectPartial } = ProjectsService();

    const createGoogleAnalyticsRow = (index: number, data: IBlogGoogleAnalytics) => (
        {
            writers: capitalize(data.name),
            publishedCount: data.publishedCount,
            impressions: data.impressions,
            clicks: data.clicks
        }
    );
    useEffect(() => {
        const setDefaultDates = () => {
            const currentDate = new Date();
            const startDate = new Date();

            switch (state.analyticsPeriod) {
                case "hourly":
                    startDate.setHours(currentDate.getHours() - 1);
                    break;
                case "daily":
                    startDate.setHours(0, 0, 0, 0);
                    break;
                case "weekly":
                    startDate.setDate(currentDate.getDate() - currentDate.getDay());
                    startDate.setHours(0, 0, 0, 0);
                    break;
                case "monthly":
                    startDate.setDate(1);
                    startDate.setHours(0, 0, 0, 0);
                    break;
                default:
                    // Default to weekly
                    startDate.setDate(currentDate.getDate() - currentDate.getDay());
                    startDate.setHours(0, 0, 0, 0);
                    break;
            }

            return { start: startDate, end: currentDate };
        };

        const { start, end } = setDefaultDates();

        setState((prevState) => ({
            ...prevState,
            dateRange: {
                start,
                end,
            },
        }));

    }, [state.analyticsPeriod]);
    const showData = (label: string) => {
        const multiColor = ["rgba(153, 102, 255, 0.3)", "rgba(54, 162, 235, 0.3)", "rgba(255, 99, 132, 0.3)", "rgba(255, 206, 86, 0.3)", "rgba(75, 192, 192, 0.2)", "rgba(254, 234, 230, 0.9)", "rgba(98, 54, 114, 0.3)", "rgba(54, 114, 61, 0.3)"];
        const data = {
            labels: [],
            datasets: [
                {
                    data: [],
                    backgroundColor: multiColor,
                },
            ],
        };

        const categoryLabel: string[] = [];
        const categoryData: number[] = [];
        getAllCount.data?.data?.countWithCategories?.forEach((data) => {
            categoryLabel.push(data.category);
            categoryData.push(data.count);
        });
        if (label === "category") {
            return {
                ...data,
                labels: categoryLabel,
                datasets: [
                    {
                        label: "Count",
                        data: categoryData,
                        backgroundColor: multiColor,
                    },
                ],
            };
        } else {
            return data;
        }

    };

    const getOverviewStats = useQuery(
        {
            queryKey: ["cms-overview-stats", state.filters],
            queryFn: () => cmsOverviewStats({ startDate: state.filters?.createdAt?.startDate || "", endDate: state.filters?.createdAt?.endDate || "", productId: state.filters?.productId }),
        }
    );

    const funnelData = useQuery(
        {
            queryKey: ["cms-funnel-data"],
            queryFn: () => cmsFunnelStats({}),
        }
    );

    const getTopPerformanceStats = useQuery(
        {
            queryKey: ["cms-performance-stats"],
            queryFn: () => topPerformanceStats({}),
        }
    );
    const getplannedVsActualStats = useQuery(
        {
            queryKey: ["cms-planned-vs-actual-stats"],
            queryFn: () => plannedVsActualReport({}),
        }
    );

    const getAssignedBlogsStats = useQuery(
        {
            queryKey: ["cms-assigned-blogs-stats",state.filters],
            queryFn: () => assignedBlogsStats({ startDate: state.filters?.createdAt?.startDate || "", endDate: state.filters?.createdAt?.endDate || "", productId: state.filters?.productId }),
        }
    );

    const project = useQuery({
        queryKey: ["project"],
        queryFn: () => getContentProjectPartial({
          search: "Bot Penguin"
        }),
      });

    const getBlogGoogleAnalytics = useQuery(
        {
            queryKey: ["cms-blogs-google-analytics",state.filters],
            queryFn: () => blogGoogleAnalytics({ 
                startDate: state.filters?.createdAt?.startDate || "", 
                endDate: state.filters?.createdAt?.endDate || "", 
                productId: project.data?.data?._id 
            }),
        }
    );

    const keywordStatistics = useQuery(
        {
            queryKey: ["keyword-stats"],
            queryFn: () => keywordStats({}),
        }
    );

    const getWeeklyPerformanceReport = useQuery({
        queryKey: ["weekly-performance-stats"],
        queryFn: () => weeklyPerformanceStats({}),
    });

    if (getplannedVsActualStats?.data?.data?.length) {
        plannedVsActualRows = getplannedVsActualStats.data?.data.map((data, index) => createPlannedVsActualRow(index, data));
    }

    if (getAssignedBlogsStats?.data?.data?.length) {
        assignedBlogsRows = getAssignedBlogsStats.data?.data.map((data, index) => createAssignedBlogsRow(index, data));
    }

    if (keywordStatistics?.data?.data?.length) {
        keywordRows = keywordStatistics.data?.data.map((data, index) => createKeywordStatsRow(index, data));
    }
    if (getTopPerformanceStats?.data?.data?.length) {
        topPerformanceRows = getTopPerformanceStats.data?.data.map((data, index) => createTopPerformanceRow(index, data));
    }
    if (getWeeklyPerformanceReport?.data?.data?.length) {
        weeklyPerformanceRows = getWeeklyPerformanceReport.data?.data.map((data, index) => createWeeklyPerformanceRow(index, data));
    }
    if (getBlogGoogleAnalytics?.data?.data?.length) {
        blogGoogleAnalyticsRows = getBlogGoogleAnalytics.data?.data.map((data, index) => {
            totalPublished += data.publishedCount || 0;
            totalImpressions += data.impressions || 0;
            totalClicks += data.clicks || 0;
            return createGoogleAnalyticsRow(index, data);
        });
        blogGoogleAnalyticsRows.push({
            writers: "Total",
            publishedCount: totalPublished,
            impressions: totalImpressions,
            clicks: totalClicks,
        });
    }

    return (
        <>

            <Box marginTop="10px" height="calc(100vh - 145px)">
                {/* <Header /> */}
                <Box marginTop="16px" height="calc(100% - 70px)">
                    <Box className="tags" marginBottom="30px">
                        <Box className="tags" marginBottom="30px">
                            <Box className="tags-head">
                                <Typography className="head" variant="body1" marginBottom="10px" style={{ fontWeight: 700 }}>
                                    Overview
                                </Typography>
                                <Box display="flex" alignItems="center">
                                    <button
                                        className="p-2 arrows cursor-pointer"
                                        onClick={(e) => openFilter(e)}
                                        style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "120px" }}
                                    >
                                        <FilterListIcon color="inherit" />
                                        <Typography variant="body1" >
                                            Filters
                                        </Typography>
                                    </button>
                                </Box>
                                <CmsReportFilter
                                    anchorEl={state.filterDialog.anchorEl}
                                    isOpen={state.filterDialog.isOpen}
                                    onClose={closeFilter}
                                />
                            </Box>
                        </Box>
                        <Box className="overview-tabs">
                            <CmsTag
                                name="Draft"
                                count={getOverviewStats.data?.data?.draftBlogs || 0}
                            />
                            <CmsTag
                                name="Published"
                                count={getOverviewStats.data?.data?.completedBlogs || 0}
                            />
                            <CmsTag
                                name="Scheduled"
                                count={getOverviewStats.data?.data?.scheduledBlogs || 0}
                            />
                            <CmsTag
                                name="Under Review"
                                count={getOverviewStats.data?.data?.underReviewBlogs || 0}
                            />
                            <CmsTag
                                name="Total Blogs"
                                count={getOverviewStats.data?.data?.totalBlogs || 0}
                            /> 
                            <CmsTag
                                name="Reassign Blogs"
                                count = {getOverviewStats.data && getOverviewStats.data.data && getOverviewStats.data.data.reassignedBlogs || 0}
                            />
                        </Box>
                    </Box>
                    {/* show graph  */}
                    <Box marginTop="16px">
                        <Grid container spacing={2}>
                            {/*Saved For Future Use*/}
                            {/* <Grid item xs={12} md={6}>
                                <Box className="center" marginBottom="18px" justifyContent="space-between" height="38px">
                                    <Typography className="recent-applications m-0" variant="h6">Top Performance</Typography>
                                </Box>
                                <BarGraph
                                    label="Top Performance"
                                    data={showData("top-performance")}
                                    isStacked
                                />
                            </Grid> */}
                            <Grid item xs={12} md={6}>
                                <Box className="center" marginBottom="18px" justifyContent="space-between" height="38px">
                                    <Typography className="recent-applications m-0" variant="h6">Category</Typography>
                                </Box>
                                <DonutGraph
                                    label=""
                                    data={showData("category")}
                                    height={342}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} >
                                <Box className="center" marginBottom="18px" justifyContent="space-between" height="38px">
                                    <Typography className="recent-applications m-0" variant="h6">Blogs Progress Funnel</Typography>
                                </Box>
                                <Box marginLeft={20} marginTop={6}>
                                    {funnelData?.data?.data ? <FunnelChart 
                                        data={[
                                            { name: "Planned", value: funnelData?.data?.data?.plannedCount ? funnelData?.data?.data?.plannedCount : 0},
                                            { name: "Written", value: funnelData?.data?.data?.writeCount ? funnelData?.data?.data?.writeCount : 0},
                                            { name: "In-review", value: funnelData?.data?.data?.reviewCount ? funnelData?.data?.data?.reviewCount : 0},
                                            { name: "Published", value: funnelData?.data?.data?.publishCount ? funnelData?.data?.data?.publishCount : 0},
                                        ]}
                                        chartHeight={342}
                                        chartWidth={400}
                                        pallette={["#ffcccc", "#e6ccff", "#e0ebeb", "#cce6ff"]}
                                    /> : 
                                    <ErrorMessage Icon={<FilterAltIcon fontSize="large" className="error-icon" />} fullHeight errorMessage="Add some data to see" />}
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box className="center" marginBottom="18px" justifyContent="space-between" height="38px">
                                    <Typography className="recent-applications m-0" variant="h6">Assigned Blogs</Typography>
                                    <div
                                        className="more-details"
                                        onClick={() => navigate("/blog")}
                                    >
                                        View All Blogs
                                    </div>
                                </Box>
                                <CustomTable
                                    columns={assignedBlogsColumns}
                                    rows={assignedBlogsRows}
                                    height={340 + 20}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box className="center" marginBottom="18px" justifyContent="space-between" height="38px">
                                    <Typography className="recent-applications m-0" variant="h6">Planned vs Actual</Typography>
                                </Box>
                                <CustomTable
                                    columns={plannedVsActualColumns}
                                    rows={plannedVsActualRows}
                                    height={340 + 20}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box className="center" marginBottom="18px" justifyContent="space-between" height="38px">
                                    <Typography className="recent-applications m-0" variant="h6">Keywords</Typography>
                                    <div
                                        className="more-details"
                                        onClick={() => navigate("/content/keywords")}
                                    >
                                        View All Keywords
                                    </div>
                                </Box>
                                <CustomTable
                                    columns={keywordColumns}
                                    rows={keywordRows}
                                    height={340 + 20}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box className="center" marginBottom="18px" justifyContent="space-between" height="38px">
                                    <Typography className="recent-applications m-0" variant="h6">Top Performance</Typography>
                                </Box>
                                <CustomTable
                                    columns={topPerformanceColumns}
                                    rows={topPerformanceRows}
                                    height={340 + 20}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box className="center" marginBottom="18px" justifyContent="space-between" height="38px">
                                    <Typography className="recent-applications m-0" variant="h6">Weekly Performance</Typography>
                                </Box>
                                <CustomTable
                                    columns={weeklyPerformanceColumns}
                                    rows={weeklyPerformanceRows}
                                    height={340 + 20}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box className="center" marginBottom="18px" justifyContent="space-between" height="38px">
                                    <Typography className="recent-applications m-0" variant="h6">Blog Google Analytics</Typography>
                                </Box>
                                <CustomTable
                                    columns={blogGoogleAnalyticsColumns}
                                    rows={blogGoogleAnalyticsRows}
                                    height={340 + 20}
                                    showTotal={true}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default CmsAnalytics;