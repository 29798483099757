import "./style.scss";
import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { useNavigate, useOutletContext, useParams, useSearchParams, useLocation, Outlet } from "react-router-dom";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { CandidateService } from "../../services";
import { capitalize } from "../../utilities/helper";
import { BootstrapDialog, BootstrapDialogTitle, TabPanel, a11yProps } from "../shared/mui-tabs";
import EditIcon from "@mui/icons-material/Edit";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import BasicDetail from "./basic-detail";
import InternCareers from "./intern-careers";
import FulltimeCareers from "./fulltime-careers";
import CallStatus from "./call-status";
import Notes from "./notes";
import Interview from "./interview";
import Review from "./review";
import Timeline from "./timeline";
import AddIcon from "@mui/icons-material/Add";
import useResource from "../../hooks/useResource";
import AssignedAssessments from "./assessment";
import ResumeMatch from "./resume-parser";
import { ResumeParserService } from "../../services/ai-resume-parser";

interface SideButtonProps {
  index: number;
  setActiveAction: (e: boolean) => void;
  hasWritePermission: boolean;
}

const SideButton: FC<SideButtonProps> = ({ index, setActiveAction, hasWritePermission }) => {
  let label = "";

  if (index === 2) {
    label = "Add Call Status";
  } else if (index === 3) {
    label = "Add Note";
  } else if (index === 4) {
    label = "Schedule Interview";
  } else if (index === 7) {
    label = "Assign Assessment";
  }
  else {
    return null;
  }

  return (
    <Button
      variant="text"
      size="small"
      startIcon={<AddIcon />}
      onClick={() => setActiveAction(true)}
      disabled={!hasWritePermission}
    >
      {label}
    </Button>
  );
};

const redirectionLinks = ["basic-detail", "careers", "call-status", "notes", "interview", "review", "timeline", "assessments", "resume-parser"];
interface outletProps {
  reFetch: () => void
}

const ManageCandidateLayout = () => {
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { typeOfLead, leadId } = useParams();
  const location = useLocation();
  const { allowPermission } = useResource();
  const [activeAction, setActiveAction] = useState<boolean>(false);
  const [value, setValue] = useState(0);
  const { getCandidate } = CandidateService();
  const [isDisable, setIsDisable] = useState(true);
  const [searchParam, setSearchParam] = useSearchParams();
  const candidate = useQuery({
    queryKey: ["candidate"],
    queryFn: () => getCandidate({ _id: leadId })
  });

  const { getResumeParsedInformation } = ResumeParserService();
  const parsedResume = useQuery({ queryKey: [leadId], queryFn: () => getResumeParsedInformation({ _lead: leadId })});
  const flag = parsedResume?.data?.data && Object.keys(parsedResume.data.data).length;
  useEffect(() => {
    const tab = searchParam.get("type") ? String(searchParam.get("type")) : "basic-detail";
    setValue(redirectionLinks.indexOf(tab));
  }, [searchParam]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    searchParam.set("type", redirectionLinks[newValue]);
    setSearchParam(searchParam);
    setActiveAction(false);
  };

  const onClose = () => {
    searchParam.delete("type");
    if (location.pathname.slice(0, 11) === "/candidates") {
      navigate({ pathname: `/candidates/${typeOfLead}`, search: searchParam.toString() });
    } else if (location.pathname.slice(0, 31) === "/assessments/interview/upcoming") {
      navigate({ pathname: "/assessments/interview/upcoming", search: searchParam.toString() });
    } else if (location.pathname.slice(0, 32) === "/assessments/interview/conducted") {
      navigate({ pathname: "/assessments/interview/conducted", search: searchParam.toString() });
    } else if (location.pathname.slice(0, 27) === "/assessments/review/pending") {
      navigate({ pathname: "/assessments/review/pending", search: searchParam.toString() });
    } else if (location.pathname.slice(0, 29) === "/assessments/review/completed") {
      navigate({ pathname: "/assessments/review/completed", search: searchParam.toString() });
    } else if (location.pathname.slice(0, 8) === "/offered") {
      navigate({ pathname: "/offered", search: searchParam.toString() });
    }
  };

  const name = candidate.data?.data.name ? capitalize(candidate.data?.data.name) : "Candidate Detail";
  const source = candidate.data?.data.source ? candidate.data?.data.source : undefined;
  const createdByUser = candidate.data?.data.createdBy?._user ?? "";
  const permission = allowPermission(createdByUser) ? true : false;

  return <div>
    <BootstrapDialog
      maxWidth="lg"
      onClose={onClose}
      open={typeOfLead ? true : false}
    >
      <BootstrapDialogTitle onClose={onClose}>
        <Box display="flex" alignItems="center">
          {name} {<IconButton sx={{ visibility: (isDisable && value < 2) ? "visible" : "hidden" }} className="ml-2" color="primary" onClick={() => setIsDisable(false)} disabled={!permission} ><EditIcon /></IconButton>}
        </Box>
        <Box className="center" justifyContent="space-between">
          {
            source &&
            <Box display="flex">
              <Typography variant="subtitle1">Source:</Typography>
              <Typography className="ml-2" variant="overline">{source}</Typography>
            </Box>
          }
        </Box>
      </BootstrapDialogTitle>
      <DialogContent dividers sx={{ height: "65vh" }}>
        <Box width="100%" height="100%">
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Box>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab className="tabs-space start-tab-space" label="Basic Detail" {...a11yProps(0)} />
                <Tab className="tabs-space" label="Careers" {...a11yProps(1)} />
                <Tab className="tabs-space" label="Call Status" {...a11yProps(1)} />
                <Tab className="tabs-space" label="Notes" {...a11yProps(1)} />
                <Tab className="tabs-space" label="Interview" {...a11yProps(1)} />
                <Tab className="tabs-space" label="Review" {...a11yProps(1)} />
                <Tab className="tabs-space" label="Timeline" {...a11yProps(1)} />
                <Tab className="tabs-space" label="Assessments" {...a11yProps(1)} />
                {!!flag &&  <Tab className="tabs-space" label="Resume Parser" {...a11yProps(1)} />}
              </Tabs>
            </Box>
            {!activeAction && (
              <SideButton index={value} setActiveAction={setActiveAction} hasWritePermission={permission} />
            )}
          </Box>

          {/* Basic detail  */}
          <TabPanel
            style={{
              height: isDisable ? "calc(100% - 60px)" : "calc(100% - 135px)",
              overflow: "auto",
              marginTop: 10
            }}
            value={value}
            index={0}>
            <BasicDetail
              candidate={candidate.data?.data}
              typeOfLead={typeOfLead}
              isDisable={isDisable}
              setIsDisable={setIsDisable}
              onClose={onClose}
              reFetch={outlet.reFetch}
            />
          </TabPanel>

          {/* Careers detail  */}
          <TabPanel
            style={{
              height: isDisable ? "calc(100% - 60px)" : "calc(100% - 135px)",
              overflow: "auto",
              marginTop: 10
            }}
            value={value}
            index={1}>
            {
              typeOfLead === "intern"
                ?
                <InternCareers
                  candidate={candidate.data?.data}
                  isDisable={isDisable}
                  setIsDisable={setIsDisable}
                  onClose={onClose}
                  reFetch={outlet.reFetch}
                />
                :
                <FulltimeCareers
                  candidate={candidate.data?.data}
                  isDisable={isDisable}
                  setIsDisable={setIsDisable}
                  onClose={onClose}
                  reFetch={outlet.reFetch}
                />
            }
          </TabPanel>

          {/* Call status  */}
          <TabPanel value={value} index={2}>
            <CallStatus
              candidate={candidate.data?.data}
              currentTab={value}
              activeAction={activeAction}
              setActiveAction={setActiveAction}
              candidateDetailRefetch={candidate.refetch}
              candidateListRefetch={outlet.reFetch}
            />
          </TabPanel>

          {/* Notes  */}
          <TabPanel value={value} index={3}>
            <Notes
              candidate={candidate.data?.data}
              currentTab={value}
              activeAction={activeAction}
              setActiveAction={setActiveAction}
              candidateDetailRefetch={candidate.refetch}
            />
          </TabPanel>

          {/* Interview  */}
          <TabPanel value={value} index={4}>
            <Interview
              candidate={candidate.data?.data}
              activeAction={activeAction}
              setActiveAction={setActiveAction}
              currentTab={value}
              candidateDetailRefetch={candidate.refetch}
              candidateListRefetch={outlet.reFetch}
            />
          </TabPanel>

          {/* Review  */}
          <TabPanel value={value} index={5}>
            <Review
              candidate={candidate.data?.data}
              activeAction={activeAction}
              currentTab={value}
              candidateDetailRefetch={candidate.refetch}
              candidateListRefetch={outlet.reFetch}
            />
          </TabPanel>

          {/* TimeLine  */}
          <TabPanel value={value} index={6}>
            <Timeline
              candidate={candidate.data?.data}
            />
          </TabPanel>

          {/* Assessments  */}
          <TabPanel value={value} index={7}>
            <AssignedAssessments
              currentTab={value}
              activeAction={activeAction}
              setActiveAction={setActiveAction}
              candidateDetailRefetch={candidate.refetch}
              candidateListRefetch={outlet.reFetch}
            />
          </TabPanel>

            {/* AI Parser */}
            <TabPanel value={value} index={8}>
            {!!flag && <ResumeMatch parsedData = {parsedResume?.data?.data}  />}
          </TabPanel>
        </Box>
      </DialogContent>
    </BootstrapDialog>

    <Outlet context={{ ...outlet }} />
  </div>;
};

export default ManageCandidateLayout;
