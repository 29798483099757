import "./style.scss";
import { FC, ReactNode } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
    minHeight: "70vh",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(0),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: ReactNode;
  onClose: () => void;
  secondaryText?: string;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, secondaryText, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {secondaryText && (
        <Typography variant="body2" sx={{ color: "#BABABA" }}>
          {secondaryText}
        </Typography>
      )}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

interface Props {
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  title: string;
  isOpen: boolean;
  disabled?: boolean;
  confirmText?: string;
  cancelText?: string;
  onClose: () => void;
  onConfirm?: () => void;
  onSubmit?: () => void;
  children: ReactNode;
  secondaryText?: string;
}

const ResponseDialogTest: FC<Props> = ({
  size,
  title,
  isOpen,
  disabled,
  confirmText,
  cancelText,
  onClose,
  onConfirm,
  onSubmit,
  children,
  secondaryText,
}) => (
  <BootstrapDialog
    maxWidth={size}
    onClose={onClose}
    aria-labelledby="customized-dialog-title"
    open={isOpen}
    scroll="paper"
    PaperProps={{
      style: {
        height: "auto",
        maxHeight: "90vh",
        display: "flex",
        flexDirection: "column",
      },
    }}
  >
    <BootstrapDialogTitle
      id="dialog-title"
      onClose={onClose}
      secondaryText={secondaryText}
    >
      {title}
    </BootstrapDialogTitle>

    <form
      id="form-dialog"
      onSubmit={onSubmit}
      style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
    >
      <DialogContent style={{ flexGrow: 1, overflow: "auto", padding: 0 }}>
        {children}
      </DialogContent>

      {!disabled && (
        <DialogActions
          id="dialog-action"
          style={{
            marginTop: "auto",
            padding: "8px 16px",
          }}
        >
          <Button variant="outlined" size="medium" onClick={onClose}>
            {cancelText ? cancelText : "Discard"}
          </Button>
          <Button
            size="medium"
            type="submit"
            autoFocus
            onClick={() => onConfirm && onConfirm()}
          >
            {confirmText ? confirmText : "Save changes"}
          </Button>
        </DialogActions>
      )}
    </form>
  </BootstrapDialog>
);

ResponseDialogTest.defaultProps = {
  size: "md",
  disabled: false,
};

export default ResponseDialogTest;
