
import { blogKeywordDensity, blogScore, blogScoreList } from "../../endpoints";
import HttpService from "../../http";
import { IBlogKeywordDensityResponses, IBlogScoreResponse, IBlogScoreResponses } from "../../../interfaces/content-marketing/blog/score";
const blogScoreService = () => {

    const { httpRequest } = HttpService();
    const getBlogScoreList = async (search: object): Promise<IBlogScoreResponses> =>
        new Promise((resolve, reject) => {
            httpRequest<IBlogScoreResponses>(
                "GET",
                `${blogScoreList}`,
                {},
                search
            )
                .then(resolve)
                .catch(reject);
        });

        const getBlogKeywordDensity = async (search: object): Promise<IBlogKeywordDensityResponses> =>
        new Promise((resolve, reject) => {
            httpRequest<IBlogKeywordDensityResponses>(
                "GET",
                `${blogKeywordDensity}`,
                {},
                search
            )
                .then(resolve)
                .catch(reject);
        });


    const addBlogScore = async (payload: object): Promise<IBlogScoreResponse> => new Promise((resolve, reject) => {
        httpRequest<IBlogScoreResponse>(
            "PUT",
            `${blogScore}`,
            payload)
            .then(resolve)
            .catch(reject);
    });

    const getBlogScore = async (query: object): Promise<IBlogScoreResponse> => new Promise((resolve, reject) => {
        httpRequest<IBlogScoreResponse>(
            "GET",
            `${blogScore}`,
            {},
            query
        )
            .then(resolve)
            .catch(reject);
    });

    return { addBlogScore,getBlogScoreList,getBlogScore,getBlogKeywordDensity };

};

export { blogScoreService };