import Joi from "joi";
import { required, valid_string } from "../../shared";

export const addTechnicalQuestionBasicDetailValidation = Joi.object({
	title: Joi.string()
		.required()
		.trim()
		.label("Title")
		.messages({
			"string.empty": required
		}),
	description: Joi.string()
		.required()
		.label("Description")
		.messages({
			"string.empty": required
		}),
	difficulty: Joi.string()
		.valid("EASY", "MEDIUM", "HARD")
		.required()
		.label("Difficulty")
		.messages({
			"any.only": valid_string
		}),
	languages: Joi.array()
		.items(Joi.string())
		.min(1)
		.required()
		.label("Languages")
		.messages({
			"string.empty": required,
			"array.min": "Please select at least one {{#label}}"
		}),
	jobs: Joi.array()
		.items(Joi.string())
		.min(1)
		.required()
		.label("Jobs")
		.messages({
			"string.empty": required,
			"array.min": "Please select at least one {{#label}}"
		}),
	tags: Joi.array()
		.items(Joi.string())
		.min(1)
		.required()
		.label("Tags")
		.messages({
			"string.empty": required,
			"array.min": "Please select at least one {{#label}}"
		}),
	weightage: Joi.number()
		.required()
		.label("Weightage")
		.messages({
			"string.empty": required
		}),
	duration: Joi.number()
		.required()
		.label("Duration")
		.messages({
			"string.empty": required
		}),
});

export const addTechnicalQuestionTemplateValidation = Joi.object({
	_language: Joi.string()
		.required()
		.label("Language")
		.messages({
			"string.empty": required,
		}),
	content: Joi.string()
		.allow("")
});

export const addTechnicalQuestionTestCaseValidation = Joi.object({
	type: Joi.string()
		.required()
		.label("Type")
		.messages({
			"string.empty": required,
		}),
	inputType: Joi.string()
		.required()
		.label("Input Type")
		.messages({
			"string.empty": required,
		}),
	outputType: Joi.string()
		.required()
		.label("Output Type")
		.messages({
			"string.empty": required,
		}),
	input: Joi.string()
		.required()
		.label("Input")
		.messages({
			"string.empty": required,
		}),
	output: Joi.string()
		.required()
		.label("Output")
		.messages({
			"string.empty": required,
		}),
});