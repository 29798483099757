import InlineDialog from "../../../../components/mui/inlineDialogue";
import { useNavigate } from "react-router-dom";
import {useForm,Controller} from "react-hook-form";
import { IAddGoal, IGoalsField } from "../../../../interfaces/content/goals";
import { joiResolver } from "@hookform/resolvers/joi";
import { goalValidations } from "../../../../validations/content/goals";
import { Grid, TextField } from "@mui/material";
import goalsService from "../../../../services/content/goals";
import { IErrorResponse } from "../../../../interfaces";
import useSnackbar from "../../../../hooks/useSnackbar";
import { useOutletContext } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

interface IOutletProps{
    reFetch : () =>  void;
}

const AddGoal = () => {
    const navigate = useNavigate();
    const {snackbar} = useSnackbar();
    const {id} = useParams();
    const { addContentGoal , getContentGoal ,updateContentGoal } = goalsService();
    const outlet = useOutletContext<IOutletProps>();
    const hitQuery = id === "new" ? false : true;
    const { handleSubmit, setValue, control, formState: { errors } } = useForm({
        resolver: joiResolver(goalValidations),
        defaultValues: {
            name: ""
        }
    });

    const goalData = useQuery({
        queryKey: ["hitQuery"],
        queryFn: () => getContentGoal({ _id: id }),
        enabled: hitQuery,
    });
    useEffect(() => {
     if(id !== "new"){
        setValue("name",goalData?.data?.data?.name || "");
     }
    },[goalData?.data?.data]);
    const fields:IGoalsField[] = [
        {
            name:"name",
            label:"Name",
            type:"input",
            placeholder:"Enter goals Name",
            width:12
        }
    ];
    const onSubmit = async (data:IAddGoal) => {
        try{
            if(id === "new"){
            const response = await addContentGoal(data);
            snackbar(response.message,"info");
            navigate("/content/goals");
            outlet.reFetch && outlet.reFetch();
            }else{
            const response = await updateContentGoal({_id:id,...data});
            snackbar(response.message,"info");
            navigate("/content/goals");
            outlet.reFetch && outlet.reFetch();
            }
        }catch(error){
            const err = error as IErrorResponse;
            snackbar(err?.data?.message,"error");
            console.log("Error in Adding Goal",error);
        }
    };
    return (
            <InlineDialog
            onClose = {() => navigate("/content/goals")}
            onSubmit= {handleSubmit(onSubmit)}
            >
                <Grid container spacing = {4}>
            {
                fields.map(field => {
                    if(field.type === "input"){
                        return (
                            <Grid key = {field.name} item xs = {field.width ? field.width : 12}>
                                <Controller 
                                name = {field.name}
                                control = {control}
                                render = {(prop) => (
                                    <TextField 
                                    label = {field.label}
                                    placeholder = {field.placeholder}
                                    inputProps={{
                                        maxLength:30
                                    }}
                                    error = {errors[field.name] ? true : false}
                                    helperText = {errors[field.name]?.message}
                                    {...prop.field}
                                    
                                    />
                                )}
                                
                                />
                            </Grid>
                        );
                    }
                })
            }
            </Grid>
            </InlineDialog>
    );
};

export default AddGoal;