import InlineDialog from "../../../../components/mui/inlineDialogue";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Grid, TextField } from "@mui/material";
import { joiResolver } from "@hookform/resolvers/joi";
import { scoreValidations } from "../../../../validations/content/scores-data";
import { useOutletContext } from "react-router-dom";
import useSnackbar from "../../../../hooks/useSnackbar";
import ScoreDataService from "../../../../services/content/score-data";
import { IAddScoreData, IScoreDataField } from "../../../../interfaces/content/score-data";
import { useParams } from "react-router-dom";
import { IErrorResponse } from "../../../../interfaces";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
interface IOutletProps {
    reFetch: () => void;
}

const AddScore = () => {
    const navigate = useNavigate();
    const { snackbar } = useSnackbar();
    const outlet = useOutletContext<IOutletProps>();
    const { id } = useParams();
    const { addContentScoreData, updateContentScoreData, getContentScoreData } = ScoreDataService();
    const { handleSubmit, control, setValue, formState: { errors } } = useForm<IAddScoreData>({
        resolver: joiResolver(scoreValidations),
        defaultValues: {
            name: "",
            description: ""
        }
    });
    const hitQuery = (id === "new") ? false : true;

    const scoreData = useQuery({
        queryKey: ["scoreData"],
        queryFn: () => getContentScoreData({ _id: id }),
        enabled: hitQuery
    });

    useEffect(() => {
        if (id !== "new") {
            setValue("name", scoreData?.data?.data?.name || "");
            setValue("description", scoreData?.data?.data?.description || "");

        }
    }, [scoreData?.data]);

    const fields: IScoreDataField[] = [
        {
            name: "name",
            label: "Name",
            type: "input",
            placeholder: "Enter Score Data Name",
        },
        {
            name: "description",
            label: "Description",
            type: "input",
            placeholder: "Enter description",
        }
    ];
    const onSubmit = async (data: IAddScoreData) => {
        try {
            if (id === "new") {
                const response = await addContentScoreData(data);
                snackbar(response.message, "info");
                navigate("/content/score-data");
                outlet.reFetch && outlet.reFetch();
            } else {
                const response = await updateContentScoreData({ _id: id, ...data });
                snackbar(response?.message, "info");
                navigate("/content/score-data");
                outlet.reFetch && outlet.reFetch();
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err?.data?.message, "error");
            console.log("Error in add of types of content", err);
        }
    };
    return (
        <InlineDialog
            onClose={() => navigate("/content/score-data")}
            onSubmit={handleSubmit(onSubmit)}
        >
            <Grid container spacing={4}>
                {
                    fields.map(field => {
                        if (field.type === "input") {
                            return (
                                <Grid key={field.name} item xs={field.width ? field.width : 12}>
                                    <Controller
                                        name={field.name}
                                        control={control}
                                        render={(prop) => (
                                            <TextField
                                                label={field.label}
                                                placeholder={field.placeholder}
                                                inputProps={{
                                                    maxLength: field.name === "name" ? 50 : 300
                                                }}
                                                error={errors[field.name] ? true : false}
                                                helperText={errors[field.name]?.message}
                                                {...prop.field}

                                            />
                                        )}

                                    />
                                </Grid>
                            );
                        }
                    })
                }
            </Grid>
        </InlineDialog>
    );
};

export default AddScore;