import { FC, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { RoleService } from "../../../../services";
import { IUserFilter } from "../../../../interfaces";
import { capitalize } from "../../../../utilities/helper";
import { MenuItem } from "../../../../components/shared/filter";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    Box, Button, Checkbox, Chip, Divider, Grid, IconButton, List, ListItemButton,
    ListItemText, Menu, Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../../../../components/shared/filter/style.scss";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
interface props {
    anchorEl: null | HTMLElement;
    isOpen: boolean;
    OnClose: () => void;
}

const UserFilter: FC<props> = ({ anchorEl, isOpen, OnClose }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { getPartialRoles } = RoleService();
    const roles = useQuery({ queryKey: ["partial-roles"], queryFn: () => getPartialRoles() });
    const [state, setState] = useState<IUserFilter>({
        selectedMenu: 0,
        _role: [],
    });

    useEffect(() => {
        const _role: { key: string, value: string }[] = searchParams.get("_role") ? JSON.parse(String(searchParams.get("_role"))) : [];
        setState(prevState => ({
            ...prevState,
            _role,
        }));
    }, [searchParams]);

    const handleLeftListItem = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setState(prevState => ({
            ...prevState,
            selectedMenu: index
        }));
    };

    const handleRightListItem = (name: "_role", key: string, value: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];

        const isExist = state[name].find(ele => ele.key === key) ? true : false;
        if (isExist) {
            payload = state[name].filter(ele => ele.key !== key);
        } else {
            payload = state[name];
            payload.push({
                key,
                value
            });
        }

        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const deleteChip = (name: "_role", key: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];

        payload = state[name].filter(ele => ele.key !== key);
        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const resetFilter = () => {
        setState({
            selectedMenu: 0,
            _role: [],
        });
    };

    const onApply = () => {
        searchParams.set("_role", JSON.stringify(state._role));

        searchParams.set("page", "1");
        navigate({
            pathname: "/configurations/user",
            search: searchParams.toString()
        });
        OnClose();
    };

    const onClose = () => {
        const _role: { key: string, value: string }[] = searchParams.get("_role") ? JSON.parse(String(searchParams.get("_role"))) : [];
        setState(prevState => ({
            ...prevState,
            _role,
        }));
        OnClose();
    };

    return <>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={OnClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >
            <Box id="filters-container">
                <Box className="center mb-3" justifyContent="space-between" alignItems="start">
                    <div className="active-filter mb-1">
                        {
                            (state._role.length) ?
                                <>
                                    {state._role.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("_role", ele.key)} label={ele.value} variant="outlined" />)}
                                </>
                                :
                                <Box className="mt-2" display="flex" alignItems="center">
                                    <FilterAltOffIcon />
                                    <Typography className="ml-2">No filters are applied</Typography>
                                </Box>
                        }
                    </div>
                    <IconButton
                        onClick={onClose}
                        style={{ marginRight: "-10px" }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Grid className="filter-box" container>
                    <Grid id="left" item xs={5}>
                        <List component="nav">

                            <MenuItem
                                index={0}
                                label="Role"
                                selectedMenu={state.selectedMenu === 0}
                                onChange={handleLeftListItem}
                                count={state._role}
                            />

                        </List>
                    </Grid>
                    <Divider orientation="vertical" />

                    <Grid id="right" item xs={6}>
                        <List component="nav">
                            {
                                state.selectedMenu === 0 &&
                                roles.data?.data?.map(i => <ListItemButton
                                    key={i._id}
                                    selected={state._role.find(ele => ele.key === i._id) ? true : false}
                                    onClick={() => handleRightListItem("_role", i._id, capitalize(i.name))}
                                >
                                    <ListItemText primary={capitalize(i.name)} />
                                    <Checkbox edge="end" checked={state._role.find(ele => ele.key === i._id) ? true : false} />
                                </ListItemButton>)
                            }
                        </List>

                    </Grid>
                </Grid>

                <Box className="actions-btn" marginTop="8px" textAlign="end">
                    <Button variant="outlined" color="error" onClick={() => resetFilter()}>Clear All</Button>
                    <Button className="ml-2" onClick={onApply}>Apply</Button>
                </Box>
            </Box>
        </Menu>
    </>;
};

export default UserFilter;