import GetActions from "../../../components/get-actions";
import { IOnboardUser, IOnboardUserRow, IOnboardUserState, IPagination } from "../../../interfaces";
import { Outlet, useNavigate, useSearchParams, useOutletContext } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import { OnboardUserService } from "../../../services";
import useSnackbar from "../../../hooks/useSnackbar";
import { capitalize, createIndex, formatMobileNumber } from "../../../utilities/helper";
import { Box, IconButton } from "@mui/material";
import CustomTypography from "../../../components/mui/max-length-limit";
import { useQuery } from "@tanstack/react-query";
import useDebounce from "../../../hooks/useDebounce";
import Header from "../../../components/header";
import CustomTable from "../../../components/mui/table";
import WarningDialog from "../../../components/mui/warning-dialog";
import useResource from "../../../hooks/useResource";
import ImageViewer from "react-simple-image-viewer";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface IImageViewer {
    open: boolean,
    index: number,
    images: string[],
}

interface outletProps {
    refetchOnBoardUsers: () => void;
}

const OnBoardUser = () => {
    let rows: IOnboardUserRow[] = [];
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { resourceAllocate } = useResource();
    const outlet = useOutletContext<outletProps>();
    const { snackbar } = useSnackbar();
    const [search, setSearch] = useState<string>("");
    const { getOnBoardUsers, deleteOnBoardUser } = OnboardUserService();
    const [state, setState] = useState<IOnboardUserState>({
        deleteWarning: false,
        _onBoardUser: "",
        pagination: {
            page: 1,
            limit: 20,
            totalPages: 1
        },
        filters: {
            search: "",
        },
        filterDialog: {
            anchorEl: null,
            isOpen: false
        },
        filterCount: 0,
    });

    const [imageViewer, setImageViewer] = useState<IImageViewer>({
        open: false,
        index: 0,
        images: [],
    });

    const onboardUsers = useQuery({
        queryKey: ["allOnboardUsers", state.pagination.page, state.filters],
        queryFn: () => getOnBoardUsers({
            pagination: true, limit: state.pagination.limit, page: state.pagination.page, ...state.filters
        })
    });

    const searchRecord = useDebounce(search, 1000);
    useEffect(() => {
        if (searchRecord.length) {
            setSearchParams(prev => ({
                ...prev,
                page: 1,
                search: searchRecord
            }));
        } else {
            searchParams.delete("search");
            setSearchParams(searchParams);
        }
    }, [searchRecord]);

    useEffect(() => {
        if (onboardUsers.data?.data.length) {
            setState(prevState => ({
                ...prevState,
                pagination: {
                    ...prevState.pagination,
                    page: onboardUsers.data.meta.page,
                    totalPages: onboardUsers.data.meta.totalPages,
                    totalRecords: onboardUsers.data.meta.totalRecords
                }
            }));
            const images: string[] = [];
            onboardUsers.data?.data.map(image => {
                images.push(image.picture);
            });

            setImageViewer(prev => ({
                ...prev,
                images
            }));
        }
    }, [onboardUsers.data?.meta]);

    useEffect(() => {
        const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
        const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
        setState(prevState => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page
            },
            filters: {
                ...prevState.filters,
                search,
            },
        }));
    }, [searchParams]);

    const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
        searchParams.set("page", page.toString());
        setSearchParams(searchParams);
    };

    const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

    const handleImageViewer = (index?: number) => {
        if (!imageViewer.open) {
            setImageViewer({
                ...imageViewer,
                open: true,
                index: index ? index : 0,
            });
        } else {
            setImageViewer({
                ...imageViewer,
                open: false,
                index: 0,
            });
        }
    };

    const handleDelete = (_onBoardUser = "") => {
        setState(prevState => ({
            ...prevState,
            deleteWarning: !prevState.deleteWarning,
            _onBoardUser
        }
        ));
    };

    const onDelete = async () => {
        try {
            const user = await deleteOnBoardUser({ _id: state._onBoardUser });
            snackbar(user.message, "info");
            handleDelete();
            onboardUsers.refetch();
            outlet?.refetchOnBoardUsers && outlet.refetchOnBoardUsers();
        } catch (error) {
            console.log("Error in user delete", error);
        }
    };

    const columns = [
        {
            id: "id",
            label: "S No."
        },
        {
            id: "onBoardUser_profile",
            label: "Profile",
        },
        {
            id: "onBoardUser_name",
            label: "Name",
        },
        {
            id: "onBoardUser_phoneNumber",
            label: "Phone Number"
        },
        {
            id: "onBoardUser_email",
            label: "Email"
        },
        {
            id: "onBoardUser_designation",
            label: "Designation"
        },
        {
            id: "action",
            label: "Actions"
        },
    ];

    const onCopy = (label: string, text: string) => {
        navigator.clipboard.writeText(text);
        snackbar(`${capitalize(text)} ID copied`, "info");
      };

    const createRow = (index: number, onboardUser: IOnboardUser, pagination: IPagination) => {
        const action = <GetActions
            icons={[
                { name: "Edit", method: () => navigate({ pathname: "" + onboardUser._id, search: searchParams.toString() }), disabled: resourceAllocate("onboard-user.write") ? false : true },
                { name: "Delete", method: () => handleDelete(onboardUser._id), disabled: resourceAllocate("onboard-user.remove") ? false : true },
            ]}
        />;

        const email = (
            <Box display="flex" alignItems="center">
            <IconButton className="ml-2" onClick={() => onCopy(onboardUser?.email,"email")} size="small">
              <ContentCopyIcon fontSize="small" />
            </IconButton >
            <CustomTypography
              limit={20}
              label={onboardUser?.email}
            />
            </Box>
          );

        return {
            id: createIndex(pagination, index),
            onBoardUser_profile: <IconButton onClick={() => handleImageViewer(index)}><img className="thumbnail" src={onboardUser?.picture} alt="" /></IconButton>,
            onBoardUser_name: (
                <CustomTypography
                    limit={30}
                    label={capitalize(onboardUser?.name)}
                />
            ),
            onBoardUser_phoneNumber: formatMobileNumber(onboardUser?.phone) || "",
            onBoardUser_email: email,
            onBoardUser_designation: capitalize(onboardUser.designation),
            action
        };
    };

    if (onboardUsers.data?.data.length) {
        rows = onboardUsers.data?.data.map((onboardUser, i) => createRow(i, onboardUser, state.pagination));
    }

    return (
        <div>
            {/* Add Data  */}
            <Header
                className="my-2"
                searchPlaceholder="Search by name"
                onSearch={onSearch}
                btnText="Add Onboard User"
                onBtnClick={resourceAllocate("onboard-user.write") ? () => navigate("new") : undefined}
                isDeleteDisable={false}
            />

            {/* Show Data  */}
            <Box marginTop="10px">
                <CustomTable
                    columns={columns}
                    rows={rows}
                    height="calc(100vh - 248px)"
                    errorMessage="Add user to see the data here"
                    pagination={state.pagination}
                    onPageChange={onPageChange}
                />
            </Box>

            {/* Delete Data  */}
            <WarningDialog
                isOpen={state.deleteWarning}
                onClose={() => handleDelete()}
                onConfirm={onDelete}
                title="Delete User"
                description="Are you sure you want to delete this user?"
            />

            {/* Preview Image */}
            {imageViewer.open && (
                <>
                    <ImageViewer
                        src={imageViewer.images}
                        currentIndex={imageViewer.index}
                        disableScroll={true}
                        closeOnClickOutside={false}
                        onClose={handleImageViewer}
                        backgroundStyle={
                            {
                                color: "white"
                            }
                        }
                    />
                </>
            )}

            <Outlet context={{ ...outlet, reFetch: onboardUsers.refetch }} />
        </div>
    );
};

export default OnBoardUser;