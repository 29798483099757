import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { capitalize, displayName } from "../../../utilities/helper";
import { Box, Grid, SelectChangeEvent, TextField, Typography, } from "@mui/material";
import { IProfileField, IProfle } from "../../../interfaces/setting/profile";
import Avatar from "@mui/material/Avatar";
import MobileNumber from "../../../components/mui/mobile-number";
import useUser from "../../../hooks/useUser";

const Profile = () => {
  const isDisable = true;
  const { user } = useUser();
  const { control, setValue, formState: { errors } } = useForm<IProfle>({
    defaultValues: {
      name: "",
      role: "",
      email: "",
      number: "",
      department: "",
    }
  });

  const [state, setState] = useState({
    country: "INDIA",
    dialCode: "+91",
    iso2: "IN",
  });

  useEffect(() => {
    if (user) {
      setValue("name", `${capitalize(displayName(user))}`);
      setValue("role", capitalize(user._role?.name));
      setValue("email", user.email);
      setValue("number", user.phone.number);
      setState(user.phone);
      setValue("department", capitalize(user.department?.name));
    }

  }, [user]);

  const onNumberChange = (e: SelectChangeEvent<string>) => {
    const { value } = e.target;
    const data = value.split(":");
    setState({
      country: data[0],
      iso2: data[1],
      dialCode: data[2]
    });
  };

  const fields: IProfileField[] = [
    {
      label: "Name",
      name: "name",
      type: "input",
      wordLimit: 70
    },
    {
      label: "Authority",
      name: "role",
      type: "input",
      wordLimit: 70
    },
    {
      label: "Email",
      name: "email",
      type: "input",
      wordLimit: 70
    },
    {
      label: "Mobile Number",
      name: "number",
      type: "mobile-number",
    },
    {
      label: "Team",
      name: "department",
      type: "input",
      wordLimit: 70
    },
  ];

  return (
    <Box paddingTop="50px" className="center">
      <form>
        <Box height={!isDisable ? "49vh" : "56vh"} overflow="auto" className="profile-box">
          <Avatar className="profile-avatar" sx={{ width: "60px", height: "60px", margin: "30px auto" }} src={user?.integrationMeta?.picture}>
            {user?.firstName && capitalize(user?.firstName.charAt(0) || "")}
          </Avatar>
          <Grid container spacing={4}>
            {
              fields.map(field => {
                if (field.type === "input") {
                  return (<Grid key={field.label} item xs={12} md={6}>
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => <TextField
                        {...prop.field}
                        label={field.label}
                        className="disable-text"
                        disabled={isDisable}
                        variant={isDisable ? "standard" : "outlined"}
                        size={isDisable ? "medium" : "small"}
                        autoComplete={field.name === "email" ? "username" : ""}
                        inputProps={{ maxLength: field.wordLimit }}
                      />}
                    />
                  </Grid>
                  );
                }
                else if (field.type === "mobile-number") {
                  return <Grid key={field.label} item xs={12} md={6}>
                    <Controller
                      control={control}
                      name={field.name}
                      render={(prop) => <MobileNumber
                        other={prop.field}
                        key={field.label}
                        className="disable-text"
                        disabled={isDisable}
                        NumberFieldLabel={field.label}
                        dialCodeValue={`${state.country}:${state.iso2}:${state.dialCode}`}
                        dialCodeName={field.name}
                        onChange={onNumberChange}
                        error={errors[field.name] ? true : false}
                        helperText={errors[field.name]?.message}
                      />}
                    />
                  </Grid>;
                }
              })
            }

            {
              user?._role?.name === "default" ?
                <Grid item xs={12}>
                  <Box className="text-center">
                    <Typography variant="caption" color="red">You have not assign any permission, Please contact to Administrator</Typography>
                    <br />
                    <Typography variant="caption">
                      <a className="primary-color " href="mailto:admin@example.com">Click here to send an email</a>
                    </Typography>
                  </Box>
                </Grid>
                :
                null
            }
          </Grid>
        </Box>
      </form>
    </Box>
  );
};

export default Profile;