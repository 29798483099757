import {
  Autocomplete,
  AutocompleteChangeReason,
  Box,
  Chip,
  Grid,
  MenuItem,
  TextField,
  Button,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  IKeywordField,
  IKeywordFieldForm,
} from "../../../../interfaces/content/keyword";
import { KeywordValidations } from "../../../../validations/content/keyword";
import { joiResolver } from "@hookform/resolvers/joi";
import InlineDialog from "../../../../components/mui/inlineDialogue";
import { useQuery } from "@tanstack/react-query";
import CategoryService from "../../../../services/content/category";
import SubCategoryService from "../../../../services/content/sub-category";
import KeywordService from "../../../../services/content/keyword";
import useSnackbar from "../../../../hooks/useSnackbar";
import { useOutletContext } from "react-router-dom";
import { IErrorResponse } from "../../../../interfaces";
import { useState, useEffect, SyntheticEvent } from "react";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { capitalize } from "../../../../utilities/helper";
import { ISubCategory } from "../../../../interfaces/content/sub-category";
import CustomLabel from "../../../../components/mui/custom-label";
import { ICategory } from "../../../../interfaces/content/category";
import "./style.scss";

interface IOutletProps {
  reFetch: () => void;
}

interface IState {
  fields: IKeywordField[];
}

const AddKeyword = () => {
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const { id } = useParams();
  const { getContentCategorys } = CategoryService();
  const outlet = useOutletContext<IOutletProps>();
  const { getContentSubCategorys } = SubCategoryService();
  const { addContentKeyword, getContentKeyword, updateContentKeyword } =
    KeywordService();
  const hitQuery = id === "new" ? false : true;

  const {
    unregister,
    handleSubmit,
    trigger,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<IKeywordFieldForm>({
    resolver: joiResolver(KeywordValidations),
    defaultValues: {
      form: [
        {
          name: "",
          _category: "",
          children3: [],
          _subCategory: [],
        },
      ],
    },
  });
  const [selectedCategoryId, setSelectedCategoryId] =
    useState<ICategory | null>(null);
  const [index, setIndex] = useState<number>(0);

  const keyword = useQuery({
    queryKey: ["keyword"],
    queryFn: () =>
      getContentKeyword({
        _id: id,
      }),
    enabled: hitQuery,
  });

  useEffect(() => {
    if (id !== "new") {
      if (keyword?.data?.data) {
        setValue(`form.${0}.name`, keyword?.data?.data?.name || "");
        setValue(
          `form.${0}._category`,
          keyword?.data?.data?._category?._id || ""
        );
        setSelectedCategoryId(keyword?.data?.data?._category);
        const subCategoryValue = keyword?.data?.data?._subCategory;
        if (Array.isArray(subCategoryValue)) {
          setValue(
            `form.${0}._subCategory`,
            subCategoryValue.map((subCategory) => subCategory._id)
          );
        } else {
          setValue(`form.${0}._subCategory`, []);
        }
      }
    }
  }, [id, keyword?.data]);

  const categorys = useQuery({
    queryKey: ["categorys"],
    queryFn: () =>
      getContentCategorys({
        isActive: true,
      }),
    onSuccess: (data) => {
      const newarr = [...state.fields];
      const categoryFieldIndex = newarr.findIndex(
        (field) => field.name2 === "_category"
      );
      if (categoryFieldIndex !== -1) {
        newarr[categoryFieldIndex].children2 = data.data.map((category) => (
          <MenuItem key={category?._id} value={category?._id}>
            {category?.name}
          </MenuItem>
        ));
        setState((prev) => ({ ...prev, fields: newarr }));
      }
    },
  });

  const subCategorys = useQuery({
    queryKey: [selectedCategoryId?._id, index],
    queryFn: () =>
      getContentSubCategorys({
        categoryId: selectedCategoryId?._id,
        isActive: true,
      }),
    onSuccess: (data) => {
      setValue(`form.${index}.children3`, data?.data);
      const newarr = [...state.fields];
      const subCategoryFieldIndex = newarr.findIndex(
        (field) => field.name3 === "_subCategory"
      );
      if (subCategoryFieldIndex !== -1) {
        newarr[subCategoryFieldIndex].children3 = data.data.map(
          (subCategory) => (
            <MenuItem key={subCategory?._id} value={subCategory?._id}>
              {subCategory?.name}
            </MenuItem>
          )
        );
        setState((prev) => ({ ...prev, fields: newarr }));
      }
    },
  });

  const categorysData = categorys?.data?.data;
  const subCategorysData = subCategorys?.data?.data;

  const initiateFields: IKeywordField[] = [
    {
      name1: "name",
      type1: "input",
      placeholder1: "Type your keyword",
      label1: "Keyword Name*",
      width1: 4,
      name2: "_category",
      type2: "select",
      placeholder2: "Select Category",
      label2: "Please Select Category",
      width2: 4,
      children2: categorysData?.map((category) => (
        <MenuItem key={category._id} value={category._id}>
          {category.name}
        </MenuItem>
      )),
      name3: "_subCategory",
      type3: "select",
      label3: "Please Select Sub Category",
      placeholder3: "Select Sub Category",
      children3: subCategorysData?.map((subCategory) => (
        <MenuItem key={subCategory._id} value={subCategory._id}>
          {subCategory.name}
        </MenuItem>
      )),
      width3: 4,
    },
  ];

  const [state, setState] = useState<IState>({
    fields: initiateFields,
  });
  const onChangeUsersAutoComplete = (
    event: SyntheticEvent<Element, Event>,
    newValues: ISubCategory[],
    index: number
  ) => {
    const values = (getValues(`form.${index}._subCategory`) || []) as string[];

    const newValuesArray = Array.isArray(newValues) ? newValues : [newValues];
    const uniqueValues = newValuesArray
      .map((value) => value?._id)
      .filter((id) => !values.includes(id));

    const resultArray = [...values, ...uniqueValues];
    setValue(`form.${index}._subCategory`, resultArray);
    trigger(`form.${index}._subCategory`);
  };
  const handleChange = async (
    event: React.SyntheticEvent<Element, Event>,
    value: ICategory | null,
    reason: AutocompleteChangeReason,
    index: number
  ) => {
    if (value) {
      setSelectedCategoryId(value);
      setValue(`form.${index}._category`, value._id);
      trigger(`form.${index}._category`);
      setValue(`form.${index}._subCategory`, []);
      setIndex(index);
    }
  };

  const handleDeleteUserChip = (subCategoryId: string, index: number) => {
    const anss = getValues(`form.${index}._subCategory`);
    const newData = anss.filter((id) => id !== subCategoryId);
    setValue(`form.${index}._subCategory`, newData);
  };

  const onSubmit = async (data: IKeywordFieldForm) => {
    const payload = data.form.map((data) => {
      // eslint-disable-next-line
      const { children3, ...payload } = data;
      return payload;
    });
    try {
      if (id === "new") {
        const response = await addContentKeyword(payload);
        outlet.reFetch && outlet.reFetch();
        snackbar(response.message, "info");
        navigate("/content/keywords");
      } else {
        const response = await updateContentKeyword({ ...payload[0], _id: id });
        outlet.reFetch && outlet.reFetch();
        snackbar(response.message, "info");
        navigate("/content/keywords");
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "error");
      console.log("Error in add keyword", err);
    }
  };

  const addField = (index: number) => {
    const fieldPayload = state.fields;
    fieldPayload.splice(index + 1, 0, initiateFields[0]);
    setState((prev) => ({ ...prev, fields: fieldPayload }));

    const payload = getValues();
    let i = payload.form.length - 1;

    if (payload.form.length - 1 !== index) {
      while (i > index) {
        const nextIndexVAl = payload.form[i];

        setValue(`form.${i + 1}.name`, nextIndexVAl.name);
        setValue(`form.${i + 1}._category`, nextIndexVAl._category);
        setValue(`form.${i + 1}._subCategory`, nextIndexVAl._subCategory);
        --i;
      }
    }
    setValue(`form.${index + 1}.name`, "");
    setValue(`form.${index + 1}._category`, "");
    setValue(`form.${index + 1}._subCategory`, []);
  };

  const removeField = (index: number) => {
    const fieldPayload = state.fields;
    fieldPayload.splice(index, 1);
    setState((prev) => ({
      ...prev,
      fields: fieldPayload,
    }));
    const payload = getValues();
    const size = payload.form.length;
    for (let i = index; i < size - 1; i++) {
      setValue(`form.${i}.name`, payload.form[i + 1].name);
      setValue(`form.${i}._category`, payload.form[i + 1]._category);
      setValue(`form.${i}._subCategory`, payload.form[i + 1]._subCategory);
    }
    unregister(`form.${size - 1}`);
    const formPayload = getValues().form.filter((val) => val !== undefined);
    setValue("form", formPayload);
  };

  return (
    <Box>
      <InlineDialog
        onClose={() => navigate("/content/keywords")}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={4}>
          {state.fields.map((field, index) => {
            if (field.type1 === "input") {
              return (
                <>
                  {/* name field  */}
                  <Grid item xs={3}>
                    <Controller
                      name={`form.${index}.${field.name1}`}
                      control={control}
                      render={(prop) => (
                        <TextField
                          label={field.label1}
                          className="disable-text"
                          placeholder={field.placeholder1}
                          variant="outlined"
                          inputProps={{
                            maxLength: 50,
                          }}
                          size="small"
                          error={
                            errors["form"] &&
                            errors["form"][index] &&
                            errors["form"][index]?.[field.name1]
                              ? true
                              : false
                          }
                          helperText={
                            errors["form"] &&
                            errors["form"][index] &&
                            errors["form"][index]?.[field.name1]?.message
                          }
                          {...prop.field}
                        />
                      )}
                    />
                  </Grid>

                  {/* Category  */}
                  <Grid item xs={3}>
                    <Controller
                      control={control}
                      name={`form.${index}.${field.name2}`}
                      render={(prop) => (
                        <Autocomplete
                          className="disable-text"
                          options={categorysData?.map((data) => data) || []}
                          clearIcon={null}
                          getOptionLabel={(option) => capitalize(option.name)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={
                                errors["form"] &&
                                errors["form"][index] &&
                                errors["form"][index]?.[field.name2]
                                  ? true
                                  : false
                              }
                              helperText={
                                errors["form"] &&
                                errors["form"][index] &&
                                errors["form"][index]?.[field.name2]?.message
                              }
                              size={"small"}
                              variant={"outlined"}
                              label={
                                <CustomLabel
                                  label="Please Select Category"
                                  required
                                />
                              }
                              placeholder={field.placeholder2}
                            />
                          )}
                          {...prop.field}
                          value={
                            categorysData?.find(
                              (category) =>
                                category._id ===
                                getValues(`form.${index}.${field.name2}`)
                            ) || null
                          }
                          onChange={(e, value, reason) => {
                            handleChange(e, value, reason, index);
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {/* Sub Category  */}
                  <Grid item xs={6} style={{ width: "100%" }}>
                    <Box
                      display="flex"
                      alignItems="start"
                      flexDirection="column"
                    >
                      <Controller
                        control={control}
                        name={`form.${index}.${field.name3}`}
                        render={(prop) => (
                          <>
                            <Box
                              display="flex"
                              alignItems="flex-start"
                              gap="7px"
                              mb={1}
                              width="100%"
                            >
                              <Autocomplete
                                sx={{ width: "70%" }}
                                className="disable-text"
                                options={
                                  getValues(`form.${index}.children3`)?.map(
                                    (subCategorysDa) => subCategorysDa
                                  ) || []
                                }
                                getOptionLabel={(option) =>
                                  capitalize(option.name)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    size="small"
                                    label={field.label3}
                                    placeholder={field.placeholder3}
                                    error={
                                      errors["form"] &&
                                      errors["form"][index] &&
                                      errors["form"][index]?.[field.name3]
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      errors["form"] &&
                                      errors["form"][index] &&
                                      errors["form"][index]?.[field.name3]
                                        ?.message
                                    }
                                  />
                                )}
                                {...prop.field}
                                value={[]}
                                onChange={(e, value) =>
                                  onChangeUsersAutoComplete(e, value, index)
                                }
                                multiple
                              />
                              {id === "new" &&
                                getValues(`form.${index}.${field.name3}`) && (
                                  <div className="add-mores">
                                    {state.fields.length > 1 && (
                                      <Button
                                        className="mr-1"
                                        sx={{
                                          padding: "7px 15px",
                                          visibility:
                                            state.fields.length > 1
                                              ? "visible"
                                              : "hidden",
                                        }}
                                        variant="outlined"
                                        color="error"
                                        onClick={() => removeField(index)}
                                      >
                                        <RemoveCircleIcon color="error" />
                                      </Button>
                                    )}
                                    <Button
                                      variant="outlined"
                                      sx={{
                                        padding: "7px 15px",
                                        visibility:
                                          index !== state.fields.length - 1
                                            ? "hidden"
                                            : "visible",
                                      }}
                                      color="primary"
                                      className="add-more-buttons"
                                      onClick={() => addField(index)}
                                    >
                                      <AddCircleIcon color="primary" />
                                    </Button>
                                  </div>
                                )}
                            </Box>
                            <Box
                              display="flex"
                              alignItems={"flex-start"}
                              width="100%"
                            >
                              <Box
                                display="flex"
                                alignItems={"flex-start"}
                                flexWrap={"wrap"}
                                width="70%"
                              >
                                {(
                                  getValues(`form.${index}.${field.name3}`) ||
                                  []
                                )?.map((subCategoryId) => (
                                  <Chip
                                    key={subCategoryId}
                                    label={
                                      getValues(
                                        `form.${index}.children3`
                                      )?.find(
                                        (user) => user._id === subCategoryId
                                      )?.name
                                    }
                                    color="primary"
                                    variant="outlined"
                                    onDelete={() =>
                                      handleDeleteUserChip(subCategoryId, index)
                                    }
                                    sx={{ margin: "5px" }}
                                  />
                                ))}
                              </Box>
                            </Box>
                          </>
                        )}
                      />
                    </Box>
                  </Grid>
                </>
              );
            }
          })}
        </Grid>
      </InlineDialog>
    </Box>
  );
};
export default AddKeyword;