import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import AnalyticsCard from "../components/analytics-card";
import Header from "../components/header";
import { BarGraph } from "../../../components/graphs";
import ReportService from "../../../services/report";

const Overview = () => {
  const { overview } = ReportService();
  const [searchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    department: searchParams.get("department") || "all",
    job: searchParams.get("job") || "all",
    typeOfLead: searchParams.get("typeOfLead") || "all",
    time: searchParams.get("time") || "all",
  });
  const getOverview = useQuery({
    queryKey: ["report-overview", filters],
    queryFn: () => overview({ ...filters })
  });

  useEffect(() => {
    const job = searchParams.get("job") ? String(searchParams.get("job")) : "all";
    const department = searchParams.get("department") ? String(searchParams.get("department")) : "all";
    const typeOfLead = searchParams.get("typeOfLead") ? String(searchParams.get("typeOfLead")) : "all";
    const time = searchParams.get("time") ? String(searchParams.get("time")) : "all";

    setFilters(prev => ({
      ...prev,
      job,
      department,
      typeOfLead,
      time
    }));
  }, [searchParams]);

  const countData = [
    {
      title: "Candidates imported",
      subtitle: "No. of Candidates imported",
      count: getOverview.data?.data?.importedCandidates || 0,
      isMoreSubtitle: false,
    },
    {
      title: "Candidates applied",
      subtitle: "No. of Candidates applied",
      count: getOverview.data?.data?.appliedCandidates || 0,
      isMoreSubtitle: false,
    },
    {
      title: "Candidates in pipeline",
      subtitle: "No. of Candidates with live process",
      count: getOverview.data?.data?.pipelineCandidates || 0,
      isMoreSubtitle: false,
    },
    {
      title: "Candidates hired",
      subtitle: "No. of Candidates hired",
      count: getOverview.data?.data?.hiredcandidates || 0,
      isMoreSubtitle: false,
    },
    {
      title: "Interviews scheduled",
      subtitle: "No. of interviews scheduled in selected time",
      count: Number(getOverview.data?.data?.interviewScheduled) || 0,
      isMoreSubtitle: false,
    },
    {
      title: "Jobs posted",
      subtitle: "No. of jobs posted",
      count: getOverview?.data?.data?.totalPostedJobs || 0,
      isMoreSubtitle: false,
    },
    {
      title: "Active jobs",
      subtitle: "No. of jobs with live process",
      isMoreSubtitle: true,
      subtitle1: "Total jobs",
      count1: getOverview.data?.data?.totalActiveJobs || 0,
      subtitle2: "Total openings",
      count2: getOverview.data?.data?.totalOpenings || 0,
    },
    {
      title: "Candidates rejected",
      subtitle: "No. of candidates rejected",
      isMoreSubtitle: true,
      subtitle1: "Not Interested",
      count1: getOverview.data?.data?.directlyRejected || 0,
      subtitle2: "Total rejections",
      count2: getOverview.data?.data?.rejectedcandidates || 0,
    },
  ];

  const showData = (label: string) => {
    const data = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: ["rgba(17, 140, 252, 0.3)"],
        },
      ],
    };

    const labels: string[] = [];
    const count: number[] = [];

    getOverview.data?.data?.allCandidatesByDate && getOverview.data?.data?.allCandidatesByDate.map(candidate => {

      labels.push(candidate?._id) || "";
      count.push(candidate?.count || 0);
    });

    if (label === "allCandidates") {
      return {
        ...data,
        labels: labels,
        datasets: [
          {
            label: "Candidates",
            data: count,
            backgroundColor: ["rgba(17, 140, 252, 0.3)"],
          },
        ],
      };
    } else {
      return data;
    }
  };

  return (
    <>
      {/* Show Data  */}
      <Box marginTop="10px" height="calc(100vh - 145px)">
        <Header />

        <Box marginTop="16px" height="calc(100% - 70px)" overflow="auto">
          <Grid container spacing={2}>
            {/* show count  */}
            {
              countData.map((info, index) => (
                <Grid key={index} item xs={6} md={4} lg={3}>
                  <AnalyticsCard
                    index={index}
                    title={info.title}
                    subtitle={info.subtitle}
                    count={info.count}
                    isMoreSubtitle={info.isMoreSubtitle}
                    primarySubTitle={info.subtitle1}
                    count1={info.count1}
                    secondarySubTitle={info.subtitle2}
                    count2={info.count2}
                  />
                </Grid>
              ))
            }

            {/* graph  */}
            <Grid item xs={12}>
              <BarGraph
                label="Candidates sourced over time"
                data={showData("allCandidates")}
              />
            </Grid>
          </Grid>
        </Box>

      </Box>
    </>
  );
};
export default Overview;