import { Box, Chip } from "@mui/material";
import { useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { TestSessionService } from "../../../../services";
import { useQuery } from "@tanstack/react-query";
import { IResponseTechnicalQuestionRow } from "../../../../interfaces/configuration/tests";
import { ITechnicalTestQuestion, IPagination } from "../../../../interfaces";
import { capitalize, checkTestStatusBackgroundColor, checkTestStatusColor, createIndex } from "../../../../utilities/helper";
import CustomTable from "../../../mui/table";
import GetActions from "../../../get-actions";
import ViewTechnicalQuestion from "./view-question";

interface IState {

  pagination: {
    page: number;
    limit: number;
    totalPages: number;
  };
}
const ResponseAllTechnicalQuestions = () => {
  let rows: IResponseTechnicalQuestionRow[] = [];
  const { testId } = useParams();
  const { getCoderByteQuestions } = TestSessionService();


  const [state] = useState<IState>({
    pagination: {
      page: 1,
      limit: 999999,
      totalPages: 1,
    },
  });

  const testQuestions = useQuery({
    queryKey: ["coder-byte-test-questions"],
    queryFn: () => getCoderByteQuestions({ _id: testId }),
  }
  );

  const columns = [
    {
      id: "id",
      label: "S No.",
    },
    {
      id: "testName",
      label: "Test Name",
    },
    {
      id: "marksObtained",
      label: "Marks Obtained",
    },
    {
      id: "difficulty",
      label: "Difficulty",
    },
    {
      id: "action",
      label: "Action",
    },
  ];

  const [viewQuestion, setViewQuestion] = useState(false);
  const [test, setTest] = useState<ITechnicalTestQuestion>();

  const createRow = (
    index: number,
    test: ITechnicalTestQuestion,
    pagination: IPagination
  ): IResponseTechnicalQuestionRow => {
    
    const action = <GetActions
        icons={[
          {
            name: "View", method: () => {setViewQuestion(true); setTest(test);}
          },
        ]}
      />;

    const difficulty = test._question && <Chip variant={"outlined"} sx={{ borderRadius: "4px", width: "110px", backgroundColor: checkTestStatusBackgroundColor(test._question.difficulty) }} color={checkTestStatusColor(test._question.difficulty)} label={capitalize(test._question.difficulty)} />;

    return {
      id: test._question && createIndex(pagination, index),
      testName: test._question.title.length > 60 
      ? `${capitalize(test._question.title).slice(0, 60)}...`
      : test._question.title ?? "--", 
      difficulty: test._question && difficulty,
      marksObtained: test._question && `${test.marksScored ?? 0}/${test._question.weightage}`,
      action: test._question && action};
  };
  if (testQuestions &&
    testQuestions.data &&
    testQuestions.data.data
    && testQuestions.data.data.length) {
    rows = testQuestions.data.data.map((test, index) =>
      createRow(index, test, state.pagination)
    );
  }

  return (
    <div>
      <Box paddingTop="10px">
        <Box height="56vh" paddingTop="10px" margin={"0 10px"}>
          <CustomTable
            columns={columns}
            rows={rows}
            height={"calc(100% - 30px)"}
            width="calc(100% - 2px)"
          />
        </Box>
      </Box>

      {viewQuestion && <ViewTechnicalQuestion testId={testId || ""} questionId={test && test._question._id || ""} isOpen={viewQuestion} onClose={() => setViewQuestion(false)}/>}
      <Outlet context={{ ...Outlet }} />
    </div>
  );
};
export default ResponseAllTechnicalQuestions;


