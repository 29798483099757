import "./style.scss";
import { useEffect, useState } from "react";
import { Divider, List, ListItemIcon, ListItemText, Avatar, Typography, DialogContent, Dialog, Box, Button, IconButton, Badge, Tooltip } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import {
    LOAD_JOB,
    LOAD_USER,
    LOAD_ROLE,
    LOAD_TEAM,
    LOAD_ENGAGEMENT_TYPE,
    LOAD_JOB_POSITION,
    LOAD_STATUS,
    LOAD_TEMPLATE,
    LOAD_COLLEGE,
    LOAD_CPD_DRIVE,
    LOAD_JOB_TITLE,
    LOAD_CMS_PRODUCT,
} from "../redux";
import { UsersService, RoleService, StatusService, EngagementService, JobPositionService, TeamsService, JobTitleService, JobService, CollegeService, TemplateService, cpdDriveService, NotificationService } from "../services";
import Logo from "../assets/images/logo.png";
import Logout from "../assets/images/logout.png";
import DashboardIcon from "@mui/icons-material/Dashboard";
import homeIcon from "@mui/icons-material/Home";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ListItem from "./components/layout-list-item";
import SettingsIcon from "@mui/icons-material/Settings";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SchoolIcon from "@mui/icons-material/School";
import EventIcon from "@mui/icons-material/Event";
import WebIcon from "@mui/icons-material/Web";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import DeleteIcon from "@mui/icons-material/Delete";
import useUser from "../hooks/useUser";
import useResource from "../hooks/useResource";
import { capitalize } from "../utilities/helper";
import Notification from "../screens/notification";
import SearchCandidate from "../components/search-candidate";
import ListIcon from "@mui/icons-material/List";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import InsightsIcon from "@mui/icons-material/Insights";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import ProjectsService from "../services/content/projects-products";
import { IProject } from "../interfaces/content/projects-products";
import { TestsService } from "../services/configuration/tests";

const Layout = () => {
    const dispatch = useDispatch();
    const { resourceAllocate } = useResource();
    const navigate = useNavigate();
    const { setUser } = useUser();
    const { getPartialJobs } = JobService();
    const { getPartialUsers } = UsersService();
    const { getPartialRoles } = RoleService();
    const { getPartialTeams } = TeamsService();
    const { getPartialEngagements } = EngagementService();
    const { getJobPositions } = JobPositionService();
    const { getJobTitles } = JobTitleService();
    const { getStatuss } = StatusService();
    const { getTemplates } = TemplateService();
    const { getPartialColleges } = CollegeService();
    const { getCpdDrives } = cpdDriveService();
    const { countNotifications } = NotificationService();
    const { getContentProjects } = ProjectsService();
    const {getTests} = TestsService(); 

    const { user } = useUser();
    const getJobs = useQuery({ queryKey: ["partialJob"], queryFn: () => getPartialJobs() });
    const getUsers = useQuery({ queryKey: ["partialUSers"], queryFn: () => getPartialUsers() });
    const getTest = useQuery({ queryKey: ["getTest"], queryFn: () => getTests() });
    const roles = useQuery({ queryKey: ["partialRoles"], queryFn: () => getPartialRoles() });
    const teams = useQuery({ queryKey: ["partialTeams"], queryFn: () => getPartialTeams() });
    const getEngagementTypes = useQuery({ queryKey: ["partialEngagementType"], queryFn: () => getPartialEngagements() });
    const jobPositions = useQuery({ queryKey: ["partialJobPositions"], queryFn: () => getJobPositions({}) });
    const jobTitles = useQuery({ queryKey: ["partialJobTitles"], queryFn: () => getJobTitles({}) });
    const getStatusData = useQuery({ queryKey: ["allStatus"], queryFn: () => getStatuss() });
    const templates = useQuery({ queryKey: ["allTemplates"], queryFn: () => getTemplates() });
    const getColleges = useQuery({ queryKey: ["partialColleges"], queryFn: () => getPartialColleges() });
    const getDrives = useQuery({ queryKey: ["allCpdDrives"], queryFn: () => getCpdDrives({}) });
    const notificationCount = useQuery({ queryKey: ["notification-count"], queryFn: () => countNotifications() });
    const projectsList = useQuery({
        queryKey: ["projects"],
        queryFn: () => getContentProjects({ isActive: true }),
    });


    if (getJobs.data?.data.length) {

        dispatch({ type: LOAD_JOB, payload: { list: getJobs?.data?.data } });
    }

    if (getUsers.data?.data.length) {

        dispatch({ type: LOAD_USER, payload: { list: getUsers?.data?.data } });
    }

    if (roles.data?.data.length) {

        dispatch({ type: LOAD_ROLE, payload: { list: roles?.data?.data } });
    }

    if (teams.data?.data.length) {

        dispatch({ type: LOAD_TEAM, payload: { list: teams?.data?.data } });
    }

    if (getEngagementTypes.data?.data.length) {

        dispatch({ type: LOAD_ENGAGEMENT_TYPE, payload: { list: getEngagementTypes?.data?.data } });
    }

    if (jobPositions.data?.data.length) {

        dispatch({ type: LOAD_JOB_POSITION, payload: { list: jobPositions?.data?.data } });
    }

    if (jobTitles.data?.data.length) {

        dispatch({ type: LOAD_JOB_TITLE, payload: { list: jobTitles?.data?.data } });
    }

    if (getStatusData.data?.data.length) {

        dispatch({ type: LOAD_STATUS, payload: { list: getStatusData?.data?.data } });
    }

    if (templates.data?.data.length) {

        dispatch({ type: LOAD_TEMPLATE, payload: { list: templates?.data?.data } });
    }

    if (getColleges.data?.data.length) {

        dispatch({ type: LOAD_COLLEGE, payload: { list: getColleges?.data?.data } });
    }

    if (getDrives.data?.data.length) {

        dispatch({ type: LOAD_CPD_DRIVE, payload: { list: getDrives?.data?.data } });
    }

    if (projectsList.data?.data.length) {
        dispatch({ type: LOAD_CMS_PRODUCT, payload: { list: projectsList?.data?.data } });
    }

    const projects = useSelector<{ cmsProduct: { list: IProject[] } }, IProject[]>(state => state?.cmsProduct?.list) || [];

    const selectedLink = projects && projects[0];
    const id = selectedLink ? selectedLink._id : "";

    const isCollapse = localStorage.getItem("collapse-side-bar");
    const location = useLocation();
    const [state, setState] = useState({
        collapse: isCollapse === "true" ? false : true,
        menuOption: false,
        logoutWarning: false,
        isNotificationOpen: false
    });

    useEffect(() => {
        changeSidebarSize();
    }, []);

    const changeSidebarSize = () => {
        const leftSide = document.getElementById("left") as HTMLElement;
        const rightSide = document.getElementById("right") as HTMLElement;
        const logo = document.getElementById("logo") as HTMLElement;
        const collapseArrow = document.getElementById("collapse") as HTMLElement;
        const collapseText = document.getElementsByClassName("collapse-text") as HTMLCollectionOf<HTMLDivElement>;
        const collapseListItem = document.getElementsByClassName("collapse-ListItem") as HTMLCollectionOf<HTMLDivElement>;
        const nav_items_box = document.getElementsByClassName("nav-items-box") as HTMLCollectionOf<HTMLDivElement>;

        if (state.collapse) {
            leftSide.style.width = "200px";
            rightSide.style.marginLeft = "calc(200px + 16px)";
            logo.style.display = "flex";
            collapseArrow.style.margin = "0px";
            collapseArrow.style.width = "45px";
            collapseArrow.style.height = "37px";
            collapseArrow.style.transform = "rotate(0deg)";
            nav_items_box[0].style.height = "calc(100% - 145px)";

            for (let i = 0; i < collapseText.length; i++) {
                collapseText[i].style.display = "initial";
            }

            for (let i = 0; i < collapseListItem.length; i++) {
                collapseListItem[i].style.paddingLeft = "18px";
            }

        } else {
            leftSide.style.width = "58px";
            rightSide.style.marginLeft = "calc(60px + 16px)";
            logo.style.display = "none";
            collapseArrow.style.margin = "20px 0px";
            collapseArrow.style.transform = "rotate(180deg)";
            collapseArrow.style.width = "40px";
            collapseArrow.style.height = "40px";
            nav_items_box[0].style.height = "calc(100% - 95px)";

            for (let i = 0; i < collapseText.length; i++) {
                collapseText[i].style.display = "none";
            }

            for (let i = 0; i < collapseListItem.length; i++) {
                collapseListItem[i].style.paddingLeft = "18px";
            }

        }

        // setCollapse(!collapse);
        setState(prevState => ({
            ...prevState,
            collapse: !prevState.collapse
        }));

        localStorage.setItem("collapse-side-bar", String(!state.collapse));
    };

    const handleProfileMenu = () => {
        setState(prevState => ({
            ...prevState,
            menuOption: !prevState.menuOption
        }));
    };

    const handleLogout = () => {
        setState(prevState => ({
            ...prevState,
            logoutWarning: !prevState.logoutWarning
        }));
    };

    const onLogout = () => {
        localStorage.removeItem("currentUserToken");
        setUser(() => ({}));
        navigate("/login");
    };

    const displayName = (name: string) => {
        if (name?.length > 9) {
            return `${capitalize(name.slice(0, 10))}...`;
        } else {
            return name;
        }
    };

    const onOpenNotificationDrawer = () => {
        setState(prevState => ({
            ...prevState,
            isNotificationOpen: true
        }));
    };

    const onCloseNotificationDrawer = () => {
        setState(prevState => ({
            ...prevState,
            isNotificationOpen: false
        }));
    };

    const showNavTitle = (title: string) => {
        let text = title;
        switch (title) {
            case "cpd":
                text = "CPD";
                break;
            case "broadcast-events":
                text = "Broadcast Analytics";
                break;
            case "broadcast-group":
                text = "Broadcast Group";
                break;
            case "kpi":
                text = "KPI";
                break;
            case "skill-matrix":
                text = "Skill Matrix";
                break;
            case "requested-skill":
                text = "Requested Skill";
                break;
            case "resource-requested":
                text = "Resource Planning";
                break;
            case "blogs-analytics":   
                text = "Blogs Analytics";
                break;
        }

        return text;
    };

    const navigateLink = (module: "cpd" | "report" | "configuration" | "careers" | "cms-configuration" | "assessments") => {
        let link = "";
        switch (module) {
            case "cpd": {
                if (resourceAllocate("cpd-dashboard")) {
                    link = "/cpd/dashboard";
                } else if (resourceAllocate("cpd-drives")) {
                    link = "/cpd/drives";
                }
                else if (resourceAllocate("college")) {
                    link = "/cpd/college";
                }
                break;
            }
            case "careers": {
                if (resourceAllocate("job")) {
                    link = "/careers/jobs";
                } else if (resourceAllocate("employee-review")) {
                    link = "/careers/employee-review";
                } else if (resourceAllocate("college-image")) {
                    link = "/careers/college-images";
                } else if (resourceAllocate("employee-image")) {
                    link = "/careers/office-employee-images";
                } else if (resourceAllocate("faq")) {
                    link = "/careers/faqs";
                } else if (resourceAllocate("careers-highlight")) {
                    link = "/careers/highlight";
                }
                break;
            }
            case "report": {
                if (resourceAllocate("report-analytics")) {
                    link = "/reports/overview";
                } else if (resourceAllocate("kpi")) {
                    link = "/reports/kpi";
                } else if (resourceAllocate("cms-blog-analytics")) {
                    link = "/reports/cms";
                }
                break;
            }
            case "assessments": {
                if (resourceAllocate("interview")) {
                    link = "/assessments/interview/upcoming";
                } else if (resourceAllocate("review")) {
                    link = "/assessments/review/pending";
                }
                break;
            }
            case "configuration": {
                if (resourceAllocate("user")) {
                    link = "/configurations/user";
                } else if (resourceAllocate("user-role")) {
                    link = "/configurations/roles";
                } else if (resourceAllocate("department")) {
                    link = "/configurations/teams";
                } else if (resourceAllocate("resource-request-role")) {
                    link = "/configurations/resource-request-roles";
                } else if (resourceAllocate("assessment-instruction")) {
                    link = "/configurations/assessments-instruction";
                }else if (resourceAllocate("job-position")) {
                    link = "/configurations/job-positions";
                } else if (resourceAllocate("job-title")) {
                    link = "/configurations/job-title";
                } else if (resourceAllocate("template")) {
                    link = "/configurations/template";
                } else if (resourceAllocate("technical-test-tag")) {
                    link = "/configurations/question-tags";
                } else if (resourceAllocate("status")) {
                    link = "/configurations/status";
                } else if (resourceAllocate("google-integration")) {
                    link = "/configurations/integration";
                } else if (resourceAllocate("interview-parameter")) {
                    link = "/configurations/parameters";
                } else if (resourceAllocate("review-parameter")) {
                    link = "/configurations/review-parameters";
                } else if (resourceAllocate("onboard-users")) {
                    link = "/configurations/onboard-user";
                } else if (resourceAllocate("test")) {
                    link = "/configurations/tests";
                }
                break;
            }
            case "cms-configuration": {
                if (resourceAllocate("cms-product-title")) {
                    link = "/content/products";
                } else if (resourceAllocate("cms-category")) {
                    link = "/content/category";
                } else if (resourceAllocate("cms-sub-category")) {
                    link = "/content/sub-category";
                } else if (resourceAllocate("cms-keyword")) {
                    link = "/content/keywords";
                } else if (resourceAllocate("cms-funnel-parts")) {
                    link = "/content/parts-of-funnel";
                } else if (resourceAllocate("cms-goal")) {
                    link = "/content/goals";
                } else if (resourceAllocate("cms-content-types")) {
                    link = "/content/type-of-content";
                } else if (resourceAllocate("cms-score-data")) {
                    link = "/content/score-data";
                } else if (resourceAllocate("cms-product-mapping")) {
                    link = "/content/product-mapping";
                }
                break;
            }
        }

        return link;
    };


    const showHeader = !(new RegExp("/blog/manage")).test(location.pathname);

    return (
        <>
            {/* left sidebar  */}
            <div id="left" className="left-side-nav">
                <div className="center">
                    <div id="logo" className="logo-box">
                        <img src={Logo} alt="logo" />
                    </div>
                    <div
                        id="collapse"
                        className="collapse-arrow"
                        onClick={changeSidebarSize}
                    >
                        <ArrowBackIosIcon className="collapse-arrow-icon h-100" />
                    </div>
                </div>

                <div className="nav-items-box">
                    {/* navigation buttons  */}
                    <div style={{ overflowX: "auto" }}>
                        <List>
                            {
                                resourceAllocate("dashboard") && <ListItem
                                    navigateLink="/dashboard"
                                    label="Dashboard"
                                    Icon={DashboardIcon}
                                    isCollapse={state.collapse}
                                />
                            }
                            {
                                resourceAllocate("cms-home") && <ListItem
                                    navigateLink="/home"
                                    label="Home"
                                    Icon={homeIcon}
                                    isCollapse={state.collapse}
                                />
                            }
                            {
                                (resourceAllocate("cms-product-title") || resourceAllocate("cms-category") || resourceAllocate("cms-sub-category") || resourceAllocate("cms-keyword") || resourceAllocate("cms-goal") || resourceAllocate("cms-funnel-parts") ||
                                    resourceAllocate("cms-content-types") || resourceAllocate("cms-score-data") || resourceAllocate("cms-product-mapping")) &&
                                <ListItem
                                    navigateLink={navigateLink("cms-configuration")}
                                    label="Planner-config"
                                    Icon={ManageAccountsIcon}
                                    isCollapse={state.collapse}
                                />
                            }
                            {
                                resourceAllocate("cms-quarter") &&
                                <ListItem
                                    navigateLink={`/planner/${id}`}
                                    label="Planner"
                                    Icon={EventAvailableIcon}
                                    isCollapse={state.collapse}

                                />
                            }
                            {
                                resourceAllocate("lead") &&
                                <ListItem
                                    navigateLink="/candidates/intern"
                                    label="Candidates"
                                    Icon={SupervisedUserCircleIcon}
                                    isCollapse={state.collapse}
                                />
                            }
                            {
                                resourceAllocate("cms-blog") &&
                                <ListItem
                                    navigateLink="/blog"
                                    label="Blogs"
                                    Icon={ListIcon}
                                    isCollapse={state.collapse}
                                />
                            }
                            {
                                (resourceAllocate("cpd-dashboard") || resourceAllocate("cpd-drives") || resourceAllocate("college")) ?
                                    <ListItem
                                        navigateLink={navigateLink("cpd")}
                                        label="CPD"
                                        Icon={SchoolIcon}
                                        isCollapse={state.collapse}
                                    />
                                    :
                                    null
                            }
                            {
                                (resourceAllocate("interview") || resourceAllocate("review")) &&
                                <ListItem
                                    navigateLink={navigateLink("assessments")}
                                    label="Assessments"
                                    Icon={EventIcon}
                                    isCollapse={state.collapse}
                                />
                            }
                            {
                                (
                                    resourceAllocate("job") || resourceAllocate("employee-review") || resourceAllocate("college-image") ||
                                    resourceAllocate("employee-image") || resourceAllocate("faq") || resourceAllocate("careers-highlight")
                                ) &&
                                <ListItem
                                    navigateLink={navigateLink("careers")}
                                    label="Careers Page"
                                    Icon={WebIcon}
                                    isCollapse={state.collapse}
                                />
                            }
                            {
                                resourceAllocate("broadcast-group") &&
                                <ListItem
                                    navigateLink="/broadcast-group"
                                    label="Broadcast Group"
                                    Icon={ForwardToInboxIcon}
                                    isCollapse={state.collapse}
                                />
                            }
                            {
                                resourceAllocate("offer") &&
                                <ListItem
                                    navigateLink="/offered"
                                    label="Offers"
                                    Icon={RecentActorsIcon}
                                    isCollapse={state.collapse}
                                />
                            }
                            {
                                (resourceAllocate("report-analytics") || resourceAllocate("kpi") || resourceAllocate("cms-blog-analytics")) &&
                                <ListItem
                                    navigateLink={navigateLink("report")}
                                    label="Reports"
                                    Icon={AnalyticsIcon}
                                    isCollapse={state.collapse}
                                />
                            }
                            {
                                resourceAllocate("cms-blog-insights") &&    
                                <ListItem
                                    navigateLink="/blogs-analytics/pages"    
                                    label="Blogs Analytics"
                                    Icon={InsightsIcon}
                                    isCollapse={state.collapse}
                                />
                            }
                            {
                                resourceAllocate("trash") &&
                                <ListItem
                                    navigateLink="/trash/candidate-trash"
                                    label="Trash"
                                    Icon={DeleteIcon}
                                    isCollapse={state.collapse}
                                />
                            }
                            {
                                (resourceAllocate("user") || resourceAllocate("user-role") || resourceAllocate("department") || resourceAllocate("job-type") || resourceAllocate("job-position") ||
                                    resourceAllocate("job-title") || resourceAllocate("template") || resourceAllocate("status") ||
                                    resourceAllocate("google-integration") || resourceAllocate("interview-parameter") || resourceAllocate("review-parameter") || resourceAllocate("onboard-user") || resourceAllocate("test") ||
                                    resourceAllocate("technical-test-tag") || resourceAllocate("technical-question-languages") || resourceAllocate("technical-question") || resourceAllocate("assessment") || resourceAllocate("resource-request-role")) &&
                                <ListItem
                                    navigateLink={navigateLink("configuration")}
                                    label="Configurations"
                                    Icon={ManageAccountsIcon}
                                    isCollapse={state.collapse}
                                />
                            }
                            {
                                resourceAllocate("vendor") &&
                                <ListItem
                                    navigateLink="/directory/vendors"    
                                    label="Directory"
                                    Icon={StoreMallDirectoryIcon}
                                    isCollapse={state.collapse}
                                />
                            }
                            {
                                resourceAllocate("skill-matrix") &&
                                <ListItem
                                    navigateLink="/skill-matrix"
                                    label="Skill Matrix"
                                    Icon={ListIcon}
                                    isCollapse={state.collapse}
                                />
                            }
                            {
                                resourceAllocate("skill-matrix-request") &&
                                <ListItem
                                    navigateLink="/requested-skill"
                                    label="Requested Skill"
                                    Icon={AnnouncementIcon}
                                    isCollapse={state.collapse}
                                />
                            }

                            {
                                resourceAllocate("resource-request") &&
                                <ListItem
                                    navigateLink="/resource-requested/requests"
                                    label="Resource Planning"
                                    Icon={BorderColorIcon}
                                    isCollapse={state.collapse}
                                />
                            }
                        </List>
                    </div>

                    {/* profile buttons */}
                    <div>
                        <Divider className="divider-color" />
                        <div>
                            <div
                                className={`profile-transition ${state.menuOption ? "margin-top-30" : "profile-buttons-hide"}`}
                            >
                                <ListItem
                                    label="Settings"
                                    navigateLink="/settings/profile"
                                    Icon={SettingsIcon}
                                    isCollapse={state.collapse}
                                />

                                <div
                                    className="profileButtonWrap"
                                    id="logout-icon"
                                    onClick={handleLogout}
                                >
                                    <Tooltip title={"Logout"} disableHoverListener={isCollapse ? false : true} placement="right">
                                        <ListItemIcon className="navIcons">
                                            <ExitToAppIcon />
                                        </ListItemIcon>
                                    </Tooltip>
                                    <ListItemText
                                        className="collapse-text"
                                        primary={"Logout"}
                                        classes={{ primary: "listItem-text" }}
                                    />
                                </div>
                            </div>

                            <div
                                id="profile"
                                className={
                                    state.menuOption
                                        ? "rotate-icon-down active-profile profile"
                                        : "rotate-icon-up profile"
                                }
                                onClick={handleProfileMenu}
                            >
                                <div className="profile-name-box">
                                    <Avatar className="profile-avatar" src={user?.integrationMeta?.picture}>
                                        {user ? user.name && user.name[0]?.toUpperCase() : "A"}
                                    </Avatar>
                                    <div className="name collapse-text">
                                        {user ? displayName(capitalize(user.name)) : ""}
                                    </div>
                                </div>
                                <ExpandLessIcon className="collapse-text" />
                            </div>
                        </div>

                    </div>
                </div>
            </div >

            {/* right side content  */}
            < div id="right" className="right-side-content" >
                <Box className="center top-nav" justifyContent="space-between">
                    {
                        showHeader &&
                        <Typography variant="h5">
                            {showNavTitle(location.pathname.split("/")[1])}
                        </Typography>
                    }

                    {resourceAllocate("notification")  &&  <Box className="center">

                        {location.pathname.split("/")[1] === "candidates" && <SearchCandidate />}

                        {
                            showHeader &&
                            <IconButton
                                onClick={onOpenNotificationDrawer}
                                className="ml-3"
                            >
                                <Badge
                                    badgeContent={notificationCount.data?.data || 0}
                                    color="error"
                                >
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                        }
                    </Box>}


                    <Notification
                        count={notificationCount.data?.data || 0}
                        isOpen={state.isNotificationOpen}
                        onClose={onCloseNotificationDrawer}
                        reFetchCount={notificationCount.refetch}
                    />
                </Box>
                <Outlet context={{
                    refetchUsers: getUsers.refetch, refetchTeams: teams.refetch,
                    refetchEngagementTypes: getEngagementTypes.refetch, refetchJobTitles: jobTitles.refetch,
                    refetchJobPositions: jobPositions.refetch, refetchStatuss: getStatusData.refetch,
                    refetchTemplates: templates.refetch, refetchColleges: getColleges.refetch,
                    refetchJobss: getJobs.refetch, refetchDrivess: getDrives.refetch,
                    refetchTest : getTest.refetch,  
                    refetchProjects: projectsList.refetch
                }} />
            </div >

            {/* logout modal  */}
            <Dialog
                open={state.logoutWarning}
                onClose={handleLogout}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                PaperProps={{ sx: { width: 500, borderRadius: 2 } }}

            >
                <DialogContent>
                    <Box height={350} className="center">
                        <Box className="center" flexDirection="column">
                            <img
                                src={Logout}
                                alt="Logo"
                                srcSet=""
                                width={110}
                            />
                            <Typography variant="h5" sx={{ fontWeight: 700 }} className="mt-2 mb-1">Log out</Typography>
                            <Typography >
                                Are you sure you want to log out?
                            </Typography>
                            <div className="button mt-4">
                                <Button
                                    sx={{ color: "black" }}
                                    color="inherit"
                                    className="mr-3"
                                    onClick={handleLogout}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={onLogout}
                                >
                                    {"Yes, I'II be back"}
                                </Button>
                            </div>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>

        </>
    );
};

export default Layout;
