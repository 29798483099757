import HttpService from "../http";
import { IOfferedsResponse, IQuery, IOfferedResponse } from "../../interfaces";
import { offered, offereAction } from "../endpoints";

const OfferedService = () => {
    const { httpRequest } = HttpService();
    const getOffereds = async (params: IQuery, data = {}): Promise<IOfferedsResponse> => new Promise((resolve, reject) => {
        httpRequest<IOfferedsResponse>(
            "POST",
            `${offered}/list`,
            data,
            params
        )
            .then(resolve)
            .catch(reject);
    });

    const updateOfferedStatusAssignTo = async (id: string, payload: object): Promise<IOfferedsResponse> => new Promise((resolve, reject) => {
        httpRequest<IOfferedsResponse>(
            "PUT",
            `${offered}`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const sendOfferedEmail = async (payload: object): Promise<IOfferedResponse> => new Promise((resolve, reject) => {
        httpRequest<IOfferedResponse>(
            "POST",
            `${offereAction}/offer-sent`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const sendOnBoard = async (payload: object): Promise<IOfferedResponse> => new Promise((resolve, reject) => {
        httpRequest<IOfferedResponse>(
            "POST",
            `${offereAction}/onboard-template`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    const addNewJoinees = async (payload: object): Promise<IOfferedsResponse> => new Promise((resolve, reject) => {
        httpRequest<IOfferedsResponse>(
            "POST",
            `${offereAction}/new-joinee`,
            payload
        )
            .then(resolve)
            .catch(reject);
    });

    return { getOffereds, updateOfferedStatusAssignTo, sendOfferedEmail, sendOnBoard, addNewJoinees };

};

export { OfferedService };