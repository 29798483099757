import Joi from "joi";
import { required } from "../../shared";

export const blogContentValidation = Joi.object({
    author: Joi.string()
        .required()
        .label("Author")
        .messages({
            "string.empty": required,
        }),
    readTime: Joi.number()
        .optional()
        .allow("")
        .label("Read Time"),
    content: Joi.string()
        .required()
        .label("Content")
        .messages({
            "string.empty": required,
        }),
    metaTitle: Joi.string()
        .required()
        .label("Meta Title")
        .max(60)
        .messages({
            "string.empty": required,
        }),
    metaDescription: Joi.string()
        .required()
        .max(160)
        .label("Meta Description")
        .messages({
            "string.empty": required,
        }),
});
