import Joi from "joi";
import { required, only_string } from "../../shared";

export const teamValidation = Joi.object({
    name: Joi.string()
        .required()
        .label("Name")
        .max(25)
        .messages({
            "string.empty": required,
            "string.pattern.base": only_string,
            "string.max": "Maximum 25 characters are allowed"
        }),
});
