import { Box, Checkbox, Typography } from "@mui/material";
import Header from "../../../components/header";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useSearchParams, Outlet } from "react-router-dom";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import useDebounce from "../../../hooks/useDebounce";
import { useQuery } from "@tanstack/react-query";
import { capitalize } from "../../../utilities/helper";
import { IColumn, IErrorResponse } from "../../../interfaces";
import WarningDialog from "../../../components/mui/warning-dialog";
import useSnackbar from "../../../hooks/useSnackbar";
import { handleSelect, handleSelectAll } from "../common/helper";
import useResource from "../../../hooks/useResource";
import ProductMappingService from "../../../services/content/product-mapping";
import { IProductMapping, IProductMappingRow,IProductMappingStatus } from "../../../interfaces/content/product-mapping";

const ProductMappingList = () => {
    const { snackbar } = useSnackbar();
    const [search] = useState<string>("");
    const [searchParams, setSearchParams] = useSearchParams();
    const { resourceAllocate } = useResource();
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const { getProductMappings, deleteProductMapping } = ProductMappingService();
    const [addState, setAddState] = useState(false);
    const [state, setState] = useState<IProductMappingStatus>({
        selectAll: [],
        multiDeleteWarning: false,
        pagination: {
            page: page,
            limit: 20,
            totalPages: 1,
            totalRecords: 0
        },
        searching: {
            search: ""
        },
        filterDialog: {
            anchorEl: null,
            isOpen: false
        },
        filterCount: 0,
        deleteWarning: false,
        _keyword: "",
        reopenWarning: false
    });

    const productMappingList = useQuery({
        queryKey: ["keyword", state.pagination.page, state.searching.search],
        queryFn: () => getProductMappings({
            pagination: true,
            page: state.pagination.page,
            limit: state.pagination.limit,
            ...state.searching
        }),
    });

    const navigate = useNavigate();
    useEffect(() => {
        const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
        const search = searchParams.get("search") ? String(searchParams.get("search")) : "";
        setState(prevState => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: page
            },
            searching: {
                ...prevState.searching,
                search: search,
            },
        }));
    }, [searchParams]);

    const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
        searchParams.set("page", page.toString());
        setSearchParams(searchParams);
    };

    const searchRecord = useDebounce(search, 1000);
    useEffect(() => {
        if (searchRecord.length) {
            setSearchParams(prev => ({
                ...prev,
                page: 1,
                search: searchRecord
            }));
        } else {
            searchParams.delete("search");
            searchParams.delete("page");
            setSearchParams(searchParams);
        }
    }, [searchRecord]);


    const handleDelete = (_keyword = "") => {
        setState((prevState) => ({
            ...prevState,
            deleteWarning: !prevState.deleteWarning,
            _keyword,
        }));
    };

    const onDelete = async () => {
        try {
            const response = await deleteProductMapping({ ids: [state._keyword] });
            setState(prevState => ({
                ...prevState,
                selectAll : prevState.selectAll.filter((keyword) => keyword !== state._keyword)
            }));
            snackbar(response.message, "info");
            handleDelete();
            productMappingList.refetch();
            navigate(
                {pathname : "/content/product-mapping",
                search: searchParams.toString()}
            );
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log("Error in delete sub-category", err);

        }
    };

    const handleMultiDelete = () => setState(prevState => ({
        ...prevState,
        multiDeleteWarning: !prevState.multiDeleteWarning
    }));
    const onMultiDelete = async () => {
        try {
            const goal = await deleteProductMapping({ ids: state.selectAll });
            snackbar(goal.message, "info");
            productMappingList.refetch();
            setState(prevState => ({
                ...prevState,
                multiDeleteWarning: false,
                selectAll: []
            }));
            navigate(
                {pathname : "/content/product-mapping",
                search: searchParams.toString()}
            );
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            handleMultiDelete();
            console.log({ "Error in delete project": error });
        }
    };

    const isChecked = (productMappingList.data?.data?.length && state.selectAll?.length === productMappingList.data?.data?.length) ? true : false;
    const isIndeterminateChecked = (state.selectAll.length > 0 && state.selectAll.length < Number(productMappingList.data?.data.length)) ? true : false;

    const columns: IColumn[] =
        [
            {
                id: "all",
                label: <Checkbox onChange={e => handleSelectAll(e, productMappingList?.data?.data ? productMappingList?.data?.data : [], setState)} checked={isChecked} indeterminate={isIndeterminateChecked} />,
                maxWidth: 0
            },
            {
                id: "product_name",
                label: "Name",
                // minWidth: 100
            },
            {
                id: "link",
                label: "URL"
            },
            {
                id: "action",
                label: "Actions",
                align: "right"
            },
        ];

    let rows: IProductMappingRow[] = [];
    const createRows = (
        mapping: IProductMapping,
        selectedAll: string[]
    ) => {
        const action = <GetActions
            icons={[
                {
                    name: "Edit", method: () => {
                        setAddState(true);
                        navigate({
                            pathname: "" + mapping._id,
                            search: searchParams.toString()
                        });
                    },
                    disabled: resourceAllocate("cms-product-mapping.write") ? false : true

                },
                { name: "Delete", method: () => handleDelete(mapping._id), disabled: resourceAllocate("cms-product-mapping.remove") ? false : true },
            ]}
        />;

        return {
            all: <Checkbox onChange={e => handleSelect(e, mapping._id, state, setState)} checked={selectedAll.includes(mapping._id)} />,
            product_name: capitalize(mapping?._productId?.name || ""),
            link: mapping?.link || "",
            action
        };
    };


    if (productMappingList?.data?.data.length) {
        rows = productMappingList?.data?.data?.map((mapping: IProductMapping) => createRows(mapping, state.selectAll));
    }


    const addOpenBox = () => {
        setAddState(true);
        navigate("/content/product-mapping/new");
    };

    return (

        <div >
            {addState && <Outlet context={{ ...Outlet, reFetch: productMappingList.refetch }} />}
            <Header
                className="my-2"
                onDelete={resourceAllocate("cms-product-mapping.remove") ? handleMultiDelete : undefined}
                isDeleteDisable={state.selectAll.length ? false : true}
                btnText="Add Product Mapping"
                onBtnClick={resourceAllocate("cms-product-mapping.write") ? () => addOpenBox() : undefined}
            />
            <Box display="flex" className="mb-2" justifyContent="flex-end" alignItems="center">
                <Box display="flex" alignItems="center">
                    <Typography variant="body1">Total Product Mappings:</Typography>
                    <Typography className="ml-3" variant="body1" >{productMappingList?.data?.meta?.totalRecords}</Typography>
                </Box>
            </Box>
            <Box id="custom-table" marginTop="10px">
                <CustomTable
                    columns={columns}
                    rows={rows}
                    height="calc(100vh - 285px)"
                    errorMessage="Add user to see the data here"
                    pagination={{
                        page: state.pagination.page,
                        totalPages: state.pagination.totalPages
                    }}
                    onPageChange={onPageChange}
                />

            </Box>
            <WarningDialog
                isOpen={state.deleteWarning}
                onClose={() => handleDelete()}
                onConfirm={onDelete}
                title="Delete Mapping"
                description="Are you sure you want to delete this mapping?"
            />
            {/* Multiple Delete  */}
            <WarningDialog
                isOpen={state.multiDeleteWarning}
                onClose={() => handleMultiDelete()}
                onConfirm={onMultiDelete}
                title="Delete All Mappings"
                description={`Are you sure you want to delete ${state.selectAll.length} ${state.selectAll.length > 1 ? "mappings" : "mapping"} ?`}
            />
        </div>
    );

};
export default ProductMappingList;